import React from "react";

import Afgreidd from "./afgreidd";
import AfhendingPontud from "./afhendingPontud";
import Afhent from "./afhent";
import AfhentTilLandflutninga from "./afhentTilLandflutninga";
import AksturPantadur from "./aksturPantadur";
import BidurUthlutunar from "./bidurUthlutunar";
import DiagramNested from "./diagramNested";
import Forskrad from "./forskrad";
import GreidsluUpplysingarBokunar from "./greidsluUpplysingarBokunar";
import GripHorizontal from "./gripHorizontal";
import HaettuefnaDGRSendingar1 from "./haettuefnaDGRSendingar1";
import HaettuefnaDGRSendingar2 from "./haettuefnaDGRSendingar2";
import HaettuefnaDGRSendingar3 from "./haettuefnaDGRSendingar3";
import HaettVid from "./haettVid";
import HitastyrdarSendingar from "./hitastyrdarSendingar";
import IAkstri from "./iAkstri";
import IBid from "./iBid";
import IFlutningiFlug from "./iFlutningiFlug";
import IFlutningiSjor from "./iFlutningiSjor";
import IMottokukIVoruhusi from "./iMottokukIVoruhusi";
import IVinnslu from "./iVinnslu";
import JonarArrow from "./jonarArrow";
import LandflutningurPantadur from "./landflutningurPantadur";
import LestadIBil from "./lestadIBil";
import OTollad from "./oTollad";
import SafnSending from "./safnsending";
import Search from "./search";
import SendingLosudUrSkipi from "./sendingLosudUrSkipi";
import Skrad from "./skrad";
import Sott from "./sott";
import Stjarna from "./stjarna";
import TilbuinTilAfgreidslu from "./tilbuinTilAfgreidslu";
import TilfaerslaIVoruhusi from "./tilfaerslaIVoruhusi";
import Tollad from "./tollad";

const Icon = (props: { className?: string | undefined; name: string; onClick?: Function }) => {
  switch (props.name) {
    case "afgreidd":
      return <Afgreidd {...props} />;
    case "tollad":
      return <Tollad {...props} />;
    case "stjarna":
    case "minnisbladBokunar":
      return <Stjarna {...props} />;
    case "afhendingPontud":
      return <AfhendingPontud {...props} />;
    case "afhent":
      return <Afhent {...props} />;
    case "afhentTilLandflutninga":
      return <AfhentTilLandflutninga {...props} />;
    case "aksturPantadur":
      return <AksturPantadur {...props} />;
    case "bidurUthlutunar":
      return <BidurUthlutunar {...props} />;
    case "forskrad":
      return <Forskrad {...props} />;
    case "greidsluUpplysingarBokunar":
      return <GreidsluUpplysingarBokunar {...props} />;
    case "haettuefnaDGRSendingar1":
      return <HaettuefnaDGRSendingar1 {...props} />;
    case "haettuefnaDGRSendingar2":
      return <HaettuefnaDGRSendingar2 {...props} />;
    case "haettuefnaDGRSendingar3":
      return <HaettuefnaDGRSendingar3 {...props} />;
    case "haettVid":
      return <HaettVid {...props} />;
    case "hitastyrdarSendingar":
      return <HitastyrdarSendingar {...props} />;
    case "iAkstri":
      return <IAkstri {...props} />;
    case "iBid":
      return <IBid {...props} />;
    case "iFlutningiFlug":
      return <IFlutningiFlug {...props} />;
    case "iFlutningiSjor":
      return <IFlutningiSjor {...props} />;
    case "iMottokukIVoruhusi":
      return <IMottokukIVoruhusi {...props} />;
    case "iVinnslu":
      return <IVinnslu {...props} />;
    case "jonarArrow":
      return <JonarArrow {...props} />;
    case "landflutningurPantadur":
      return <LandflutningurPantadur {...props} />;
    case "lestadIBil":
      return <LestadIBil {...props} />;
    case "oTollad":
      return <OTollad {...props} />;
    case "sendingLosudUrSkipi":
      return <SendingLosudUrSkipi {...props} />;
    case "skrad":
      return <Skrad {...props} />;
    case "sott":
      return <Sott {...props} />;
    case "search":
      return <Search {...props} />;
    case "tilbuinTilAfgreidslu":
      return <TilbuinTilAfgreidslu {...props} />;
    case "tilfaerslaIVoruhusi":
      return <TilfaerslaIVoruhusi {...props} />;
    case "safnsending":
      return <SafnSending {...props} />;
    case "diagramNested":
      return <DiagramNested {...props} />;
    case "gripHorizontal":
      return <GripHorizontal {...props} />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

export default Icon;
