import { Invoice } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

class InvoiceReq {
  jobReference: string;
  sapID: string;
}

export const getInvoicesUrls = createAsyncThunk(
  "Invoices/jobReference",
  async (req: InvoiceReq) => {
    return await SingleBookingService.getInvoicesUrls(req.jobReference, req.sapID);
  }
);

export interface InvoicesState {
  invoices: Invoice[];
  loadingInvoices: boolean;
}

export const initialInvoicesState: InvoicesState = {
  invoices: [],
  loadingInvoices: false,
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: initialInvoicesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoicesUrls.pending, (state) => {
        state.loadingInvoices = true;
      })
      .addCase(getInvoicesUrls.rejected, (state) => {
        state.loadingInvoices = false;
        state.invoices = [];
      })
      .addCase(getInvoicesUrls.fulfilled, (state, action: PayloadAction<Invoice[]>) => {
        state.loadingInvoices = false;
        state.invoices = action.payload;
      });
  },
});

export const invoiceState = (state: RootState) => state.invoicesSlice;

export default invoicesSlice.reducer;
