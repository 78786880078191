import React, { ReactNode } from "react";

import { BookingPalletResponse, TableInfo } from "@assets/types";
import ExpandableComponent from "@components/ExpendableComponent/expendableComponent";
import Table from "@components/Table/table";
import moment from "moment";
import { useTranslation } from "react-i18next";

type PalletCellProps = {
  title: string;
  palletInfo?: BookingPalletResponse[] | null;
  className?: string;
};

export const PalletCell: React.FC<PalletCellProps> = ({ palletInfo, title, className }) => {
  const { t } = useTranslation();
  const palletDataTable: TableInfo = {
    tableHeader: [
      t("LABEL_LOCATION"),
      t("LABEL_QUANTITY"),
      t("LABEL_WAREHOUSE_STATUS"),
      t("LABEL_OPENED"),
      t("LABEL_CLOSED"),
    ],
    tableData: palletInfo
      ? palletInfo?.map((pallet) => {
          return [
            pallet.Type == "CONTAINER" ? pallet.Location : t(`LABEL_${pallet.Location}`),
            pallet.Quantity,
            pallet.StatusDescription != null
              ? `${pallet.StatusDescription} ${
                  pallet.Type == "CONTAINER" || pallet.ID == null ? "" : `(${pallet.ID})`
                }`
              : "N/A",
            pallet.DateOpened != null
              ? moment(pallet.DateOpened).format("DD MMM YYYY HH:mm").toString()
              : "N/A",
            pallet.DateClosed != null
              ? moment(pallet.DateClosed).format("DD MMM YYYY HH:mm").toString()
              : "N/A",
          ];
        })
      : [],
    classes: ["", "", "w-1/3"],
  };

  const totalInWarehouse = palletInfo
    ? palletInfo?.reduce((x, y) => {
        if (y.LocationType == "WMO") return x + (y.Quantity ?? 0);
        return x;
      }, 0)
    : 0;

  const totalInContainer = palletInfo
    ? palletInfo?.reduce((x, y) => {
        if (y.LocationType == "CNT") return x + (y.Quantity ?? 0);
        return x;
      }, 0)
    : 0;

  const handleText = (): ReactNode => {
    return (
      <div className="flex mt-2 text-neutral-700">
        <label>{t("LABEL_TOTAL_IN_CONTAINER")}:</label>
        <span className="text-neutral-900 ml-1"> {totalInContainer}</span>

        <span className="mx-2">•</span>
        <label>{t("LABEL_TOTAL_IN_WAREHOUSE")}:</label>
        <span className="text-neutral-900 ml-1">{totalInWarehouse}</span>
      </div>
    );
  };

  return (
    <ExpandableComponent className={className} closedTitle={title} fixedComponent={handleText()}>
      <div className="mt-4">
        <Table
          className="overflow-auto block whitespace-nowrap table-auto"
          shouldFade
          tableInfo={palletDataTable}
        />
      </div>
    </ExpandableComponent>
  );
};

PalletCell.displayName = "PalletCell";

export default PalletCell;
