import React, { useEffect, useState } from "react";

import { FreightMode, ShippingType, Temperatures, volumeMax, weightMax } from "@assets/constants";
import {
  AddCargoData,
  ContainerTypes,
  PackageTypes,
  ModalProps,
  Commodities,
  HazardCodes,
  ErrorTypes,
  ResourceMaxSizes,
  SelectOption,
  AddDimensionsData,
} from "@assets/types";
import {
  commodityLabelMapper,
  commodityValueMapper,
  containerTypeLabelMapper,
  containerTypeValueMapper,
  hazardCodesLabelMapper,
  hazardCodesValueMapper,
  packageTypeLabelMapper,
  packageTypeValueMapper,
} from "@assets/utilities/labelMapperUtils";
import { getErrorMessage } from "@assets/utilities/translationUtilities";
import { emptyStringToNull } from "@assets/yupValidationHelpers";
import Button from "@components/Button/button";
import Checkbox from "@components/Input/checkbox";
import InputEx from "@components/Input/inputEx";
import Modal from "@components/Modal/modal";
import AsyncSelect from "@components/Select/asyncSelect";
import SelectEx from "@components/Select/selectEx";
import WithFormController from "@components/WithFormController/WithFormController";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateBookingService from "@services/CreateBookingService";
import { FieldError, useForm } from "react-hook-form";
import { Translation } from "react-i18next";
import StringUtilities from "utilities/StringUtilities";
import * as yup from "yup";

interface AddCargoProps extends ModalProps {
  shippingType: ShippingType;
  data?: AddCargoData;
  onChangeData: (data: AddCargoData, containerIndex: number | null) => void;
  onEditCargoData: (
    cargoIndex: number,
    containerIndex: number | null,
    addCargoData: AddCargoData
  ) => void;
  containerTypes: ContainerTypes[];
  unit: PackageTypes[];
  hazardCodes: HazardCodes[];
  commodities: Commodities[];
  isEdit: boolean;
  cargoIndex: number;
  containerIndex: number | null;
  loadingContainerTypes: boolean;
  loadingPackageTypes: boolean;
  loadingHazardCodes: boolean;
  loadingCommodities: boolean;
  shipmentType: FreightMode;
}

interface AddCargoForm {
  commodity: Commodities | null;
  quantity: number | null;
  containerType: ContainerTypes | null;
  containerShipperOwned: boolean;
  containerNumber: string;
  containerSealNumber: string;
  unit: PackageTypes | null;
  description: string;
  temperatureControl: boolean;
  temperatureMax: string;
  temperatureMin: string;
  temperature: SelectOption<number[]> | null;
  stackable: boolean;
  dimensions: AddDimensionsData[];
  height: number | null;
  width: number | null;
  length: number | null;
  volume: number | null;
  weight: number | null;
  hazardous: boolean;
  unNumbers: HazardCodes[];
  outOfGauge: boolean;
  marksAndNumbers: string;
}

const defaultFormValues: AddCargoForm = {
  commodity: null,
  quantity: null,
  containerType: null,
  containerShipperOwned: false,
  containerNumber: "",
  containerSealNumber: "",
  unit: null,
  description: "",
  temperatureControl: false,
  temperatureMin: "",
  temperatureMax: "",
  temperature: null,
  stackable: false,
  dimensions: [],
  height: null,
  width: null,
  length: null,
  volume: null,
  weight: null,
  hazardous: false,
  unNumbers: [],
  outOfGauge: false,
  marksAndNumbers: "",
};

const AddFCLCargoModal: React.FC<AddCargoProps> = (props: AddCargoProps) => {
  const {
    data,
    open,
    closeModal,
    onChangeData,
    onEditCargoData,
    containerTypes,
    unit,
    isEdit,
    cargoIndex,
    containerIndex = null,
  } = props;

  const [temperatureChecked, setTemperatureChecked] = useState(false);
  const [resourceMaxSizes, setResourceMaxSizes] = useState<ResourceMaxSizes | null>(null);

  const dropdownSchema = {
    label: yup.string(),
    value: yup.mixed(),
  };

  const schema = yup.object({
    commodity: yup
      .object()
      .shape(dropdownSchema)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    quantity: yup
      .number()
      .nullable()
      .transform(emptyStringToNull)
      .required(ErrorTypes.RequiredField)
      .min(1, ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    containerType: yup.lazy((value: ContainerTypes) => {
      if (value?.Refrigerated == "N") {
        return yup.object().test({
          name: "reefer",
          exclusive: false,
          params: {},
          message: ErrorTypes.ContainerTemperatureError,
          test: function () {
            return this.parent.temperatureControl == false;
          },
        });
      }
      return yup.mixed().required(ErrorTypes.RequiredField).typeError(ErrorTypes.RequiredField);
    }),
    containerNumber: yup.string().when("containerShipperOwned", {
      is: true,
      then: yup
        .string()
        .matches(StringUtilities.validContainerNumberRegex, {
          message: ErrorTypes.ContainerNumberValidation,
          excludeEmptyString: true,
        })
        .optional(),
    }),
    unit: yup
      .object()
      .shape(dropdownSchema)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    description: yup
      .string()
      .max(30, ErrorTypes.DescriptionSizeExceded)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    temperature: yup.mixed().when("temperatureControl", {
      is: true,
      then: yup
        .object()
        .shape(dropdownSchema)
        .typeError(ErrorTypes.RequiredField)
        .required(ErrorTypes.RequiredField),
    }),
    temperatureControl: yup.mixed().when("containerType", {
      is: (containerType: ContainerTypes) => containerType?.Refrigerated === "Y",
      then: yup.bool().oneOf([true], ErrorTypes.RequiredField),
    }),
    weight: yup
      .number()
      .max(
        resourceMaxSizes != null ? resourceMaxSizes?.Weight : weightMax,
        ErrorTypes.WeightExceded
      )
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField)
      .min(1, ErrorTypes.RequiredField),
    height: yup.lazy(() => {
      return yup.number().optional().nullable().transform(emptyStringToNull);
    }),
    width: yup.lazy(() => {
      return yup.number().optional().nullable().transform(emptyStringToNull);
    }),
    length: yup.lazy(() => {
      return yup.number().optional().nullable().transform(emptyStringToNull);
    }),
    volume: yup.lazy(() => {
      return yup.number().when("outOfGauge", {
        is: false,
        then: yup
          .number()
          .max(
            resourceMaxSizes != null ? resourceMaxSizes?.Volume : volumeMax,
            ErrorTypes.VolumeExceded
          )
          .required(ErrorTypes.RequiredField)
          .typeError(ErrorTypes.RequiredField)
          .min(0.1, ErrorTypes.RequiredField),
      });
    }),
    unNumbers: yup.mixed().when("hazardous", {
      is: true,
      then: yup
        .array(yup.object().shape(dropdownSchema))
        .min(1, ErrorTypes.RequiredField)
        .required(ErrorTypes.RequiredField)
        .typeError(ErrorTypes.RequiredField),
    }),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const calculateVolume = (
    quantity: number | null,
    length: number | null,
    width: number | null,
    height: number | null
  ) => {
    const _length = length ? +length : 0;
    const _width = width ? +width : 0;
    const _height = height ? +height : 0;
    const _quantity = quantity ? +quantity : 0;

    const volume = (_length * _width * _height * _quantity) / 1000000;

    return volume;
  };

  const getTemperatureOption = (minTemp?: string, maxTemp?: string) => {
    if (minTemp && maxTemp) {
      const temp = Temperatures.find(
        (x) => x.value[0] === parseInt(minTemp) && x.value[1] === parseInt(maxTemp)
      );
      return temp || null;
    }
    return null;
  };

  useEffect(() => {
    setValue("commodity", data?.commodity ?? null);
    setValue("quantity", data?.quantity ?? null);
    setValue("containerType", data?.containerType ?? null);
    setValue("containerShipperOwned", data?.containerShipperOwned ?? false);
    setValue("containerNumber", data?.containerNumber ?? "");
    setValue("containerSealNumber", data?.containerSealNumber ?? "");
    setValue("unit", data?.unit ?? null);
    setValue("description", data?.description ?? "");
    setValue("temperatureControl", data?.temperatureControl ?? false);
    setValue("temperature", getTemperatureOption(data?.temperatureMin, data?.temperatureMax));
    setValue("stackable", data?.stackable ?? false);
    setValue("dimensions", []);
    setValue("height", data?.height ?? null);
    setValue("weight", data?.weight ?? null);
    setValue("length", data?.length ?? null);
    setValue("width", data?.width ?? null);
    setValue("volume", data?.volume ?? null);
    setValue("hazardous", data?.hazardous ?? false);
    setValue("unNumbers", data?.unNumbers ?? []);
    setValue("outOfGauge", data?.outOfGauge ?? false);
    setValue("marksAndNumbers", data?.marksAndNumbers ?? "");
    setTemperatureChecked(data?.temperatureControl == false ? false : true);
  }, [props]);

  const onClose = () => {
    setTemperatureChecked(false);
    setValue("commodity", null);
    setValue("quantity", null);
    setValue("containerType", null);
    setValue("containerShipperOwned", false);
    setValue("containerNumber", "");
    setValue("containerSealNumber", "");
    setValue("unit", null);
    setValue("description", "");
    setValue("temperatureControl", false);
    setValue("temperature", null);
    setValue("stackable", false);
    setValue("dimensions", []);
    setValue("height", null);
    setValue("weight", null);
    setValue("length", null);
    setValue("width", null);
    setValue("volume", null);
    setValue("hazardous", false);
    setValue("unNumbers", []);
    setValue("outOfGauge", false);
    setValue("marksAndNumbers", "");
    closeModal();
    reset();
  };

  const searchHazardCodes = (searchString: string): Promise<HazardCodes[]> => {
    return CreateBookingService.searchHazardCodes(searchString);
  };

  const onSubmit = async (formData: AddCargoForm) => {
    let minTemperature = "";
    let maxTemperature = "";
    if (formData?.temperatureControl) {
      minTemperature = formData?.temperature ? formData?.temperature?.value[0].toString() : "";
      maxTemperature = formData?.temperature ? formData?.temperature?.value[1].toString() : "";
    }

    const newData = {
      commodity: formData.commodity ?? null,
      quantity: formData.quantity ?? 0,
      containerType: formData.containerType
        ? {
            PackageCode: formData.containerType?.PackageCode,
            Description: formData.containerType?.Description,
            CubicMetres: formData.containerType?.CubicMetres,
            Height: formData.containerType?.Height,
            Width: formData.containerType?.Width,
            PackageType: formData.containerType?.PackageType,
            StackableFlag: formData.containerType?.StackableFlag,
            Length: formData.containerType?.Length,
            ResourceType: formData.containerType?.ResourceType,
            ResourceSize: formData.containerType?.ResourceSize,
            Refrigerated: formData.containerType?.Refrigerated,
          }
        : null,
      containerShipperOwned: formData?.containerShipperOwned ?? false,
      containerNumber: formData?.containerNumber ?? "",
      containerSealNumber: formData?.containerSealNumber ?? "",
      unit: formData.unit
        ? {
            PackageCode: formData.unit?.PackageCode,
            Description: formData.unit?.Description,
            StackableFlag: formData.unit?.StackableFlag,
            Refrigerated: formData.unit?.Refrigerated,
            DefaultTeus: formData.unit?.DefaultTeus,
          }
        : null,
      description: formData.description ?? "",
      temperatureControl: formData.temperatureControl ?? false,
      temperatureMin: minTemperature,
      temperatureMax: maxTemperature,
      stackable: formData.stackable ?? false,
      height: formData.height ?? 0,
      weight: formData.weight ?? 0,
      length: formData.length ?? 0,
      width: formData.width ?? 0,
      volume: formData.volume ?? 0,
      hazardous: formData.hazardous ?? false,
      unNumbers: formData.unNumbers ?? [],
      outOfGauge: formData.outOfGauge ?? false,
      marksAndNumbers: formData.marksAndNumbers ?? "",
      dimensions: [],
    };
    isEdit
      ? onEditCargoData(cargoIndex, containerIndex, newData)
      : onChangeData(newData, props.shippingType === ShippingType.FCL ? containerIndex : null);
    closeModal();
  };

  const setVolume = () => {
    if (watch("outOfGauge"))
      setValue(
        "volume",
        calculateVolume(watch("quantity"), watch("height"), watch("length"), watch("width"))
      );
  };

  const renderDimensionsInput = () => {
    return (
      <Translation>
        {(t) => (
          <div className="flex md:flex-row flex-col w-full mb-6">
            <InputEx
              className="w-4/5 mr-3"
              label={t("LABEL_LENGTH")}
              type="number"
              {...register("length", {
                onChange: setVolume,
              })}
              errorMessage={getErrorMessage("length", errors)}
              hasError={errors.length != null}
              id="length"
              optional={props.shippingType === ShippingType.FCL}
              placeholder={t("TEXT_ADD_UNIT_LENGTH")}
              unitRight="cm"
            />
            <InputEx
              className="w-4/5 mx-3"
              label={t("LABEL_WIDTH")}
              type="number"
              {...register("width", {
                onChange: setVolume,
              })}
              errorMessage={getErrorMessage("width", errors)}
              hasError={errors.width != null}
              id="width"
              optional={props.shippingType === ShippingType.FCL}
              placeholder={t("TEXT_ADD_UNIT_WIDTH")}
              unitRight="cm"
            />
            <InputEx
              className="w-4/5 mx-3"
              label={t("LABEL_HEIGHT")}
              type="number"
              {...register("height", {
                onChange: setVolume,
              })}
              errorMessage={getErrorMessage("height", errors)}
              hasError={errors.height != null}
              id="height"
              optional={props.shippingType === ShippingType.FCL}
              placeholder={t("TEXT_ADD_UNIT_HEIGHT")}
              unitRight="cm"
            />
            <div className="flex flex-col mr-6 text-base w-1/4">
              <label className="w-full text-center form-label inline-block leading-5.5 font-semibold text-3.25 text-neutral-800">
                {t("LABEL_VOLUME")}
              </label>
              <p className="w-full h-full text-center justify-center mt-4 whitespace-nowrap">
                {`${getValues("volume")} m3`}
              </p>
            </div>
          </div>
        )}
      </Translation>
    );
  };

  return (
    <Translation>
      {(t) => (
        <Modal closeOnOutsideClick={props.closeOnOutsideClick} onClose={closeModal} open={open}>
          <Modal.Header>{t("LABEL_ADD_CONTAINER_AND_CARGO")}</Modal.Header>
          <Modal.Content className="bg-white grid md:w-full overflow-y-auto md:max-h-80vh">
            <form>
              <div className="grid md:grid-cols-1">
                <div className="flex flex-col py-5 mb-5 border-b border-solid border-gray-300">
                  <WithFormController control={control} name="containerType">
                    <SelectEx
                      className="mt-6 mb-3 text-base font-normal"
                      errorText={getErrorMessage("containerType", errors)}
                      getOptionLabel={containerTypeLabelMapper}
                      getOptionValue={containerTypeValueMapper}
                      hasError={errors.containerType != null}
                      id="containertype"
                      isLoading={props.loadingContainerTypes}
                      onChange={async (e: ContainerTypes) => {
                        const resourceMaxSizesByContainerType =
                          await CreateBookingService.getResourceMaxSizesByContainerType(
                            e.PackageCode
                          );
                        setResourceMaxSizes(resourceMaxSizesByContainerType);
                      }}
                      options={containerTypes}
                      placeholder={t("TEXT_ADD_CONTAINER_TYPE")}
                    />
                  </WithFormController>
                  <Checkbox
                    className="flex flex-col my-3"
                    id="containerShipperOwned"
                    label={t("LABEL_SHIPPER_OWNED_CONTAINER")}
                    type="checkbox"
                    {...register("containerShipperOwned")}
                  />
                  <div
                    className={`${
                      watch("containerShipperOwned") === true
                        ? "flex flex-row w-full justify-between"
                        : "hidden"
                    }`}
                  >
                    <InputEx
                      className="my-3 w-full mr-3"
                      label={t("LABEL_CONTAINER_NUMBER")}
                      type="text"
                      {...register("containerNumber")}
                      errorMessage={getErrorMessage("containerNumber", errors)}
                      hasError={errors.containerNumber != null}
                      id="containerNumber"
                      optional
                      placeholder={t("TEXT_ADD_CONTAINER_NUMBER")}
                    />
                    <InputEx
                      className="my-3 w-full ml-3"
                      label={t("LABEL_SEAL_NUMBER")}
                      type="text"
                      {...register("containerSealNumber")}
                      id="sealNumber"
                      optional
                      placeholder={t("TEXT_ADD_SEAL_NUMBER")}
                    />
                  </div>
                </div>
                <WithFormController control={control} name="commodity">
                  <SelectEx
                    className="mb-3 text-base font-normal"
                    errorText={getErrorMessage("commodity", errors)}
                    getOptionLabel={commodityLabelMapper}
                    getOptionValue={commodityValueMapper}
                    hasError={errors.commodity != null}
                    id="commodity"
                    isLoading={props.loadingCommodities}
                    label={t("LABEL_COMMODITY")}
                    options={props.commodities}
                    placeholder={t("TEXT_ADD_COMMODITY")}
                  />
                </WithFormController>
                <InputEx
                  className="my-3"
                  label={t("LABEL_DESCRIPTION")}
                  type="text"
                  {...register("description")}
                  errorMessage={getErrorMessage("description", errors)}
                  fullWidth
                  hasError={errors.description != null}
                  id="description"
                  maxLength={30}
                  placeholder={t("TEXT_ADD_DESCRIPTION")}
                  tooltipContent={t("INFO_DESCRIPTION")}
                />
                <div className="flex flex-row w-full mb-6">
                  <InputEx
                    className="my-3 w-full"
                    label={t("LABEL_QUANTITY")}
                    type="number"
                    {...register("quantity", {
                      onChange: setVolume,
                    })}
                    errorMessage={getErrorMessage("quantity", errors)}
                    hasError={errors.quantity != null}
                    id="quantity"
                    placeholder={t("TEXT_ADD_QUANTITY")}
                  />
                  <WithFormController control={control} name="unit">
                    <SelectEx
                      className="w-full
                        justify-center
                        my-3
                        block
                        ml-6"
                      errorText={getErrorMessage("unit", errors)}
                      getOptionLabel={packageTypeLabelMapper}
                      getOptionValue={packageTypeValueMapper}
                      hasError={errors.unit != null}
                      id="unit"
                      isLoading={props.loadingPackageTypes}
                      label={t("LABEL_UNIT")}
                      options={unit}
                      placeholder={t("TEXT_SELECT_UNIT_TYPE")}
                    />
                  </WithFormController>
                </div>
                <div className="grid grid-cols-4 justify-start">
                  <InputEx
                    className="my-3 mr-3"
                    label={t("LABEL_VOLUME")}
                    type="number"
                    {...register("volume")}
                    disabled={watch("outOfGauge")}
                    errorMessage={getErrorMessage("volume", errors)}
                    hasError={errors.volume != null}
                    id="volume"
                    min={0}
                    placeholder={t("TEXT_ADD_VOLUME")}
                    unitRight="m3"
                  />
                  <InputEx
                    className="my-3 ml-3"
                    label={t("LABEL_WEIGHT")}
                    type="number"
                    {...register("weight")}
                    errorMessage={getErrorMessage("weight", errors)}
                    hasError={errors.weight != null}
                    id="weight"
                    min={0}
                    placeholder={t("TEXT_ADD_UNIT_WEIGHT")}
                    unitRight="kg"
                  />
                </div>
                <div className="flex flex-col justify-start border-t border-solid border-gray-300 w-full my-5">
                  <label className="my-5 text-base inline-block leading-5.5 font-semibold text-3.25 text-neutral-800">
                    {t("LABEL_SPECIAL_HANDLING")}
                  </label>
                  <div className="flex md:flex-row flex-col justify-between">
                    <Checkbox
                      className="flex flex-col"
                      id="outOfGauge"
                      label={t("LABEL_OUTOFGAUGE")}
                      type="checkbox"
                      {...register("outOfGauge", {
                        onChange: () => {
                          setValue(
                            "volume",
                            calculateVolume(
                              watch("quantity"),
                              watch("height"),
                              watch("length"),
                              watch("width")
                            )
                          );
                        },
                      })}
                    />
                    <Checkbox
                      className="flex flex-col"
                      id="hazardous"
                      label={t("LABEL_HAZARDOUS")}
                      type="checkbox"
                      {...register("hazardous")}
                    />
                    <Checkbox
                      className="flex flex-col"
                      id="temperatureControl"
                      label={t("LABEL_TEMPERATURE_CONTROL")}
                      type="checkbox"
                      {...register("temperatureControl", {
                        onChange: () => {
                          setTemperatureChecked(!temperatureChecked);
                        },
                      })}
                      errorMessage={getErrorMessage("temperatureControl", errors)}
                      hasError={errors.temperatureControl != null}
                    />
                  </div>
                </div>
                <div>{watch("outOfGauge") === true ? renderDimensionsInput() : null}</div>
                <div className={`${watch("hazardous") === true ? "" : "hidden"}`}>
                  <WithFormController control={control} name="unNumbers">
                    <AsyncSelect
                      className="my-3 text-base font-normal"
                      defaultOptions={props.hazardCodes}
                      errorText={
                        Array.isArray(errors.unNumbers)
                          ? t((errors.unNumbers[0] as FieldError)?.message as string) || ""
                          : t(ErrorTypes.RequiredField)
                      }
                      getOptionLabel={hazardCodesLabelMapper}
                      getOptionValue={hazardCodesValueMapper}
                      hasError={errors.unNumbers != null}
                      id="unNumbers"
                      isLoading={props.loadingHazardCodes}
                      isMulti
                      label={t("LABEL_HAZARDOUS")}
                      loadOptions={searchHazardCodes}
                      placeholder={t("TEXT_ADD_HAZARDOUS")}
                    />
                  </WithFormController>
                </div>
                <div
                  className={`${
                    watch("temperatureControl") === true
                      ? "flex flex-row justify-between"
                      : "hidden"
                  }`}
                >
                  <WithFormController control={control} name="temperature">
                    <SelectEx
                      className="mt-6 mb-3 text-base font-normal"
                      errorText={getErrorMessage("temperature", errors)}
                      hasError={errors.temperature != null}
                      label={t("LABEL_TEMPERATURE_RANGE")}
                      options={Temperatures}
                      placeholder={t("TEXT_SELECT_TEMPERATURE_RANGE")}
                      portaling
                    />
                  </WithFormController>
                </div>
                <div className="w-full border-t border-solid border-gray-300">
                  <InputEx
                    className="my-3"
                    label={t("LABEL_MARKS_AND_NUMBERS")}
                    type="text"
                    {...register("marksAndNumbers")}
                    fullWidth
                    id="marksAndNumbers"
                    optional
                    placeholder={t("TEXT_ADD_MARKS_AND_NUMBERS")}
                    tooltipContent={t("INFO_MARKS_AND_NUMBERS")}
                  />
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Footer>
            <Button
              className="ml-2"
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              {t("LABEL_CANCEL")}
            </Button>
            <Button className="mr-2" onClick={handleSubmit(onSubmit)} primary>
              {t("LABEL_ADD")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
};

export default AddFCLCargoModal;
