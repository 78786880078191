import React, { useEffect, useState } from "react";

import { FreightMode, UserType } from "@assets/constants";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import {
  BuyersConsoleSubBooking,
  RouteBookingResponse,
  SubBookingDocuments,
  UploadDocumentsResponse,
  UserProfileViewModel,
} from "@assets/types";
import { downloadFile, openFile } from "@assets/utilities/fileUtilities";
import Alert from "@components/Alert/alert";
import Button from "@components/Button/button";
import LabelEx from "@components/Label/labelEx";
import Link from "@components/Link/link";
import DeleteModal from "@components/Modal/deleteModal";
import SendDocumentsEmailModal from "@components/Modal/sendDocumentsEmailModal";
import UploadDocumentsModal from "@components/Modal/uploadDocumentsModal";
import Page from "@components/Page/page";
import Spinner from "@components/Spinner/spinner";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ActionTable } from "@samskip/frontend-components/";
import { ActionTableProps } from "@samskip/frontend-components/dist/components/Table/actionTable";
import BookingService from "@services/BookingService";
import SingleBookingService from "@services/SingleBookingService";
import { documentState, resetUploadDocumentsState } from "@state/slices/documentsSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";

interface DocumentsPageProps {
  userProfile: UserProfileViewModel | undefined | null;
  jobReference: string;
  freightMode: string;
  routeBooking: RouteBookingResponse | null;
}

type UploadDocumentsModal = {
  openUploadDocuments: boolean;
};

type SendDocumentsEmailModal = {
  openSendDocumentsEmailModal: boolean;
};

const DocumentsComponent: React.FC<DocumentsPageProps> = (props: DocumentsPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const uploadStatus = useAppSelector(documentState).uploadStatus;

  const [uploadDocumentsModal, setUploadDocumentsModal] = useState<UploadDocumentsModal>({
    openUploadDocuments: false,
  });

  const [sendDocumentsEmailModal, setSendDocumentsEmailModal] = useState<SendDocumentsEmailModal>({
    openSendDocumentsEmailModal: false,
  });

  const [deleteModal, setDeleteModal] = useState<{ open: boolean }>({
    open: false,
  });

  const [fileId, setFileId] = useState<string | null>(null);

  const [selectedDocuments, setSelectedDocuments] = useState<UploadDocumentsResponse[]>([]);

  const [bookingsDocuments, setBookingsDocuments] = useState<UploadDocumentsResponse[]>([]);
  const [subBookingsDocuments, setSubBookingsDocuments] = useState<SubBookingDocuments[] | null>(
    null
  );

  const [loadingSubbookings, setLoadingSubbookings] = useState<boolean>(false);

  const handleSubbookings = (ref: UploadDocumentsResponse[]) => {
    const getSubbookings = async (s: BuyersConsoleSubBooking[]) => {
      let subBookingDocuments: SubBookingDocuments[] = [];
      for (const item of s) {
        const docs = await SingleBookingService.getDocumentsWithReferences(item.JobReference);
        const subbookingDocs: SubBookingDocuments = {
          JobReference: item.JobReference,
          ShipperName: item.ShipperName,
          ConsigneeName: item.ConsigneeName,
          Documents: docs,
        };
        subBookingDocuments = [...subBookingDocuments, subbookingDocs];
      }
      return subBookingDocuments;
    };

    if (props.routeBooking && props.routeBooking.HBLFlag && ref) {
      BookingService.getBCSubBookings(props.jobReference).then((s) => {
        getSubbookings(s).then((subBookingDocuments) => {
          setSubBookingsDocuments(subBookingDocuments);
          setLoadingSubbookings(false);
        });
      });
    } else {
      setLoadingSubbookings(false);
    }
  };

  useEffect(() => {
    if (bookingsDocuments) {
      SingleBookingService.getDocumentsWithReferences(props.jobReference).then((ref) => {
        setLoadingSubbookings(true);
        setBookingsDocuments(ref);
        handleSubbookings(ref);
      });
    }
  }, [window.location.href.split("#")[1] === "Documents"]);

  useEffect(() => {
    if (uploadStatus === "OK") {
      toast.success(t("TEXT_FILE_UPLOADED"));
      SingleBookingService.getDocumentsWithReferences(props.jobReference).then((ref) => {
        setBookingsDocuments(ref);
        handleSubbookings(ref);
      });
    } else if (uploadStatus === "failed") {
      toast.error(t("ERROR_FILE_DID_NOT_UPLOAD"));
    }
    dispatch(resetUploadDocumentsState());
  }, [uploadStatus]);

  const openUploadDocumentsModal = () => {
    const uploadDocModal = { ...uploadDocumentsModal };
    uploadDocModal.openUploadDocuments = true;
    setUploadDocumentsModal(uploadDocModal);
  };

  const closeUploadDocumentsModal = () => {
    const uploadDocModal = { ...uploadDocumentsModal };
    uploadDocModal.openUploadDocuments = false;
    setUploadDocumentsModal(uploadDocModal);
  };

  const openSendDocumentsEmailModal = () => {
    const sendDocumentEmailModal = { ...sendDocumentsEmailModal };
    sendDocumentEmailModal.openSendDocumentsEmailModal = true;
    setSendDocumentsEmailModal(sendDocumentEmailModal);
  };

  const closeSendDocumentsEmailModal = () => {
    const sendDocumentEmailModal = { ...sendDocumentsEmailModal };
    sendDocumentEmailModal.openSendDocumentsEmailModal = false;
    setSendDocumentsEmailModal(sendDocumentEmailModal);
  };

  const onHandleSelectedDocuments = (selectedDocumentsIds: string[]) => {
    let documents: UploadDocumentsResponse[] = [];
    for (let index = 0; index < selectedDocumentsIds.length; index++) {
      const doc = bookingsDocuments.find((document) => document.ID == selectedDocumentsIds[index]);
      if (doc) documents = [...documents, doc];
    }

    setSelectedDocuments(documents);
  };

  const isEmployee = props.userProfile?.Access.UserType === UserType.EMPLOYEE;

  const openDeleteModal = (docID: string | null) => {
    setFileId(docID);

    const openDeleteModal = {
      open: true,
    };
    setDeleteModal(openDeleteModal);
  };

  const closeDeleteModal = () => {
    const closeDeleteModal = {
      ...deleteModal,
      open: false,
    };
    setDeleteModal(closeDeleteModal);
  };

  const handleDelete = (docID: string | null) => {
    SingleBookingService.deleteDocument(docID !== null ? docID : "").then(() => {
      toast.success(t("TEXT_DOCUMENT_DELETED"));
      SingleBookingService.getDocumentsWithReferences(props.jobReference).then((ref) => {
        setBookingsDocuments(ref);
        handleSubbookings(ref);
      });
    });
  };

  const onDownload = async (id: string | null, name: string | null) => {
    if (id != null && name != null) {
      SingleBookingService.getDocument(id)
        .then((b) => {
          toast.success(t("LABEL_FILE_FOUND"));
          openFile(b);
          downloadFile(name, b);
        })
        .catch(() => {
          toast.error(t("LABEL_FILE_NOT_FOUND"));
        });
    }
  };

  const onDownloadAll = (documents: UploadDocumentsResponse[]) => {
    documents?.forEach(async (x) => {
      onDownload(x.ETag, x.Name);
    });
  };

  const getBookingDocumentsColumns = (documents: UploadDocumentsResponse[]) => {
    const bookingDocumentsColumns: ActionTableProps<UploadDocumentsResponse>["columns"] = [
      {
        dataIndex: "Name",
        id: "fileName",
        title: t("LABEL_FILENAME"),
        render: (document) => <div className="w-80 truncate">{document.Name}</div>,
        sortable: true,
      },
      {
        dataIndex: "Type",
        id: "docType",
        title: t("LABEL_DOCUMENT_TYPE"),
        className: "w-44",
        sortable: true,
      },
      {
        dataIndex: "Created",
        id: "uploadOn",
        title: t("LABEL_UPLOADED_ON"),
        className: "w-40",
        render: (document) => moment(document.Created).format("DD MMM YYYY").toString(),
        sortable: true,
      },
      {
        dataIndex: "UserName",
        id: "uplodedBy",
        title: t("LABEL_UPLOADED_BY"),
        className: "w-40",
        render: (document) => (document.UserName ? document.UserName : "-"),
        sortable: true,
      },
      {
        dataIndex: "",
        id: "download-all",
        className: "w-36",
        title: (
          <Link
            className="text-jonar-blue"
            onClick={() => onDownloadAll(documents)}
            target="_blank"
          >
            {t("LABEL_DOWNLOAD_ALL")}
          </Link>
        ),
        render: (document: { ETag: string | null; Name: string | null }) => (
          <Link
            className="text-jonar-blue"
            onClick={() => onDownload(document.ETag, document.Name)}
            target="_blank"
          >
            {t("LABEL_DOWNLOAD")}
          </Link>
        ),
      },
    ];
    return bookingDocumentsColumns;
  };

  const renderTableByIndex = (jobReference: string, documents: UploadDocumentsResponse[]) => {
    return (
      <div className="my-6">
        <ActionTable
          canRemove={isEmployee}
          columns={getBookingDocumentsColumns(documents)}
          getRowId={(doc) => doc.ID}
          headerTextCase="none"
          id={`subbooking-document-${jobReference}`}
          isBordered={false}
          onRemove={(id) => openDeleteModal(id)}
          rows={documents}
          scrollable={false}
        />
      </div>
    );
  };

  const subBookingSection = subBookingsDocuments?.map((item, index) => (
    <div key={`subbooking-${index}`}>
      <div className="grid grid-cols-3 mb-4">
        <div className="flex items-center">
          <div className="rounded-full bg-jonar-blue h-6 w-6 text-white m-2 flex justify-center items-center">
            {index + 1}
          </div>
          <div className="flex flex-col">
            <label className="text-neutral-7">{t("LABEL_BOOKING_REFERENCE")}</label>
            {item.JobReference ? (
              <div>
                <Link
                  href={`/singleBooking/${props.freightMode}/${item.JobReference}`}
                  icon={faArrowUpRightFromSquare}
                  target="_blank"
                >
                  {item.JobReference}
                </Link>
              </div>
            ) : (
              <div>N/A</div>
            )}
          </div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_SHIPPER")}</label>
          <div className="text-neutral-9">{item.ShipperName ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_CONSIGNEE")}</label>
          <div className="text-neutral-9">{item.ConsigneeName ?? "N/A"}</div>
        </div>
      </div>
      {renderTableByIndex(item.JobReference, item.Documents)}
    </div>
  ));

  return (
    <>
      <UploadDocumentsModal
        closeIcon
        closeModal={() => closeUploadDocumentsModal()}
        closeOnOutsideClick={false}
        freightMode={props.freightMode === FreightMode.SH ? FreightMode.SH : FreightMode.AI}
        jobReference={props.jobReference}
        open={uploadDocumentsModal.openUploadDocuments}
        userName={props.userProfile?.User.Username ?? ""}
      />
      <SendDocumentsEmailModal
        closeIcon
        closeModal={() => closeSendDocumentsEmailModal()}
        closeOnOutsideClick={false}
        documents={bookingsDocuments ?? []}
        open={sendDocumentsEmailModal.openSendDocumentsEmailModal}
        selectedDocumentsIndex={selectedDocuments}
      />
      {deleteModal.open && (
        <DeleteModal
          closeModal={closeDeleteModal}
          content={t("TEXT_DELETE_DOCUMENT")}
          header={t("LABEL_DELETE_DOCUMENT")}
          onDelete={() => handleDelete(fileId)}
          open={deleteModal.open}
        />
      )}
      <div className="md:my-6 md:ml-6 flex gap-4 justify-center md:justify-end rounded my-6 ml-40">
        <Button onClick={() => openUploadDocumentsModal()}>{t("LABEL_UPLOAD_DOCUMENTS")}</Button>

        <Button
          disabled={selectedDocuments.length <= 0}
          onClick={() => openSendDocumentsEmailModal()}
        >
          {t("LABEL_SEND_EMAIL")}
        </Button>
      </div>
      {loadingSubbookings ? (
        <Spinner className="w-full flex align-center justify-center" />
      ) : (
        <>
          <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
            <div className="w-full">
              {bookingsDocuments && bookingsDocuments.length == 0 ? (
                <div className="text-neutral-700 px-8 py-6 text-sm">
                  {t("TEXT_NO_DOCUMENTS_AVAILABLE")}
                </div>
              ) : (
                <div className="mx-8 my-6">
                  {subBookingsDocuments !== null && (
                    <LabelEx className="text-lg font-semibold text-neutral-900 mb-6">
                      {t("LABEL_BUYERS_CONSOL")}
                    </LabelEx>
                  )}
                  <ActionTable
                    canRemove={isEmployee}
                    columns={getBookingDocumentsColumns(bookingsDocuments)}
                    getRowId={(doc) => doc.ID}
                    headerTextCase="none"
                    id="booking-document"
                    isBordered={false}
                    onRemove={(id) => openDeleteModal(id)}
                    rowSelection={{
                      allowSingleRowSelection: true,
                      onChange: (selectedRows) => {
                        onHandleSelectedDocuments(selectedRows);
                      },
                    }}
                    rows={bookingsDocuments}
                    scrollable={false}
                  />
                </div>
              )}
            </div>
          </Page>
          {subBookingsDocuments && subBookingsDocuments.length > 0 && (
            <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
              <div className="w-full">
                <div className="mx-8 my-6">
                  <LabelEx className="text-lg font-semibold text-neutral-900 mb-6">
                    {t("LABEL_BUYERS_CONSOL_SUB")}
                  </LabelEx>
                  {subBookingSection}
                </div>
              </div>
            </Page>
          )}
          {bookingsDocuments && bookingsDocuments.length > 0 && (
            <Alert className="py-2 px-5 mt-6 text-base flex items-start md:w-full w-375px rounded">
              <FaInfoCircle className="text-jonar-blue left-2 right-2 top-2 bottom-2 mr-2 mt-1 w-10" />
              {t("INFO_REPLACE_DOCUMENTS")}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default DocumentsComponent;
