import React from "react";

import { Label } from "@assets/types";
import { Translation } from "react-i18next";

import LabelComponent from "./label";

type LabelListProps = {
  className?: string;
  labelList: Label[];
  onClick: Function;
};

const onRemove = (labels: Label[], key: string) => {
  if (key) {
    return labels.filter((l: Label) => l.key !== key);
  }

  return [];
};

const onAdd = (labels: Label[], value: string | boolean, key: string, text: string) => {
  const isDuplicate = labels.find((l: Label) => l.key === key);
  const isEmpty = !((value && value !== "") || value === false);

  if (isEmpty) {
    return onRemove(labels, key);
  }

  if (isDuplicate) {
    return labels;
  }

  if (!text) {
    return labels;
  }

  return [...labels, { key, text }];
};

export const LabelList: React.FC<LabelListProps> = ({ className, labelList, onClick }) => {
  return (
    <Translation>
      {(t) => (
        <div className={`flex items-center leading-none ${className}`}>
          <span className="inline-block pt-1">{`${t("LABEL_ACTIVE_FILTER")}`}</span>
          <div className="flex ml-2">
            {labelList.map((label) => {
              return <LabelComponent key={label.key} label={label} onClick={onClick} />;
            })}
          </div>
        </div>
      )}
    </Translation>
  );
};

export { onAdd, onRemove };

export default LabelList;
