import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    height="512.000000pt"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    viewBox="0 0 512.000000 512.000000"
    width="512.000000pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="#000000"
      stroke="none"
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    >
      <path
        d="M1254 5106 c-18 -14 -19 -33 -24 -438 l-5 -423 -213 -5 c-197 -5
-215 -7 -228 -24 -12 -16 -14 -92 -14 -432 l0 -413 -199 -3 c-179 -3 -201 -5
-220 -22 -21 -19 -21 -26 -23 -535 l-3 -515 -140 -73 c-146 -75 -180 -102
-171 -138 3 -12 118 -261 256 -553 l250 -532 0 -104 c0 -124 17 -220 60 -339
35 -94 119 -237 189 -318 35 -40 41 -53 30 -60 -26 -16 -45 -9 -173 68 -112
67 -135 77 -186 81 -71 5 -102 -8 -285 -118 -134 -81 -155 -100 -155 -140 0
-29 44 -70 75 -70 15 0 91 38 177 90 83 50 158 90 166 90 9 0 74 -35 146 -77
l131 -78 75 0 c74 0 78 2 232 78 180 90 147 91 302 -12 99 -66 99 -66 170 -66
70 0 73 1 213 78 111 61 147 77 165 71 13 -4 79 -39 147 -77 106 -61 132 -72
182 -75 56 -4 62 -2 212 77 86 44 168 81 183 81 16 0 67 -25 123 -59 142 -87
157 -93 224 -94 56 0 71 5 208 76 82 43 160 77 174 77 15 0 80 -32 148 -72
120 -72 122 -73 192 -73 68 0 74 2 203 72 75 41 146 73 161 73 19 0 54 -20
110 -65 90 -70 141 -90 209 -81 91 12 128 54 102 117 -16 38 -52 54 -93 39
-46 -16 -50 -14 -139 53 -105 80 -132 90 -210 85 -55 -3 -79 -13 -195 -76 -73
-40 -142 -72 -153 -72 -12 0 -76 33 -142 72 -113 67 -126 73 -187 76 -66 4
-67 4 -227 -78 -127 -65 -165 -80 -187 -75 l-26 7 47 58 c139 172 212 381 212
606 l0 107 236 559 c142 336 237 572 236 590 0 16 -6 36 -13 45 -7 8 -75 46
-151 84 l-137 69 -3 511 -3 511 -24 19 c-21 17 -41 19 -222 19 l-199 0 0 424
c0 381 -2 425 -17 437 -12 11 -68 15 -222 19 l-206 5 -5 423 c-5 405 -6 424
-24 438 -29 21 -1093 21 -1122 0z m996 -506 l0 -360 -435 0 -435 0 0 360 0
360 435 0 435 0 0 -360z m-510 -870 l0 -360 -410 0 -410 0 0 360 0 360 410 0
410 0 0 -360z m970 -10 l0 -360 -410 0 -410 0 0 360 0 360 410 0 410 0 0 -360z
m440 -920 l0 -400 -82 41 c-154 77 -1163 601 -1187 616 -14 9 -36 13 -55 10
-17 -3 -319 -156 -672 -341 -352 -184 -648 -338 -657 -342 -16 -6 -17 24 -17
405 l0 411 1335 0 1335 0 0 -400z m-1390 -820 l0 -890 -554 0 c-431 0 -556 3
-563 13 -28 35 -445 939 -437 947 7 7 1539 816 1552 819 1 1 2 -399 2 -889z
m1148 370 c292 -151 531 -279 531 -285 0 -5 -93 -226 -207 -490 l-207 -480
-552 -3 -553 -2 0 885 0 885 228 -117 c125 -65 467 -242 760 -393z m69 -1527
c-8 -136 -27 -214 -82 -328 -49 -100 -136 -215 -164 -215 -10 0 -26 8 -35 19
-24 26 -98 45 -146 37 -23 -3 -105 -41 -183 -83 -88 -48 -153 -77 -172 -77
-19 0 -68 24 -139 68 -197 123 -221 123 -425 11 -76 -41 -148 -75 -161 -75
-13 0 -73 32 -133 72 -98 65 -114 72 -167 76 -52 4 -68 0 -141 -34 l-82 -38
-39 44 c-154 176 -238 370 -238 552 l0 78 1156 0 1157 0 -6 -107z"
      />
      <path
        d="M1202 2382 c-181 -65 -233 -291 -99 -430 54 -57 102 -77 187 -77 57
0 84 5 115 22 47 25 96 75 120 122 23 45 30 135 16 188 -38 138 -202 223 -339
175z m147 -158 c31 -22 52 -75 45 -113 -18 -88 -148 -114 -194 -38 -11 18 -20
42 -20 55 0 31 25 77 52 96 29 21 87 20 117 0z"
      />
      <path
        d="M2283 2382 c-72 -26 -114 -61 -148 -123 -58 -106 -32 -247 58 -324
141 -121 362 -61 422 114 19 56 19 104 0 167 -40 135 -197 214 -332 166z m164
-177 c49 -56 20 -155 -50 -173 -95 -26 -171 71 -123 157 32 58 130 67 173 16z"
      />
      <path
        d="M3851 4634 c-21 -26 -21 -36 -21 -491 l0 -465 29 -29 30 -30 521 3
522 3 19 24 c18 22 19 45 19 497 0 461 -1 475 -20 494 -19 19 -33 20 -549 20
l-530 0 -20 -26z m969 -489 l0 -365 -415 0 -415 0 0 365 0 365 415 0 415 0 0
-365z"
      />
      <path
        d="M3879 3399 c-15 -6 -32 -22 -38 -37 -8 -20 -11 -163 -9 -491 l3 -463
24 -19 c22 -18 46 -19 547 -19 511 0 525 1 544 20 19 19 20 33 20 494 0 452
-1 475 -19 497 l-19 24 -514 2 c-318 1 -523 -2 -539 -8z m941 -514 l0 -365
-415 0 -415 0 0 365 0 365 415 0 415 0 0 -365z"
      />
      <path
        d="M3859 2131 l-24 -19 0 -482 0 -482 24 -19 c22 -18 46 -19 545 -19
l523 0 21 23 c22 23 22 24 22 499 l0 477 -26 20 c-26 21 -36 21 -544 21 -495
0 -519 -1 -541 -19z m959 -503 l-3 -363 -412 -3 -413 -2 0 365 0 365 415 0
415 0 -2 -362z"
      />
      <path
        d="M3420 1013 c-56 -21 -70 -96 -24 -132 27 -21 32 -21 851 -21 l824 0
24 25 c41 40 26 106 -28 125 -31 10 -1618 14 -1647 3z"
      />
    </g>
  </svg>
);

export default SVG;
