import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M21.47,19.57l-4.13-4.13l4.13-4.13l4.13,4.13L21.47,19.57z M16.07,35.2L1.57,20.7L16.21,6.06L30.85,20.7
                L16.34,35.21L16.07,35.2z M48.43,22.97l-7.44-7.44 M13.42,37.52L1.96,26.06l2.48-2.49L15.9,35.04 M19.98,36.54l25.6-25.6L43.1,8.45
                L21.14,30.41l-4.64,4.64 M20.24,36.71c1.66,1.66,1.67,4.35,0.01,6c-1.65,1.65-4.34,1.65-6-0.01c-1.66-1.66-1.67-4.35-0.01-6
                S18.58,35.05,20.24,36.71z"
      />
    </g>
  </svg>
);

export default SVG;
