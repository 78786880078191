import React, { FC, ReactNode, useState } from "react";

import { classNames } from "@assets/helperFunctions";
import { TableInfo } from "@assets/types";
import Button from "@components/Button/button";
import Spinner from "@components/Spinner/spinner";
import OverflowTip from "@components/Tooltip/overflowTip";
import Tooltip from "@components/Tooltip/tooltip";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import styles from "./table.module.less";
import TableFade from "./tableFade";

type TableProps = {
  className?: string;
  tableInfo: TableInfo;
  noDataMessage?: string;
  shouldFade?: boolean;
  error?: string | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHeader?: (head: any) => void;
  disabled?: boolean;
  buttonLabel?: string;
  buttonTooltip?: string;
  isLight?: boolean;
  isStriped?: boolean;
  isBordered?: boolean;
  isLoading?: boolean;
};

const Table: FC<TableProps> = ({
  className,
  tableInfo,
  noDataMessage,
  shouldFade = false,
  error,
  onClick,
  onClickHeader,
  disabled,
  buttonLabel,
  buttonTooltip,
  isLight = false,
  isStriped = false,
  isBordered = false,
  isLoading = false,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);
  const [openNoteIndex, setOpenNoteIndex] = useState<number | null>(null);
  const { t } = useTranslation();

  const onScroll = (() => {
    let ticking = false;
    let lastScrollLeft = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e: any) => {
      if (e.target.offsetWidth + e.target.scrollLeft == e.target.scrollWidth) {
        setEndPosition(e.target.scrollLeft);
      }
      if (e.target.scrollLeft == 0) {
        setEndPosition(e.target.scrollLeft);
      }
      if (!ticking) {
        window.requestAnimationFrame(function () {
          const documentScrollLeft = e.target.scrollLeft;
          if (lastScrollLeft != documentScrollLeft) {
            lastScrollLeft = documentScrollLeft;
            setScrollPosition(lastScrollLeft);
          }

          ticking = false;
        });
        ticking = true;
      }
      setScrollPosition(0);
    };
  })();

  return (
    <>
      {shouldFade ? <TableFade endingPoint={endPosition} scrollPosition={scrollPosition} /> : null}
      <div
        className={classNames(
          `flex flex-col flex-grow md:overflow-x-hidden md:w-full ${className}`,
          isBordered ? `outline outline-1 outline-neutral-500` : ``
        )}
        onScroll={onScroll}
      >
        {isLoading ? (
          <Spinner className="w-full flex align-center justify-center" />
        ) : (
          <table className={`md:table md:table-fixed ${styles.striped}`} style={{ width: "100%" }}>
            <thead>
              <tr>
                {tableInfo.tableHeader.map((head, index) => (
                  <th
                    className={classNames(
                      `h-14`,
                      tableInfo.classes[index],
                      `text-left`,
                      isLight ? `text-neutral-0` : `text-neutral-900`,
                      onClickHeader ? "cursor-pointer" : ""
                    )}
                    key={index}
                    onClick={() => onClickHeader(head)}
                    style={{ width: "30%" }}
                  >
                    {head != null && t(head.toString()) != "" && typeof head == "string"
                      ? t(head.toString())
                      : head}
                    {head != null &&
                      t(head.toString()) != "" &&
                      typeof head == "string" &&
                      onClickHeader && (
                        <FontAwesomeIcon className="px-2 text-neutral-500" icon={faSort} />
                      )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableInfo.tableData.map((row, tindex) => (
                <tr
                  className={classNames(`h-14`, isStriped ? "even:bg-neutral-100" : "")}
                  key={tindex}
                >
                  {row.map((field, index) => (
                    <td
                      className={classNames(
                        tableInfo.classes[index],
                        `text-left`,
                        isLight ? `text-neutral-0` : `text-neutral-900`
                      )}
                      key={index}
                      onClick={() => {
                        if (openNoteIndex === tindex) {
                          setOpenNoteIndex(null);
                        } else {
                          setOpenNoteIndex(tindex);
                        }
                      }}
                    >
                      <OverflowTip
                        childClassName="w-full"
                        content={field as ReactNode}
                        isTextVisible={tindex === openNoteIndex}
                        key={index}
                      >
                        {field != null &&
                        t(field.toString()) != "" &&
                        t(field.toString()) != "[object Object]" &&
                        field != "[object Object]"
                          ? t(field.toString())
                          : field || "-"}
                      </OverflowTip>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {tableInfo.tableData.length < 1 ? (
          <div className="flex w-full flex-col flex-grow">
            <span className="flex flex-grow h-full justify-center items-center font-medium text-normal-text-color text-xl text-opacity-50">
              {noDataMessage}
            </span>
          </div>
        ) : null}
        <span className="text-red-500 my-2">
          {error != null && error != undefined ? t(error) : ""}
        </span>
        {buttonTooltip && (
          <Tooltip childClassName="inline-flex" content={buttonTooltip} direction="topLeft">
            <div className="flex mb-5 mt-2">
              <Button
                className="flex items-center border-none text-ocean-blue"
                disabled={disabled}
                onClick={onClick}
              >
                {buttonLabel}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default Table;
