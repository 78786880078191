import React from "react";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${className}`}
    fill="none"
    height="20"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11" fill="none" r="8" stroke="#9A9B9C" strokeWidth="2px" />
    <line fill="none" stroke="#9A9B9C" strokeWidth="2px" x1="21" x2="16.65" y1="21" y2="16.65" />
  </svg>
);

export default SVG;
