import React, { PureComponent } from "react";

import { LoginState, PasswordModalProps } from "@assets/types";
import Button from "@components/Button/button";
import Input from "@components/Input/input";
import Modal from "@components/Modal/modal";
import { login, logout, closeModal, setAccessTokenAndTimeout } from "@state/actions/index";
import { AppState } from "@state/reducers/types";
import { navigate } from "gatsby-link";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

class PasswordModal extends PureComponent<
  PasswordModalProps,
  {
    password: string;
    lastAccessTokenTime: number;
  }
> {
  state = { loginInProgress: false, password: "", lastAccessTokenTime: 0 };

  handleLogin = async (loginValues: LoginState) => {
    if (!this.state.loginInProgress) {
      try {
        await this.props.login(loginValues, { tokenRefresh: true });
      } catch (error) {
        console.log(error);
      }
    }
  };

  componentDidUpdate(prevProps: PasswordModalProps) {
    if (this.props.open && !this.props.userProfile) {
      navigate("/login");
      this.setState({ password: "" });
      this.props.closeModal();
    }

    if (prevProps.open === false && this.props.open === true) {
      this.onOpen();
    }
  }

  onOpen = () => {
    this.setState(
      {
        lastAccessTokenTime: new Date(
          localStorage.getItem("lastAccessTokenRefresh") as string
        ).getTime(),
      },
      () => {
        const interval = setInterval(() => {
          const accessTokenTime = new Date(
            localStorage.getItem("lastAccessTokenRefresh") as string
          ).getTime();
          if (
            this.state.lastAccessTokenTime !== 0 &&
            accessTokenTime > this.state.lastAccessTokenTime
          ) {
            clearInterval(interval);
            this.props.closeModal();
            this.props.setAccessTokenAndTimeout(new Date(accessTokenTime));
          }
        }, 10000);
      }
    );
  };

  render() {
    const { open, header, loginInProgress, logoutInProgress } = this.props;
    const { password } = this.state;
    return (
      <Translation>
        {(t) => (
          <Modal onClose={this.props.closeModal} open={open} size="small">
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
              <form
                autoComplete="on"
                className="container px-3 py-2"
                name="loginForm"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <p className="py-2 mx-1 pb-0">{t("TEXT_SESSION_EXPIRED")}</p>
                <div className="border-b mx-1 max-w-280 border-gray-300 mb-2 p-2 pl-0">
                  <Input
                    className="bg-transparent leading-tight text-gray-700 mr-3"
                    name="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ password: event.target.value })
                    }
                    placeholder={t("LABEL_PASSWORD")}
                    type="password"
                    value={password}
                  />
                </div>
                <Modal.Footer>
                  <Button
                    active={logoutInProgress}
                    className="mt-2 mr-3"
                    onClick={async () => {
                      await this.props.logout(this.props.userProfile.Access);
                      this.setState({ password: "" });
                      this.props.closeModal();
                    }}
                    type="submit"
                  >
                    {t("TEXT_LOGOUT")}
                  </Button>
                  <Button
                    active={loginInProgress}
                    className="mt-2"
                    disabled={this.state.password.length < 1}
                    onClick={() => {
                      this.handleLogin({
                        username: this.props.userProfile.Access.LoginID,
                        password: this.state.password,
                      });
                    }}
                    primary
                    type="submit"
                  >
                    {t("LABEL_BUTTON_LOGIN")}
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Content>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userProfile: state.UserReducer.userProfile,
  loginInProgress: state.UserReducer.loginInProgress,
  logoutInProgress: state.UserReducer.logoutInProgress,
});

export default connect(mapStateToProps, {
  login,
  logout,
  closeModal,
  setAccessTokenAndTimeout,
})(PasswordModal);
