import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M8.23,45.67h33.54 M8.23,4.33h33.54 M37.78,8.6V4.33H12.22V8.6c0,6.91,3.85,13.66,9.13,15.53v1.74
                c-5.28,1.87-9.13,8.63-9.13,15.53v4.27h25.55V41.4c0-6.91-3.85-13.66-9.13-15.53v-1.74C33.92,22.26,37.78,15.51,37.78,8.6z"
      />
      <path
        className={`${styles.st0}`}
        d="M25,33.35v-12.5 M33.13,41.49H16.87L25,33.35L33.13,41.49z"
      />
      <path
        className={`${styles.st0}`}
        d="M25,20.35c3.72,0,6.74-2.77,6.74-6.2H18.25C18.25,17.57,21.27,20.35,25,20.35z"
      />
    </g>
  </svg>
);

export default SVG;
