import React from "react";

import { Translation } from "react-i18next";
import { CgSortAz, CgSortZa } from "react-icons/cg";
import { FaTimes } from "react-icons/fa";

import styles from "./select.module.less";

type SelectProps = {
  className?: string;
  name: string;
  sortDirection: boolean;
  isDesc?: boolean;
  onSortClick?: Function;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: SelectItemProps[] | undefined | null;
  value?: string;
  placeholder?: string;
  onCloseIcon?: Function;
  disabled?: boolean;
};

export type SelectItemProps = {
  value: string | number | undefined;
  text: string | undefined;
  translationId: string | undefined;
};

const Select: React.FC<SelectProps> = ({
  name,
  onChange,
  sortDirection,
  isDesc,
  onSortClick,
  className,
  options,
  value,
  placeholder,
  onCloseIcon,
  disabled,
}) => (
  <Translation>
    {(t) => (
      <>
        <div className="flex w-full">
          <select
            className={`cursor-pointer border-none appearance-none focus:outline-none pt-2 pb-1 pl-2 pr-8 ${className} ${
              styles.select
            }  ${disabled ? "bg-gray-200" : ""}`}
            disabled={disabled}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          >
            {options?.map((option: SelectItemProps, i: number) => {
              return (
                <option key={i} value={option.value}>
                  {option.translationId != null && option.translationId != ""
                    ? `${t(option.translationId)}`
                    : option.text}
                </option>
              );
            })}
          </select>
          {onCloseIcon ? (
            <div className="mt-2">
              <FaTimes
                className="text-gray-600 hover:text-gray-700 cursor-pointer text-lg"
                onClick={onCloseIcon ? () => onCloseIcon() : undefined}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className={`cursor-pointer ${sortDirection === true ? "inline-block" : "hidden"}`}>
          {isDesc ? (
            <CgSortZa
              className="cursor-pointer inline-block text-lg text-jonar-blue"
              onClick={onSortClick ? () => onSortClick() : undefined}
              title={t("LABEL_SORT_Z_A")}
            />
          ) : (
            <CgSortAz
              className="cursor-pointer ml-2 inline-block text-lg fill-jonar-blue"
              onClick={onSortClick ? () => onSortClick() : undefined}
              title={t("LABEL_SORT_A_Z")}
            />
          )}
        </div>
      </>
    )}
  </Translation>
);

export default Select;
