exports.components = {
  "component---src-pages-administration-administration-tsx": () => import("./../../../src/pages/Administration/administration.tsx" /* webpackChunkName: "component---src-pages-administration-administration-tsx" */),
  "component---src-pages-finance-finance-tsx": () => import("./../../../src/pages/Finance/finance.tsx" /* webpackChunkName: "component---src-pages-finance-finance-tsx" */),
  "component---src-pages-finance-storage-fees-tsx": () => import("./../../../src/pages/Finance/storageFees.tsx" /* webpackChunkName: "component---src-pages-finance-storage-fees-tsx" */),
  "component---src-pages-login-login-tsx": () => import("./../../../src/pages/Login/login.tsx" /* webpackChunkName: "component---src-pages-login-login-tsx" */),
  "component---src-pages-login-password-reset-forgot-password-tsx": () => import("./../../../src/pages/Login/PasswordReset/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-login-password-reset-forgot-password-tsx" */),
  "component---src-pages-login-password-reset-reset-password-tsx": () => import("./../../../src/pages/Login/PasswordReset/resetPassword.tsx" /* webpackChunkName: "component---src-pages-login-password-reset-reset-password-tsx" */),
  "component---src-pages-maintenance-maintenance-tsx": () => import("./../../../src/pages/Maintenance/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-maintenance-tsx" */),
  "component---src-pages-master-master-tsx": () => import("./../../../src/pages/Master/master.tsx" /* webpackChunkName: "component---src-pages-master-master-tsx" */),
  "component---src-pages-not-found-not-found-tsx": () => import("./../../../src/pages/NotFound/notFound.tsx" /* webpackChunkName: "component---src-pages-not-found-not-found-tsx" */),
  "component---src-pages-requests-requests-tsx": () => import("./../../../src/pages/Requests/requests.tsx" /* webpackChunkName: "component---src-pages-requests-requests-tsx" */),
  "component---src-pages-shipments-create-trace-add-documents-component-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/addDocumentsComponent.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-add-documents-component-tsx" */),
  "component---src-pages-shipments-create-trace-cargo-information-component-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/cargoInformationComponent.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-cargo-information-component-tsx" */),
  "component---src-pages-shipments-create-trace-create-booking-component-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/createBookingComponent.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-create-booking-component-tsx" */),
  "component---src-pages-shipments-create-trace-create-trace-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/createTrace.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-create-trace-tsx" */),
  "component---src-pages-shipments-create-trace-review-component-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/reviewComponent.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-review-component-tsx" */),
  "component---src-pages-shipments-create-trace-route-component-tsx": () => import("./../../../src/pages/Shipments/CreateTrace/routeComponent.tsx" /* webpackChunkName: "component---src-pages-shipments-create-trace-route-component-tsx" */),
  "component---src-pages-shipments-customs-requests-tsx": () => import("./../../../src/pages/Shipments/customsRequests.tsx" /* webpackChunkName: "component---src-pages-shipments-customs-requests-tsx" */),
  "component---src-pages-shipments-document-upload-document-upload-tsx": () => import("./../../../src/pages/Shipments/DocumentUpload/documentUpload.tsx" /* webpackChunkName: "component---src-pages-shipments-document-upload-document-upload-tsx" */),
  "component---src-pages-shipments-shipments-tsx": () => import("./../../../src/pages/Shipments/shipments.tsx" /* webpackChunkName: "component---src-pages-shipments-shipments-tsx" */),
  "component---src-pages-shipments-single-booking-cargo-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/cargoTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-cargo-tab-tsx" */),
  "component---src-pages-shipments-single-booking-documents-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/documentsTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-documents-tab-tsx" */),
  "component---src-pages-shipments-single-booking-invoice-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/invoiceTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-invoice-tab-tsx" */),
  "component---src-pages-shipments-single-booking-notes-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/notesTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-notes-tab-tsx" */),
  "component---src-pages-shipments-single-booking-pallet-info-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/palletInfoTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-pallet-info-tab-tsx" */),
  "component---src-pages-shipments-single-booking-requests-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/requestsTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-requests-tab-tsx" */),
  "component---src-pages-shipments-single-booking-route-tab-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/routeTab.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-route-tab-tsx" */),
  "component---src-pages-shipments-single-booking-single-booking-tsx": () => import("./../../../src/pages/Shipments/SingleBooking/singleBooking.tsx" /* webpackChunkName: "component---src-pages-shipments-single-booking-single-booking-tsx" */),
  "component---src-pages-users-user-profile-tsx": () => import("./../../../src/pages/Users/userProfile.tsx" /* webpackChunkName: "component---src-pages-users-user-profile-tsx" */),
  "component---src-pages-warehouse-receptions-tsx": () => import("./../../../src/pages/Warehouse/receptions.tsx" /* webpackChunkName: "component---src-pages-warehouse-receptions-tsx" */)
}

