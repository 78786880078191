import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="42.45,36.33 31.11,25 42.45,13.67 36.33,7.55 25,18.89 13.67,7.55 7.55,13.67 18.89,25 7.55,36.33 
                13.67,42.45 25,31.11 36.33,42.45"
      />
    </g>
  </svg>
);

export default SVG;
