import { ContainerTypes } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getContainerTypes = createAsyncThunk("registry/containertypes", async () => {
  return await CreateBookingService.getContainerTypes();
});

export const getContainerTypeByCode = createAsyncThunk(
  "registry/containertype/code",
  async (code: string) => await CreateBookingService.getContainerTypeByCode(code)
);

export interface ContainerTypesState {
  containerTypes: ContainerTypes[];
  loadingContainerTypes: boolean;
  containerTypesByCode: ContainerTypes | null;
}

export const initialContainerTypesState: ContainerTypesState = {
  containerTypes: [],
  loadingContainerTypes: false,
  containerTypesByCode: null,
};

export const containerTypesSlice = createSlice({
  name: "containerTypes",
  initialState: initialContainerTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContainerTypes.pending, (state) => {
        state.loadingContainerTypes = true;
      })
      .addCase(getContainerTypes.rejected, (state) => {
        state.loadingContainerTypes = false;
        state.containerTypes = [];
      })
      .addCase(getContainerTypes.fulfilled, (state, action: PayloadAction<ContainerTypes[]>) => {
        state.loadingContainerTypes = false;
        state.containerTypes = action.payload;
      })
      .addCase(getContainerTypeByCode.rejected, (state) => {
        state.containerTypesByCode = null;
      })
      .addCase(getContainerTypeByCode.fulfilled, (state, action: PayloadAction<ContainerTypes>) => {
        state.containerTypesByCode = action.payload;
      });
  },
});

export const containerTypesState = (state: RootState) => state.containerTypesSlice;

export default containerTypesSlice.reducer;
