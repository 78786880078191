class DateUtilities {
  /**
   * Returns a string representing the day, month and year of a date object
   * @param date Either a Date object or a string representing a date
   * @returns String value representing the date - dd/mm/yyyy
   */
  toDateString(date: string | Date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    } as Intl.DateTimeFormatOptions;
    if (date instanceof Date) {
      return date.toLocaleDateString(undefined, options);
    }
    return new Date(date).toLocaleDateString(undefined, options);
  }

  /** Convert the given date to UTC, without taking the timezone into consideration.
   * Ex. LocalDate is Nov 16 2022 00:00:00 GMT+0200 => The return will be Nov 16 2022 00:00:00 GMT
   * @param localDate The date to copy into UTC.
   **/
  dateToUTC_WithoutTimezoneChange = (localDate: Date) =>
    new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
}

export default new DateUtilities();
