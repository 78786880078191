import React from "react";

import CustomIcon from "@components/CustomIcon/customIcon";
import Tooltip from "@components/Tooltip/tooltip";

type ListLineItemProps = {
  className?: string;
  preTitle: string;
  mainTitle: string;
  icon?: string;
  iconClasses?: string;
  actionLink?: string;
  actionFunction?: Function;
  secondLine?: React.ReactNode;
  secondLineTitle?: string;
  target?: string;
};

const renderMainTitle = (mainTitle: string, icon?: string, iconClasses?: string) => {
  return (
    <>
      {mainTitle}
      {icon && <CustomIcon className={`inline-block ml-2 -mt-1 ${iconClasses}`} iconName={icon} />}
    </>
  );
};

const ListLineItem: React.FC<ListLineItemProps> = ({
  className,
  preTitle,
  mainTitle,
  icon,
  iconClasses,
  actionLink,
  actionFunction,
  secondLine,
  secondLineTitle,
  target,
}) => {
  return (
    <div className={`pr-10 truncate ${className}`}>
      <h4 className="text-xs font-semibold uppercase text-gray-500">{preTitle}</h4>
      {mainTitle ? (
        <Tooltip childClassName="inline-flex" content={mainTitle}>
          <h3 className="pt-1 font-semibold leading-none truncate overflow-ellipsis">
            {actionLink ? (
              <a
                className="text-jonar-blue transition duration-300 hover:text-jonar-orange"
                href={actionLink}
                target={target}
              >
                {renderMainTitle(mainTitle, icon, iconClasses)}
              </a>
            ) : actionFunction ? (
              <a
                className="text-jonar-blue actionFunction transition duration-300 hover:text-jonar-orange"
                onClick={() => actionFunction()}
              >
                {renderMainTitle(mainTitle, icon, iconClasses)}
              </a>
            ) : (
              renderMainTitle(mainTitle, icon, iconClasses)
            )}
          </h3>
        </Tooltip>
      ) : (
        <h3 className="pt-1 font-semibold leading-none truncate overflow-ellipsis">&nbsp;&nbsp;</h3>
      )}
      {secondLine && (
        <div className="text-xs">
          {secondLineTitle && <span className="uppercase font-semibold">{secondLineTitle} </span>}
          {secondLine}
        </div>
      )}
    </div>
  );
};

export default ListLineItem;
