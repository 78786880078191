import React, { useState } from "react";

import { FreightMode, ShippingType } from "@assets/constants";
import {
  AddCargoData,
  Commodities,
  ContainerTypes,
  HazardCodes,
  PackageTypes,
} from "@assets/types";
import Button from "@components/Button/button";
import AddFCLCargoModal from "@components/Modal/addFCLCargoModal";
import AddLCLCargoModal from "@components/Modal/addLCLCargoModal";
import CargoSection from "@components/Section/cargoSection";
import Tooltip from "@components/Tooltip/tooltip";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

type CargoCellProps = {
  data: AddCargoData[][];
  className?: string;
  shippingTypeValue: string | null;
  freightMode: FreightMode;
  hazardCodes: HazardCodes[];
  packageTypes: PackageTypes[];
  containerTypes: ContainerTypes[];
  commodities: Commodities[];
  cargoLinesError: string | null;
  loadingContainerTypes: boolean;
  loadingPackageTypes: boolean;
  loadingHazardCodes: boolean;
  loadingCommodities: boolean;
  onChangeCargoData: (addCargoData: AddCargoData, containerIndex: number | null) => void;
  onDeleteCargoData: (cargoIndex: number, containerIndex: number | null) => void;
  onEditCargoData: (
    cargoIndex: number,
    containerIndex: number | null,
    addCargoData: AddCargoData
  ) => void;
  getShippingType: (shippingType: string | null) => ShippingType;
};

type AddCargoDataModal = {
  initialAddCargoData: AddCargoData;
  openAddCargoModal: ShippingType | null;
  isEdit: boolean;
  cargoIndex: number;
  containerIndex?: number;
};

export const CargoCell: React.FC<CargoCellProps> = ({
  className,
  data,
  shippingTypeValue,
  freightMode,
  hazardCodes,
  packageTypes,
  containerTypes,
  commodities,
  cargoLinesError,
  loadingContainerTypes,
  loadingPackageTypes,
  loadingHazardCodes,
  loadingCommodities,
  onDeleteCargoData,
  onChangeCargoData,
  onEditCargoData,
  getShippingType,
}) => {
  const { t } = useTranslation();

  const initialCargoData: AddCargoData = {
    commodity: null,
    quantity: undefined,
    unit: null,
    description: "",
    containerType: null,
    containerShipperOwned: false,
    containerNumber: "",
    containerSealNumber: "",
    temperatureControl: false,
    temperatureMin: "",
    temperatureMax: "",
    stackable: false,
    dimensions: [
      {
        quantity: null,
        length: null,
        width: null,
        height: null,
      },
    ],
    height: undefined,
    weight: undefined,
    length: undefined,
    width: undefined,
    volume: undefined,
    hazardous: false,
    unNumbers: null,
    outOfGauge: false,
    marksAndNumbers: "",
  };

  const [addCargoModal, setAddCargoModal] = useState<AddCargoDataModal>({
    openAddCargoModal: null,
    initialAddCargoData: initialCargoData,
    isEdit: false,
    cargoIndex: 0,
    containerIndex: 0,
  });

  const openAddCargoModal = (
    addCargoData: AddCargoData,
    isEdit: boolean,
    modalType: ShippingType,
    cargoIndex?: number,
    containerIndex?: number
  ) => {
    const addModal = { ...addCargoModal };
    addModal.openAddCargoModal = modalType;
    addModal.initialAddCargoData = addCargoData;
    addModal.isEdit = isEdit;
    addModal.cargoIndex = cargoIndex ?? 0;
    addModal.containerIndex = containerIndex ?? 0;
    setAddCargoModal(addModal);
  };

  const closeAddCargoModal = () => {
    const addModal = { ...addCargoModal };
    addModal.openAddCargoModal = null;
    setAddCargoModal(addModal);
  };

  return (
    <div className={`${className}`}>
      <AddLCLCargoModal
        cargoIndex={addCargoModal.cargoIndex}
        closeIcon
        closeModal={closeAddCargoModal}
        closeOnOutsideClick={false}
        commodities={commodities}
        containerIndex={addCargoModal.containerIndex ?? null}
        containerTypes={containerTypes}
        data={addCargoModal.initialAddCargoData}
        hazardCodes={hazardCodes}
        isEdit={addCargoModal.isEdit}
        loadingCommodities={loadingCommodities}
        loadingContainerTypes={loadingContainerTypes}
        loadingHazardCodes={loadingHazardCodes}
        loadingPackageTypes={loadingPackageTypes}
        onChangeData={onChangeCargoData}
        onEditCargoData={onEditCargoData}
        open={addCargoModal.openAddCargoModal === ShippingType.LCL}
        shipmentType={freightMode}
        shippingType={getShippingType(shippingTypeValue)}
        unit={packageTypes}
      />
      <AddFCLCargoModal
        cargoIndex={addCargoModal.cargoIndex}
        closeIcon
        closeModal={closeAddCargoModal}
        closeOnOutsideClick={false}
        commodities={commodities}
        containerIndex={addCargoModal.containerIndex ?? null}
        containerTypes={containerTypes}
        data={addCargoModal.initialAddCargoData}
        hazardCodes={hazardCodes}
        isEdit={addCargoModal.isEdit}
        loadingCommodities={loadingCommodities}
        loadingContainerTypes={loadingContainerTypes}
        loadingHazardCodes={loadingHazardCodes}
        loadingPackageTypes={loadingPackageTypes}
        onChangeData={onChangeCargoData}
        onEditCargoData={onEditCargoData}
        open={addCargoModal.openAddCargoModal === ShippingType.FCL}
        shipmentType={freightMode}
        shippingType={getShippingType(shippingTypeValue)}
        unit={packageTypes}
      />
      <CargoSection
        canEdit
        canRemove
        data={data}
        freightMode={freightMode}
        onDeleteCargoData={onDeleteCargoData}
        openAddCargoModal={openAddCargoModal}
        shippingType={shippingTypeValue}
      />
      <Tooltip
        childClassName="inline-flex"
        content={i18n.t("INFO_ADD_CARGO_MODAL")}
        direction="topLeft"
      >
        <div className="flex flex-col mb-5 mt-2">
          <Button
            className="flex items-center border-none text-ocean-blue ml-7"
            disabled={
              freightMode === FreightMode.SH &&
              (shippingTypeValue === undefined || shippingTypeValue === null)
            }
            onClick={() =>
              openAddCargoModal(initialCargoData, false, getShippingType(shippingTypeValue))
            }
          >
            {!shippingTypeValue || shippingTypeValue === "LCL" ? (
              <>
                <FaPlus className="mr-2 mb-1" /> {t("LABEL_ADD_CARGO")}
              </>
            ) : (
              <>
                <FaPlus className="mr-2 mb-1" />
                {t("LABEL_ADD_CONTAINER_AND_CARGO")}
              </>
            )}
          </Button>
          {cargoLinesError != null && cargoLinesError != "" && (
            <div className="text-red-600 my-2 mx-2">
              <span> {t(cargoLinesError)}</span>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

CargoCell.displayName = "CargoCell";

export default CargoCell;
