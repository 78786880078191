import React, { useState } from "react";

import Link, { LinkProps } from "@components/Link/link";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface ToggleLinkProps extends LinkProps {
  open?: boolean;
  expandedText?: React.ReactNode;
  className?: string;
}

const ToggleLink = ({
  onClick,
  open: openProp,
  expandedText,
  children,
  className,
  ...rest
}: ToggleLinkProps) => {
  const isControlled = openProp !== undefined;
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((oldValue) => !oldValue);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isControlled) {
      toggle();
    }
    onClick && onClick(event);
  };

  const isOpen = isControlled ? openProp : open;

  const getIcon = () => {
    return isOpen ? faChevronUp : faChevronDown;
  };

  const getText = () => {
    return isOpen && expandedText ? expandedText : children;
  };

  return (
    <Link className={className} icon={getIcon()} onClick={handleOnClick} {...rest}>
      {getText()}
    </Link>
  );
};

export default ToggleLink;
