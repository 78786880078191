class StringUtilities {
  isHTML(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }

  validTimeRegex = /^([0-1]?[7-9]|1[0-9]):([0-5][0-9])(:[0-5][0-9])?$/;

  validContainerNumberRegex = /^[a-zA-Z]{4}\d{7}?$/;

  validEmails(value: string) {
    const emails = value.split(/[;\s]+/).filter((e) => e);
    for (const em of emails) {
      if (!this.validEmail(em)) return false;
    }
    return true;
  }

  validEmail(email: string) {
    return /^\S+@\S+\.\S+$/.test(email);
  }

  numberCommaDecimal(value: number, noOfDigits = 2) {
    return value.toLocaleString("de", {
      minimumFractionDigits: noOfDigits,
      maximumFractionDigits: noOfDigits,
    });
  }
}

export default new StringUtilities();
