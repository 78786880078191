import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st2}`}
        d="M46.07,25c0,11.63-9.43,21.07-21.07,21.07S3.93,36.63,3.93,25C3.93,13.37,13.37,3.93,25,3.93
                S46.07,13.37,46.07,25z"
      />
      <circle className={`${styles.st2}`} cx="25" cy="25" r="16.34" />
      <polyline className={`${styles.st0}`} points="25.07,12.64 25.07,24.98 31.92,31.92 	" />
    </g>
  </svg>
);

export default SVG;
