import React, { PureComponent } from "react";

import { BookingStatus, FreightMode } from "@assets/constants";
import { TableInfo, BookingSearchResult } from "@assets/types";
import Card from "@components/Card/card";
import DropdownSearchResults from "@components/Dropdown/dropdownResults";
import Input from "@components/Input/input";
import Link from "@components/Link/link";
import Spinner from "@components/Spinner/spinner";
import Table from "@components/Table/table";
import Tooltip from "@components/Tooltip/tooltip";
import BookingService from "@services/BookingService";
import { AppState } from "@state/reducers/types";
import { Translation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import { FiInfo, FiRefreshCcw, FiSearch } from "react-icons/fi";
import { connect } from "react-redux";

type TableCardProps = {
  title: string;
  tableTitle?: string;
  subTitle: React.ReactNode;
  subTitleLink: string;
  subTitleClick: boolean;
  loadingTable: boolean;
  hasAccess: boolean;
  imageClass: string;
  className?: string;
  hasSearch?: boolean;
  searchPlaceholder?: string;
  tableInfo: TableInfo;
  freightMode?: string;
  tooltip?: React.ReactNode;
  noResultsMessage?: string;
  dateStamp?: Date;
  createBookingLink?: string;
};

const MIN_SEARCH_VALUE_LENGTH = 3;

class TableCard extends PureComponent<
  TableCardProps,
  {
    options: BookingSearchResult[];
    back: boolean;
    searchValue: string;
    isSearching: boolean;
    noResults: boolean;
  }
> {
  state = {
    options: [],
    back: false,
    searchValue: "",
    isSearching: false,
    noResults: false,
  };

  onSearchChange = (value: string) => {
    this.setState({ searchValue: value });
  };

  handleSearch = async (value: string, freightMode: string) => {
    try {
      this.setState({ isSearching: true });
      await BookingService.searchBookingsSimple(value, freightMode).then(
        (res: BookingSearchResult[]) => {
          this.setState({
            options: res,
            noResults: res.length < 1,
          });
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isSearching: false });
    }
  };

  handleKeypress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && this.state.searchValue.length >= MIN_SEARCH_VALUE_LENGTH) {
      this.handleSearch(this.state.searchValue, this.props.freightMode || "");
    }
  };

  render() {
    const {
      title,
      tableTitle,
      subTitle,
      subTitleClick,
      subTitleLink,
      loadingTable,
      hasAccess,
      className,
      imageClass,
      hasSearch,
      tableInfo,
      tooltip,
      dateStamp,
      createBookingLink,
      freightMode,
    } = this.props;

    const { options, searchValue, noResults } = this.state;
    return (
      <Translation>
        {hasAccess
          ? (t) => {
              return (
                <Card className={`h-280 m-4 ${className} `}>
                  <div
                    className={`shadow-lg content overflow-visible ${
                      this.state.back ? "showBack" : ""
                    }`}
                  >
                    <div
                      className={`front flex flex-col justify-between bg-center bg-cover ${imageClass}`}
                    >
                      <div className="flex justify-between py-2 px-4 text-lg leading-none text-white">
                        <div>
                          {createBookingLink != undefined && (
                            <a
                              className="flex justify-center text-xl mt-1 text-white font-semibold"
                              href={
                                freightMode === FreightMode.AI
                                  ? "/shipments/createairfreightbooking"
                                  : "/shipments/createseafreightbooking"
                              }
                            >
                              {t("LABEL_CREATE_BOOKING")}
                            </a>
                          )}
                        </div>
                        <div className="flex">
                          {tooltip ? (
                            <Tooltip childClassName="inline-flex" content={tooltip}>
                              <FiInfo className="cursor-help" />
                            </Tooltip>
                          ) : null}

                          <FiRefreshCcw
                            className="ml-3 cursor-pointer"
                            onClick={() => this.setState({ back: true })}
                          />
                        </div>
                      </div>

                      <div className="cardTitle absolute w-full text-center">
                        <h2 className="text-white text-center text-4xl leading-none font-semibold">
                          <svg
                            className="inline-block pr-2 pb-1 h-8 w-auto"
                            height="136.089"
                            viewBox="0 0 136.089 136.089"
                            width="136.089"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                              fill="#f66013"
                              transform="translate(-3.582 -532.516) rotate(45)"
                            />
                          </svg>
                          {title}
                        </h2>
                        {subTitleLink !== "" ? (
                          <a href={subTitleLink}>
                            <div
                              className={`cardQuickLink text-xl leading-5 text-white ${
                                subTitle !== "" ? "visible" : "invisible"
                              }`}
                            >
                              {subTitle}
                            </div>
                          </a>
                        ) : subTitleClick ? (
                          <div
                            className={`flex justify-center cardQuickLink flex-col align-center text-xl text-white cursor-pointer ${
                              subTitle !== "" ? "visible" : "invisible"
                            }`}
                          >
                            <button onClick={() => this.setState({ back: true })} type="button">
                              {subTitle}
                            </button>
                          </div>
                        ) : (
                          <div
                            className={`cardQuickLink text-xl leading-5 text-white ${
                              subTitle !== "" ? "visible" : "invisible"
                            }`}
                          >
                            {subTitle}
                          </div>
                        )}
                      </div>
                      {hasSearch && (
                        <div className="searchBar flex py-3 px-4 items-center bg-jonar-orange">
                          <label className="flex justify-content min-w-130 font-bold">
                            {t("LABEL_FIND_SHIPMENT")}
                            <svg
                              className="inline-block h-4 w-auto"
                              height="136.089"
                              viewBox="0 0 136.089 136.089"
                              width="136.089"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="jonarArrow"
                                d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                                fill="#f66013"
                                transform="translate(-3.582 -532.516) rotate(45)"
                              />
                            </svg>
                          </label>
                          <div className="flex flex-col w-full">
                            <div className="flex w-full">
                              <Input
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none px-4 py-1 leading-none"
                                name="company_website"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  this.onSearchChange(e.target.value)
                                }
                                onKeyPress={this.handleKeypress}
                                placeholder={this.props.searchPlaceholder}
                                type="text"
                                value={searchValue}
                              />
                              <span className="inline-flex items-center px-3 py-1 border-0 border-jonar-orange border-l-2 bg-white text-jonar-orange text-sm">
                                {this.state.isSearching ? (
                                  <FaSpinner className="fa-spin relative -top-2px" />
                                ) : (
                                  <FiSearch
                                    className="relative -top-2px cursor-pointer"
                                    onClick={() => {
                                      this.state.searchValue.length >= MIN_SEARCH_VALUE_LENGTH
                                        ? this.handleSearch(
                                            this.state.searchValue,
                                            this.props.freightMode || ""
                                          )
                                        : null;
                                    }}
                                  />
                                )}
                              </span>
                            </div>
                            {(options && options.length > 0) || noResults ? (
                              <div className="relative overflow-visible w-full h-auto">
                                <div className="absolute flex flex-col w-full">
                                  <DropdownSearchResults
                                    headers={[
                                      t("TEXT_NAME"),
                                      `${t("TEXT_RECORD", {
                                        count: options.length,
                                      })}: ${options.length}`,
                                    ]}
                                    onOutsideClick={() =>
                                      this.setState({
                                        searchValue: "",
                                        options: [],
                                        noResults: false,
                                      })
                                    }
                                  >
                                    {noResults ? (
                                      <button
                                        className="flex justify-between bg-white text-gray-700 font-normal p-3 hover:bg-gray-100 cursor-pointer w-full border-t"
                                        type="button"
                                      >
                                        <span>{this.props.noResultsMessage}</span>
                                      </button>
                                    ) : (
                                      options.map((item: BookingSearchResult, i: number) => {
                                        return (
                                          <button
                                            className="flex justify-between bg-white text-gray-700 font-normal p-3 hover:bg-gray-100 cursor-pointer w-full border-t"
                                            key={i}
                                            onClick={() =>
                                              window.open(
                                                `/singleBooking/${item.FreightMode}/${item.JobReference}`,
                                                "_blank",
                                                "noreferrer"
                                              )
                                            }
                                            type="button"
                                          >
                                            <Link className="text-left" target="_blank">
                                              {item.JobReference}
                                            </Link>
                                            <span>
                                              {t("BOOKINGSTATUS_" + BookingStatus[item.Status])}
                                            </span>
                                          </button>
                                        );
                                      })
                                    )}
                                  </DropdownSearchResults>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="back bg-white">
                      <div className="flex justify-end py-2 bg-jonar-blue px-4 text-lg leading-none text-white border-b-solid">
                        <svg
                          className="inline-block absolute left-5 h-4 w-auto"
                          height="136.089"
                          viewBox="0 0 136.089 136.089"
                          width="136.089"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                            fill="#f66013"
                            transform="translate(-3.582 -532.516) rotate(45)"
                          />
                        </svg>
                        <h3 className="w-auto pl-1 pt-1 absolute top-5px left-25px">
                          {tableTitle}
                        </h3>
                        {tooltip ? (
                          <Tooltip childClassName="inline-flex" content={tooltip}>
                            <FiInfo className="cursor-help" />
                          </Tooltip>
                        ) : dateStamp ? (
                          `${dateStamp.getUTCDate()}.${
                            dateStamp.getUTCMonth() + 1
                          }.${dateStamp.getUTCFullYear()}`
                        ) : null}

                        <FiRefreshCcw
                          className="ml-3 cursor-pointer hover:rotate-45"
                          onClick={() => this.setState({ back: false })}
                        />
                      </div>
                      {this.state.back ? (
                        <div className="overflow-y-auto float-left w-full pt-2 overflow-x-hidden h-table absolute flex flex-col">
                          {loadingTable ? (
                            <Spinner className="bg-white" />
                          ) : (
                            <Table
                              className="w-full"
                              noDataMessage={this.props.noResultsMessage}
                              tableInfo={tableInfo}
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Card>
              );
            }
          : (t) => {
              return (
                <Card className={`h-280 m-4 ${className} `}>
                  <div className={`shadow-lg content ${this.state.back ? "showBack" : ""}`}>
                    <div
                      className={`front flex flex-col justify-between bg-center bg-cover ${
                        imageClass + "-dark"
                      }`}
                    >
                      <div className="cardTitle absolute w-full text-center">
                        <h2 className="text-white text-center text-4xl leading-none font-semibold">
                          <svg
                            className="inline-block pr-2 pb-1 h-8 w-auto"
                            height="136.089"
                            viewBox="0 0 136.089 136.089"
                            width="136.089"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M459.271,325.9l0,0v0l-32.075,32.076h64.154v64.153l32.075-32.076h0V325.9Z"
                              fill="#f66013"
                              transform="translate(-3.582 -532.516) rotate(45)"
                            />
                          </svg>
                          {t(title)}
                        </h2>
                        <div
                          className={`cardQuickLink text-xl leading-5 text-white ${
                            subTitle !== "" ? "visible" : "invisible"
                          }`}
                        >
                          {t("TEXT_YOU_DO_NOT_HAVE_ACCESS")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            }}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
  }),
  {}
)(TableCard);
