import React, { ReactNode, useEffect, useState } from "react";

import {
  listToSelectOptionMapper,
  singleValueToSelectOptionMapper,
} from "@assets/utilities/labelMapperUtils";
import IconButtonUpdated from "@components/Button/iconButtonUpdated";
import InputEx, { InputExProps } from "@components/Input/inputEx";
import SelectEx from "@components/Select/selectEx";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getYear, getMonth, getDay } from "date-fns";
import { range } from "lodash";
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const defaultYears = range(getYear(new Date()), getYear(new Date()) + 20, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const renderHeader = (
  {
    changeMonth,
    changeYear,
    date,
    decreaseMonth,
    increaseMonth,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps,
  years: number[]
) => {
  return (
    <div className="mt-3 mx-7 flex flex-row justify-between items-baseline">
      <div className="flex flex-row gap-2">
        <SelectEx
          menuFitLongestOption
          onChange={(selectedMonth) => {
            selectedMonth ? changeMonth(months.indexOf(selectedMonth.value)) : null;
          }}
          options={listToSelectOptionMapper(months)}
          value={singleValueToSelectOptionMapper(months[getMonth(date)])}
        />

        <SelectEx
          menuFitLongestOption
          onChange={(selectedYear) => {
            selectedYear ? changeYear(selectedYear.value) : null;
          }}
          options={listToSelectOptionMapper(years)}
          value={singleValueToSelectOptionMapper(getYear(date))}
        />
      </div>
      <div className="flex flex-row gap-2">
        <IconButtonUpdated
          color="primary"
          disabled={prevMonthButtonDisabled}
          icon={faChevronLeft}
          onClick={decreaseMonth}
        />
        <IconButtonUpdated
          color="primary"
          disabled={nextMonthButtonDisabled}
          icon={faChevronRight}
          onClick={increaseMonth}
        />
      </div>
    </div>
  );
};

interface DatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> extends Omit<InputExProps, "onChange" | "value"> {
  /** Change the format of the displayed date. */
  dateFormat?: string | string[] | undefined;
  /** Disable the weekends. */
  disableWeekends?: boolean;
  /** The last date of the date range. */
  endDate?: Date | null;
  /** The interval of dates to exclude. */
  excludeDateIntervals?: { start: Date; end: Date }[];
  /** The dates to exclude. */
  excludeDates?: Date[];
  /** The max date that can be selected. */
  maxDate?: Date;
  /** The min date that can be selected. */
  minDate?: Date;
  /**
   * Callback fired when the value is changed.
   * @param date The selected Date/Dates
   * @param event The change event
   */
  onChange?: (
    date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  /** Placeholder for the date picker value. */
  placeholder?: string;
  /** The array of the selectable years. */
  selectableYears?: number[];
  /** If true, a dat range can be selected */
  selectsRange?: WithRange;
  /** The first date of the date range. */
  startDate?: Date | null;
  /** If true show the today button with "Today" text. */
  showTodayButton?: boolean;
  /** The displayed custom element. */
  todayButton?: ReactNode | string;
  /** The selected date. */
  value?: Date | null | undefined;
}

const DatePicker = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>({
  dateFormat = "dd MMM yyyy",
  disableWeekends,
  disabled,
  endDate,
  excludeDateIntervals,
  excludeDates,
  id,
  maxDate,
  minDate,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  placeholder = "Select a date...",
  required,
  selectableYears,
  selectsRange,
  showTodayButton = false,
  startDate,
  todayButton = "Today",
  value,
  ...inputProps
}: DatePickerProps<CustomModifierNames, WithRange>) => {
  const [years, setYears] = useState(defaultYears);
  useEffect(() => {
    if (selectableYears) {
      setYears(selectableYears);
    }
  }, []);

  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <ReactDatePicker
      customInput={<InputEx {...inputProps} />}
      dateFormat={dateFormat}
      disabled={disabled}
      endDate={endDate}
      excludeDateIntervals={excludeDateIntervals}
      excludeDates={excludeDates}
      filterDate={disableWeekends ? isWeekday : undefined}
      id={id}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      placeholderText={placeholder}
      renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => renderHeader(props, years)}
      required={required}
      selected={value}
      selectsRange={selectsRange}
      showPopperArrow={false}
      startDate={startDate}
      todayButton={showTodayButton && todayButton}
    />
  );
};

export default DatePicker;
