import { ActiveVoyage } from "@assets/types";
import axios from "axios";

const JonarAPI = process.env.JonarAPI;

class VoyageService {
  getCurrentVoyages = async (): Promise<ActiveVoyage[]> => {
    return await axios
      .post(
        `${JonarAPI}/voyages/current/info`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        return res.data.ReturnItem.Data;
      });
  };
}

export default new VoyageService();
