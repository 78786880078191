import { Commodities } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getCommodities = createAsyncThunk("registry/commodities", async () => {
  return await CreateBookingService.getCommodities();
});

export const getCommodityByCode = createAsyncThunk(
  "registry/commodity/code",
  async (code: string) => await CreateBookingService.getCommodityByCode(code)
);

export interface CommoditiesState {
  commodities: Commodities[];
  loadingCommodities: boolean;
  commodityByCode: Commodities | null;
}

export const initialCommoditiesState: CommoditiesState = {
  commodities: [],
  loadingCommodities: false,
  commodityByCode: null,
};

export const commoditiesSlice = createSlice({
  name: "commodities",
  initialState: initialCommoditiesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommodities.pending, (state) => {
        state.loadingCommodities = true;
      })
      .addCase(getCommodities.rejected, (state) => {
        state.loadingCommodities = false;
        state.commodities = [];
      })
      .addCase(getCommodities.fulfilled, (state, action: PayloadAction<Commodities[]>) => {
        state.loadingCommodities = false;
        state.commodities = action.payload;
      })
      .addCase(getCommodityByCode.rejected, (state) => {
        state.commodityByCode = null;
      })
      .addCase(getCommodityByCode.fulfilled, (state, action: PayloadAction<Commodities>) => {
        state.commodityByCode = action.payload;
      });
  },
});

export const commoditiesState = (state: RootState) => state.commoditiesSlice;

export default commoditiesSlice.reducer;
