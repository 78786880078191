import {
  UserProfileViewModel,
  Toast,
  Currency,
  ActiveRequest,
  ActiveBooking,
  ActiveVoyage,
  Booking,
  DropdownOption,
} from "@assets/types";

export interface AppState {
  UserReducer: UserState;
  ToastReducer: ToastState;
  ViewReducer: ViewState;
  HomeReducer: HomeState;
  BookingReducer: BookingState;
  LoaderReducer: LoaderState;
}

export interface UserState {
  profileLoaded: boolean;
  userProfile?: UserProfileViewModel;
  changingCompany: boolean;
  iframeUpdater: number;
  loggedInOld: boolean;
  loginInProgress: boolean;
  logoutInProgress: boolean;
}

export interface ToastState {
  toasts: Toast[];
}

export interface ViewState {
  modalOpen: boolean;
  modalType?: ModalType;
}

export enum ModalType {
  COMPANY_MODAL = "COMPANY_MODAL",
  USER_MODAL = "USER_MODAL",
  PASSWORD_MODAL = "PASSWORD_MODAL",
}

export interface HomeState {
  activeRequests: ActiveRequest[];
  loadingRequests: boolean;
  activeSeaFreightBookings: ActiveBooking[];
  loadingSeaFreightBookings: boolean;
  activeAirFreightBookings: ActiveBooking[];
  loadingAirFreightBookings: boolean;
  latestCurrencies: Currency[];
  loadingCurrencies: boolean;
  activeVoyages: ActiveVoyage[];
  loadingVoyages: boolean;
}

export interface BookingState {
  bookingsAll: Booking[];
  bookings: Booking[];
  favoriteBookings: string[];
  loadingBookings: boolean;
  togglingFavoriteBooking: boolean;
  isFetchingExcelBookings: boolean;
  loadingBookingsInit: boolean;
  shippingTypeOptions: DropdownOption[];
  displayedShippingTypeOptions: DropdownOption[];
  displayedShipperOptions: DropdownOption[];
  shipperOptions: DropdownOption[];
  consigneeOptions: DropdownOption[];
  displayedConsigneeOptions: DropdownOption[];
  polOptions: DropdownOption[];
  displayedPolOptions: DropdownOption[];
  podOptions: DropdownOption[];
  displayedPodOptions: DropdownOption[];
}

export interface LoaderState {
  favoriteBookingIsLoading: number;
}
