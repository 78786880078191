import React, { useState } from "react";

import { NoteType, WebNoteType } from "@assets/constants";
import { useAppDispatch } from "@assets/hooks";
import { ErrorTypes, ModalProps } from "@assets/types";
import Button from "@components/Button/button";
import Textarea from "@components/Textarea/textarea";
import { createWebNote } from "@state/slices/notesSlice";
import { useTranslation } from "react-i18next";

import Modal from "./modal";

interface WebNoteModalProps extends ModalProps {
  jobReference: string;
  type: number;
  isNote: boolean;
}

const WebNoteModal: React.FC<WebNoteModalProps> = (props: WebNoteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { open, closeModal, jobReference, type, isNote } = props;

  const [note, setNote] = useState<string | null>(null);
  const [noteError, setNoteError] = useState<string | null>(null);

  const onClose = () => {
    setNote(null);
    setNoteError(null);
    closeModal();
  };

  const onSubmit = async () => {
    if (note === null || note === "") {
      setNoteError(ErrorTypes.RequiredField);
    } else {
      dispatch(
        createWebNote({
          jobReference: jobReference,
          note: note,
          isInternalNote: type === NoteType.INTERNAL,
        })
      );
      closeModal();
      setNote(null);
      setNoteError(null);
    }
  };

  const createHeader = (): string => {
    let header = "";
    if (isNote) {
      if (props.type === NoteType.PUBLIC) {
        header = t("LABEL_ADD_PUBLIC_NOTE");
      } else header = t("LABEL_ADD_INTERNAL_NOTE");
    }
    if (!isNote) {
      if (type == WebNoteType.INTERNAL) {
        header = jobReference + " - " + t("LABEL_INTERNAL_MEMO");
      } else header = jobReference + " - " + t("LABEL_MEMO");
    }
    return header;
  };

  const formatNote = (note: string) => {
    const cleanNote = note.replace(/<\/?[^>]+(>|$)/g, "");
    setNote(cleanNote);
  };

  return (
    <Modal className="left-2 right-2" onClose={() => onClose()} open={open} size="small">
      <Modal.Header>{createHeader()}</Modal.Header>
      <Modal.Content className="bg-white grid overflow-x-auto">
        <Textarea
          className="h-full"
          error={noteError ?? ""}
          hasError={note === null || note === ""}
          onChange={(e) => formatNote(e.target.value)}
          value={note ?? ""}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          className="ml-2"
          onClick={() => {
            onClose();
          }}
          type="button"
        >
          {t("LABEL_CANCEL")}
        </Button>
        <Button className="mr-6 md:mr-2" onClick={() => onSubmit()} primary type="submit">
          {t("LABEL_ADD_NOTE")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WebNoteModal;
