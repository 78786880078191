/* eslint-disable react/no-unused-prop-types */
import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
  href: string;
};

const Tab: React.FC<Props> = ({ children }) => {
  return (
    <div id="tabs-tabContent">
      <div
        aria-labelledby="tabs-home-tab"
        className="tab-pane fade show active"
        id="tabs-home"
        role="tabpanel"
      >
        {children}
      </div>
    </div>
  );
};

export default Tab;
