import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M25,18.5v16 M12,38.5c0,0-3.22-9.88-4-13c0,0,10.73-5.87,17-7c6.27,1.13,17,7,17,7c-0.78,3.12-4,13-4,13
                M28,11.5v-5h-6v5 M36,22.5v-7h-4v-4H18v4h-4v7 M46,43.5l-3.43-3.47c-0.77-0.78-1.77-0.69-2.48,0.23l-1.28,1.66
                c-0.72,0.93-1.75,1.01-2.52,0.19l-1.92-2.05c-0.75-0.8-1.75-0.75-2.47,0.13l-1.46,1.79c-0.73,0.9-1.76,0.93-2.51,0.08l-1.75-1.96
                c-0.74-0.83-1.74-0.81-2.47,0.03l-1.64,1.9c-0.75,0.86-1.77,0.85-2.51-0.02l-1.57-1.86c-0.73-0.86-1.72-0.88-2.47-0.07l-1.82,1.99
                c-0.76,0.84-1.79,0.78-2.51-0.12l-1.39-1.74c-0.71-0.89-1.71-0.96-2.47-0.17L4,43.5"
      />
    </g>
  </svg>
);

export default SVG;
