import React from "react";

import Step from "./step";

const styles = {
  root: {
    width: "100%",
    minHeight: 0,
    padding: 0,
  },
  stepper: {
    display: "table",
    width: "100%",
    margin: "0 auto",
  },
};

type StepItem = {
  title: string;
  icon?: string;
  href?: string;
  onClick: () => void;
};

type Props = {
  activeStep?: number;
  steps: StepItem[];
  activeColor?: string;
  completeColor?: string;
  defaultColor?: string;
  activeTitleColor?: string;
  completeTitleColor?: string;
  defaultTitleColor?: string;
  circleFontColor?: string;
  size?: number;
  circleFontSize?: number;
  titleFontSize?: number;
  circleTop?: number;
  defaultOpacity?: string;
  completeOpacity?: string;
  activeOpacity?: string;
  defaultTitleOpacity?: string;
  completeTitleOpacity?: string;
  activeTitleOpacity?: string;
  defaultBarColor?: string;
  completeBarColor?: string;
  defaultBorderColor?: string;
  completeBorderColor?: string;
  activeBorderColor?: string;
  defaultBorderStyle?: string;
  completeBorderStyle?: string;
  activeBorderStyle?: string;
  lineMarginOffset?: number;
  defaultBorderWidth?: number;
  disabledSteps?: number[];
};

const Stepper: React.FC<Props> = ({
  activeStep,
  steps,
  disabledSteps,
  activeColor,
  completeColor,
  defaultColor,
  circleFontColor,
  activeTitleColor,
  completeTitleColor,
  defaultTitleColor,
  size,
  circleFontSize,
  titleFontSize,
  circleTop,
  completeOpacity,
  activeOpacity,
  defaultOpacity,
  completeTitleOpacity,
  activeTitleOpacity,
  defaultTitleOpacity,
  defaultBorderColor,
  completeBorderColor,
  activeBorderColor,
  defaultBorderStyle,
  completeBorderStyle,
  activeBorderStyle,
  defaultBarColor,
  completeBarColor,
  lineMarginOffset,
  defaultBorderWidth,
}: Props) => {
  const defaultProps = {
    activeStep: 0,
  };

  return (
    <div style={styles.root}>
      <div style={styles.stepper}>
        {steps.map((step, index) => (
          <Step
            active={
              !(disabledSteps || []).includes(index) &&
              index === (activeStep != null ? activeStep : defaultProps.activeStep)
            }
            activeBorderColor={activeBorderColor}
            activeBorderStyle={activeBorderStyle}
            activeColor={activeColor}
            activeOpacity={activeOpacity}
            activeTitleColor={activeTitleColor}
            activeTitleOpacity={activeTitleOpacity}
            circleFontColor={circleFontColor}
            circleFontSize={circleFontSize}
            circleTop={circleTop}
            completeBarColor={completeBarColor}
            completeBorderColor={completeBorderColor}
            completeBorderStyle={completeBorderStyle}
            completeColor={completeColor}
            completeOpacity={completeOpacity}
            completeTitleColor={completeTitleColor}
            completeTitleOpacity={completeTitleOpacity}
            completed={
              !(disabledSteps || []).includes(index) &&
              index < (activeStep != null ? activeStep : defaultProps.activeStep)
            }
            defaultBarColor={defaultBarColor}
            defaultBorderColor={defaultBorderColor}
            defaultBorderStyle={defaultBorderStyle}
            defaultBorderWidth={defaultBorderWidth}
            defaultColor={defaultColor}
            defaultOpacity={defaultOpacity}
            defaultTitleColor={defaultTitleColor}
            defaultTitleOpacity={defaultTitleOpacity}
            first={index === 0}
            href={step.href}
            index={index}
            isLast={index === steps.length - 1}
            key={index}
            lineMarginOffset={lineMarginOffset}
            onClick={() => step.onClick()}
            size={size}
            title={step.title}
            titleFontSize={titleFontSize}
            width={100 / steps.length}
          />
        ))}
      </div>
    </div>
  );
};

export default Stepper;
