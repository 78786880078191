export const openFile = (uri: string) => {
  const link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
};

export const downloadFile = async (fileName: string, url: string) => {
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  const blob = await response.blob();
  const a = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = a;
  link.target = "_blank";
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(a);
};
