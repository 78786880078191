class ArrayUtilities {
  /**
   *
   * @param array
   * @param value
   * @returns {T}
   */
  toggleValueInArray = <T>(array: T[], value: T[] | T): T[] => {
    if (Array.isArray(value)) {
      if (value.some((el) => array.includes(el))) {
        return array.filter((el) => !value.includes(el));
      }
      return [...array, ...value];
    } else if (array.includes(value)) {
      return array.filter((el) => el !== value);
    }
    return [...array, value];
  };

  addValueToArray = <T>(array: T[], value: T): T[] => {
    array.push(value);
    return array;
  };

  removeValueFromArray = <T>(array: T[], value: T): T[] => {
    return array.filter((item: T) => item != value);
  };

  /**
   * Searches for the first object in a list based on a value of a property.
   * @param list The list of objects
   * @param value The value based on we can identify the searched object
   * @param propertyName The property that contains the value
   * @returns {T | undefined} If found in the list returns the full object
   */
  getObject = <T>(list: T[], value: string | number, propertyName: string): T | undefined => {
    return list.find((element) => element[propertyName] === value);
  };
}

export default new ArrayUtilities();
