import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M20.35,44.17l1.58-3.66l-2.36-1.02c0.08-0.74,0.06-1.47-0.06-2.18l2.48-0.99l-1.47-3.7l-2.6,1.03
                c-0.41-0.53-0.9-1.01-1.44-1.43l1.12-2.61l-3.66-1.58l-1.12,2.61c-0.68-0.11-1.36-0.13-2.03-0.07l-1.03-2.6l-3.7,1.47l0.99,2.48
                c-0.6,0.41-1.15,0.89-1.63,1.45l-2.36-1.02l-1.58,3.66l2.19,0.94c-0.17,0.8-0.2,1.61-0.13,2.4l-2.12,0.84l1.47,3.7l2-0.8
                c0.47,0.7,1.06,1.33,1.75,1.86L5.8,46.92l3.66,1.58l0.84-1.94c0.86,0.14,1.72,0.13,2.55,0l0.8,2l3.7-1.47l-0.84-2.12
                c0.63-0.49,1.19-1.07,1.66-1.74L20.35,44.17z"
      />
      <circle className={`${styles.st0}`} cx="11.7" cy="38.26" r="3.57" />
      <path
        className={`${styles.st0}`}
        d="M48.62,20.7v-6.27h-4.04c-0.34-1.11-0.83-2.16-1.45-3.12l2.97-2.97l-4.44-4.44l-3.11,3.11
                c-0.93-0.51-1.93-0.9-2.98-1.16V1.38H29.3v4.47c-1.05,0.27-2.05,0.65-2.98,1.16l-3.11-3.11l-4.44,4.44l2.97,2.97
                c-0.61,0.96-1.11,2-1.45,3.12h-4.04v6.27H20c0.26,1.26,0.71,2.45,1.31,3.55l-2.54,2.54l4.44,4.44l2.4-2.4
                c1.12,0.72,2.36,1.26,3.69,1.6v3.33h6.27v-3.33c1.33-0.34,2.57-0.88,3.69-1.6l2.4,2.4l4.44-4.44l-2.54-2.54
                c0.6-1.09,1.05-2.29,1.31-3.55H48.62z"
      />
      <path
        className={`${styles.st0}`}
        d="M38.05,17.57c0,3.1-2.51,5.61-5.61,5.61c-3.1,0-5.62-2.51-5.62-5.61c0-3.1,2.51-5.61,5.62-5.61
                C35.53,11.95,38.05,14.47,38.05,17.57z"
      />
    </g>
  </svg>
);

export default SVG;
