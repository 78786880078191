import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M24.45,30.94c2.16,0,3.91,1.74,3.91,3.89c0,2.15-1.75,3.89-3.91,3.89s-3.91-1.74-3.91-3.89
            C20.55,32.68,22.29,30.94,24.45,30.94z M41.21,30.94c2.16,0,3.91,1.74,3.91,3.89c0,2.15-1.75,3.89-3.91,3.89
            c-2.16,0-3.9-1.74-3.9-3.89C37.3,32.68,39.05,30.94,41.21,30.94z"
      />
      <path
        className={`${styles.st0}`}
        d="M26.33,27.63l7.1-14.6h6.91c1.43,0,2.15,0.85,2.15,1.95v10.61"
      />
      <path
        className={`${styles.st0}`}
        d="M1.46,37.48h14.46V23.02H1.46V37.48z M10.73,27.1H6.65v-4.07h4.07V27.1z M22.44,31.49h-3.29V11.41h-3.23v17.51
            v8.56h-8.6 M20.55,34.82h-4.63 M45.11,36.59h3.43v-9.06c0-1.1-0.72-1.95-2.15-1.95h-9.54c-1.07,0-1.95,0.87-1.95,1.95v3.01h-5.13
            v-0.97c0-1.1-0.72-1.95-2.15-1.95H21.1c-1.08,0-1.95,0.87-1.95,1.95 M28,36.59h9.54"
      />
    </g>
  </svg>
);

export default SVG;
