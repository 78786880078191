/**
 * Opens the given url in a new window. NOTE: Forcing the browser to open a new window should be avoided.
 * But we need this because of the old implementation and need to keep consistency...
 *  (See: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#avoid_resorting_to_window.open)
 * @param url The url to open.
 * @param name The name of the window.
 * @param width The width of the window.
 * @param height The height of the window.
 */
export const openPopup = (url: string, name?: string, width?: number, height?: number): void => {
  const popupWidth = width || 1020;
  const popupHeight = height || 750;
  const popupName = name || "Popup";
  const opts = `width=${popupWidth},height=${popupHeight},top=0,toolbar=0,menubar=0,scrollbars=1,rel=noopener`;
  window.open(url, popupName, opts);
};

/**
 *Opens the given url in a new tab/window. Depends on the browser settings.
 * @param url The url to open.
 */
export const openTabOrWindow = (url: string): void => {
  window.open(url, "_blank");
};

/**
 * Navigates to the given url, staying on the same page.
 * @param url The url to navigate to.
 */
export const navigatePage = (url: string): void => {
  window.open(url, "_self");
};
