import React, { FocusEventHandler } from "react";

import { classNames } from "@assets/utilities/classNameUtilities";
import LabelEx, { labelCases } from "@components/Label/labelEx";
import { debounce } from "lodash";
import { ActionMeta, GroupBase, OnChangeValue, OptionsOrGroups, ThemeConfig } from "react-select";
import { AsyncProps, default as AsyncReactSelect } from "react-select/async";

import { styles } from "./styles";

interface SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AsyncProps<Option, IsMulti, Group> {
  /** Focus the control when it is mounted */
  autoFocus?: boolean;
  /** Override or extend the style applied to the outside wrapper. */
  className?: string;
  /** Milliseconds to delay the loadOptions functions/ */
  debounceTime?: number;
  /** Opens the menu open by default. */
  defaultMenuIsOpen?: boolean;
  /**
   * The default set of options to show before the user starts searching. When
   * set to `true`, the results for loadOptions('') will be auto-loaded.
   */
  defaultOptions?: Option[];
  /** The default value of the dropdown. */
  defaultValue?: Option | null | Option[];
  /** If true, the component is disabled. */
  disabled?: boolean;
  /** The error message to show. !NOTE: use with hasError=true. */
  errorText?: string;
  /**
   * Resolves option data to a string to be displayed as the label by components
   * (the option doesn't need to have the {value:, label:} form with this)
   *
   * Note: Failure to resolve to a string type can interfere with filtering and
   * screen reader support.
   */
  getOptionLabel?: (value: Option) => string;
  /** Resolves option data to a string to compare options and specify value attributes
   *  (the option doesn't need to have the {value:, label:} form with this)
   */
  getOptionValue?: (value: Option) => string;
  /** If true, the component is displayed with a error style. */
  hasError?: boolean;
  /** The id of the select element.  */
  id?: string;
  /** If true, the select is clearable. */
  isClearable?: boolean;
  /** Support multiple selected options. */
  isMulti?: IsMulti;
  /** A text to be used in the label element. */
  label?: string;
  /**
   * Function that returns a promise, which is the set of options to be used
   * once the promise resolves.
   */
  loadOptions?: (inputValue: string) => Promise<OptionsOrGroups<Option, Group>>;
  /** If true, the menu is open. */
  menuIsOpen?: boolean;
  /** Name attribute of the select element. */
  name?: string;
  /** Callback fired when focus has left the element */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /**
   * Callback fired when the value is changed.
   * @param value The selected SelectOption/SelectOptions
   * @param actionMeta The description of what caused the value change
   */
  onChange?: (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void;
  /** If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /** Placeholder for the select value. */
  placeholder?: string;
  /** If true, the menu appears in front of the elements instead of being cut off at the end of container. */
  portaling?: boolean;
  /**	If true, the input element shows as required. */
  required?: boolean;
  /** The value of the select; reflected by the selected option */
  value?: Option | Option[] | null;
  isLoading?: boolean;
  /** To make sure the dropdown menu's length is the of the longest option. */
  menuFitLongestOption?: boolean;
  /** Transform the label displayed case. */
  labelCase?: labelCases;
  tooltipContent?: string;
}

const theme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "rgb(59, 130, 246, 0.5)", // selected option background
    neutral5: "#e5e7eb", // disabled background
    neutral10: "#D1D5DB", //disabled border
    neutral40: "#374151", //disabled text
  },
});

const AsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  autoFocus = false,
  className,
  debounceTime = 400,
  defaultMenuIsOpen = false,
  defaultOptions,
  defaultValue,
  disabled = false,
  errorText,
  getOptionLabel,
  getOptionValue,
  hasError = false,
  id,
  isClearable = false,
  isMulti,
  label,
  loadOptions,
  menuIsOpen,
  name,
  onBlur,
  onChange,
  optional = false,
  optionalText,
  placeholder,
  portaling = false,
  required = false,
  value,
  isLoading = false,
  menuFitLongestOption = false,
  labelCase = "none",
  tooltipContent,
}: SelectProps<Option, IsMulti, Group>) => {
  const debounceSearchFunction = debounce((inputValue, callback) => {
    if (loadOptions) {
      loadOptions(inputValue).then((options) => callback(options));
    }
  }, debounceTime);

  return (
    <div className={classNames(`flex flex-col gap-2 items-baseline`, className)}>
      {label && (
        <LabelEx
          className="font-semibold text-neutral-800 text-3.25"
          forID={id}
          labelCase={labelCase}
          optional={optional}
          optionalText={optionalText}
          required={required}
          tooltipContent={tooltipContent}
        >
          {label}
        </LabelEx>
      )}
      <AsyncReactSelect
        autoFocus={autoFocus}
        classNamePrefix="samskip-select"
        defaultMenuIsOpen={defaultMenuIsOpen}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isClearable={isClearable}
        isDisabled={disabled || isLoading}
        isLoading={isLoading}
        isMulti={isMulti}
        loadOptions={debounceSearchFunction}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={portaling ? document.body : undefined}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        styles={{
          ...styles<Option, IsMulti, Group>(hasError, disabled, menuFitLongestOption, "normal"),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        theme={theme}
        value={value}
      />
      {hasError && <p className="text-red-500">{errorText}</p>}
    </div>
  );
};

export default AsyncSelect;
