import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M10.34,39.66c1.41,1.41,7.67-3.43,10.61-6.37l19.09-19.09c2.78-2.78,5.55-7.18,4.24-8.49s-5.7,1.46-8.48,4.24
                L16.7,29.06C13.76,31.99,8.93,38.25,10.34,39.66z M34.38,20.11l4.95,23.58H36.5l-5.66-10.15l-4.95-4.95 M29.89,15.62L6.31,10.67
                v2.83l10.15,5.66l4.95,4.95 M19.53,34.71l1.41,8.49l-2.83,1.41l-2.83-5.66 M15.29,30.47L6.8,29.06l-1.41,2.83l5.66,2.83"
      />
    </g>
  </svg>
);

export default SVG;
