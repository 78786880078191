import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1965_5404)">
      <path
        d="M1.569.047C.98.175.44.619.18 1.184 0 1.575 0 1.578 0 4.225v2.431h2.653l.01 2.506.009 2.51.075.219c.115.353.253.578.503.83.256.26.51.416.86.532l.218.075 2.51.01 2.506.009V16H16v-2.431c0-2.725.003-2.672-.222-3.132-.178-.356-.531-.715-.872-.88-.431-.213-.462-.216-2.322-.207-1.621.01-1.668.013-1.865.078-.588.2-1.094.706-1.288 1.29-.056.17-.068.298-.081.741L9.338 12H6.963c-1.791 0-2.41-.01-2.507-.037a.746.746 0 01-.419-.42C4.01 11.448 4 10.829 4 9.035V6.656h2.659l-.009-2.54-.01-2.538-.084-.228C6.32.697 5.737.184 5.078.047c-.306-.066-3.212-.063-3.51 0zm3.468 1.406c.072.053.17.16.213.234l.078.141v3.5l-1.99.01-1.994.006v-1.75c0-1.922-.007-1.866.19-2.06.194-.193.147-.19 1.832-.18l1.534.005.137.094zm9.276 9.297c.074.044.18.14.234.213l.094.137.009 1.778.006 1.778h-4v-1.675c0-1.178.01-1.715.038-1.819.047-.184.272-.418.45-.468.078-.022.634-.032 1.575-.028l1.453.006.14.078z"
        fill="#696969"
      />
    </g>
    <defs>
      <clipPath className={`${styles.st0}`} id="clip0_1965_5404">
        <path d="M0 0H16V16H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
