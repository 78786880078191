import { ContainerTemperatures } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getContainerTemperatures = createAsyncThunk("container/temperatures", async () => {
  return await CreateBookingService.getContainerTemperatures();
});

export interface ContainerTemperaturesState {
  containerTemperatures: ContainerTemperatures[];
  loadingContainerTemperatures: boolean;
}

export const initialContainerTemperaturesState: ContainerTemperaturesState = {
  containerTemperatures: [],
  loadingContainerTemperatures: false,
};

export const containerTemperatureSlice = createSlice({
  name: "containerTemperatures",
  initialState: initialContainerTemperaturesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContainerTemperatures.pending, (state) => {
        state.loadingContainerTemperatures = true;
      })
      .addCase(getContainerTemperatures.rejected, (state) => {
        state.loadingContainerTemperatures = false;
        state.containerTemperatures = [];
      })
      .addCase(
        getContainerTemperatures.fulfilled,
        (state, action: PayloadAction<ContainerTemperatures[]>) => {
          state.loadingContainerTemperatures = false;
          state.containerTemperatures = action.payload;
        }
      );
  },
});

export const containerTemperaturesState = (state: RootState) => state.containerTemperatureSlice;

export default containerTemperatureSlice.reducer;
