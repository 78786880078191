import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <polygon
        className={`${styles.st0}`}
        points="48.38,45.12 48.38,36.57 1.62,36.57 1.62,45.12 9.02,45.12 9.02,40.38 21.3,40.38 21.3,45.12 
          28.7,45.12 28.7,40.38 40.98,40.38 40.98,45.12"
      />
      <rect className={`${styles.st0}`} height="15.82" width="20.18" x="14.91" y="4.71" />
      <rect className={`${styles.st0}`} height="3.38" width="4.93" x="22.54" y="4.71" />
      <line className={`${styles.st0}`} x1="18.99" x2="22.67" y1="16.62" y2="16.62" />
      <rect className={`${styles.st0}`} height="15.82" width="20.18" x="4.78" y="20.64" />
      <rect className={`${styles.st0}`} height="3.38" width="4.93" x="12.4" y="20.64" />
      <line className={`${styles.st0}`} x1="8.85" x2="12.54" y1="32.55" y2="32.55" />
      <polyline
        className={`${styles.st0}`}
        points="24.99,36.45 45.22,36.45 45.22,20.64 24.99,20.64"
      />
      <rect className={`${styles.st0}`} height="3.38" width="4.93" x="32.67" y="20.64" />
      <line className={`${styles.st0}`} x1="29.12" x2="32.8" y1="32.55" y2="32.55" />
    </g>
  </svg>
);

export default SVG;
