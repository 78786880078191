import React, { Component } from "react";

import { DropdownOption } from "@assets/types";
import { navigate } from "gatsby";

type DropdownProps = {
  className?: string;
  options: DropdownOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: any;
  onSelect: Function;
};

class Dropdown extends Component<DropdownProps, { open: boolean }> {
  state = { open: false };

  render() {
    const { className, options, trigger } = this.props;
    const { open } = this.state;
    return (
      <div
        className="text-center"
        onMouseDown={() => this.setState({ open: true })}
        onMouseEnter={() => this.setState({ open: true })}
        onMouseLeave={() => this.setState({ open: false })}
      >
        {trigger}
        <div
          className={`relative block left-auto md:absolute right-auto md:-right-12 md:mt-3 rounded-md md:shadow-lg h-0 md:h-auto w-full md:w-auto transition-fade ease-in-out duration-fade-300 overflow-visible z-10 ${
            open
              ? "h-auto opacity-100 visible max-h-600px"
              : "h-0 md:h-auto opacity-10 md:delay-fade-300 invisible max-h-0 overflow-hidden"
          } ${className}`}
        >
          <svg
            className="hidden md:block fill-current text-white w-4 h-4 absolute right-0 top-0 mr-3 -mt-3 z-0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
          </svg>
          <div className="rounded-md md:bg-white md:shadow-xs flex flex-no-wrap">
            <div
              aria-labelledby="options-menu"
              aria-orientation="vertical"
              className="py-1 w-full"
              role="menu"
            >
              {options.map((opt, i) => {
                return (
                  <a
                    className="block px-4 pb-2 md:py-2 text-sm font-normal leading-5 text-white md:text-gray-700 whitespace-nowrap hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    href="#"
                    key={i}
                    onClick={() => {
                      navigate(opt.value);
                      this.props.onSelect ? this.props.onSelect() : null;
                    }}
                    role="menuitem"
                  >
                    {opt.item}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
