import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M12.09,17.31c-1.35,2.25-2.13,4.88-2.13,7.69c0,8.31,6.73,15.04,15.04,15.04c2.81,0,5.44-0.79,7.69-2.13
                L12.09,17.31z M37.91,32.69c1.35-2.25,2.13-4.87,2.13-7.69c0-8.31-6.73-15.04-15.04-15.04c-2.81,0-5.44,0.79-7.69,2.13L37.91,32.69
                z M47.42,25c0,12.38-10.04,22.42-22.42,22.42S2.58,37.38,2.58,25C2.58,12.62,12.62,2.58,25,2.58S47.42,12.62,47.42,25z"
      />
    </g>
  </svg>
);

export default SVG;
