import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path className={`${styles.st0}`} d="M16.68,32.59l15.17,7.26l15.17-7.26V17.41l-15.17-7.26" />
      <line className={`${styles.st0}`} x1="36.44" x2="43.78" y1="33.58" y2="30.19" />
      <line className={`${styles.st0}`} x1="31.85" x2="31.85" y1="24.67" y2="39.84" />
      <path
        className={`${styles.st0}`}
        d="M42.65,24.01v3.95 M41.25,26.31l1.4-2.3l1.4,0.95 M37.54,26.37v3.95 M36.14,28.67l1.4-2.3l1.4,0.95"
      />
      <path className={`${styles.st0}`} d="M26.92,22.31l4.93,2.36l15.17-7.26" />
      <polygon
        className={`${styles.st0}`}
        points="11.24,23.68 5.11,17.54 1,21.65 11.24,31.89 29.23,13.91 25.13,9.8 	"
      />
    </g>
  </svg>
);

export default SVG;
