import { PortType } from "@assets/constants";
import { Port, PortFilters } from "@assets/types";
import { combineReducers, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getPorts = createAsyncThunk("ports/getPorts", async (portType: PortType) => {
  return await CreateBookingService.getPorts(portType);
});

export const searchPorts = createAsyncThunk(
  "ports/searchPorts",
  async (portFilters: PortFilters) => await CreateBookingService.searchPorts(portFilters)
);

export interface PortsState {
  ports: Port[];
}

export const initialPortsState: PortsState = {
  ports: [],
};

export const portsSlice = createSlice({
  name: "ports",
  initialState: initialPortsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPorts.rejected, (state) => {
        state.ports = [];
      })
      .addCase(getPorts.fulfilled, (state, action: PayloadAction<Port[]>) => {
        state.ports = action.payload;
      })
      .addCase(searchPorts.rejected, (state) => {
        state.ports = [];
      })
      .addCase(searchPorts.fulfilled, (state, action: PayloadAction<Port[]>) => {
        state.ports = action.payload;
      });
  },
});

export const portsState = (state: RootState) => state.portsSlice.portsSlice.ports;

export default combineReducers({
  portsSlice: portsSlice.reducer,
});
