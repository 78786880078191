import React from "react";

import { Label } from "@assets/types";
import CustomIcon from "@components/CustomIcon/customIcon";
import Input from "@components/Input/input";
import LabelComponent from "@components/Label/label";
import { Translation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";

type SearchProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: Function;
  isSearching?: boolean;
  labelClassName?: string;
  value: string;
  placeHolder?: string;
  onSearch?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  labels?: { labels?: Label[]; labelClick: Function };
  className: string;
  icon?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const Search: React.FC<SearchProps> = ({
  isSearching,
  value,
  onChange,
  onKeyPress,
  placeHolder,
  onSearch,
  labels,
  className,
  icon,
  labelClassName,
  onBlur,
}) => (
  <Translation>
    {() => (
      <div
        className={`bg-white flex justify-center border border-solid border-base rounded pr-2 ${className}`}
      >
        {labels && labels.labels
          ? labels.labels.map((label: Label) => (
              <LabelComponent
                className={labelClassName}
                key={label.key}
                label={label}
                onClick={() => labels.labelClick(label.key)}
              />
            ))
          : null}
        <Input
          className="text-normal-text-color bg-white p-0"
          disabled={isSearching}
          name="search"
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeHolder}
          type="text"
          value={value}
        />
        {icon ? (
          isSearching ? (
            <FaSpinner className="fa-spin z-100 self-center text-normal-text-color" />
          ) : (
            <CustomIcon
              className={`self-center ${onSearch ? "cursor-pointer" : ""}`}
              iconName={icon}
              onClick={onSearch}
            />
          )
        ) : null}
      </div>
    )}
  </Translation>
);

export default Search;
