import { Alert, JonarAPIResponse } from "@assets/types";
import axios, { AxiosResponse } from "axios";

const JonarAPI = process.env.JonarAPI;

class AlertsService {
  getAlertRoute = async (req: { Route: string; Company: number }): Promise<Alert> => {
    return axios
      .post(`${JonarAPI}/alerts/getAlertRoute`, req, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Alert>>) => {
        return res.data.ReturnItem.Data;
      });
  };
}

export default new AlertsService();
