import React from "react";

import { UserProfileViewModel, MenuItem, DropdownOption } from "@assets/types";
import Dropdown from "@components/Dropdown/dropdown";
import Link from "@components/Link/link";
import NavService from "@services/NavService";
import { AppState } from "@state/reducers/types";
import { navigate } from "gatsby";
import { TFunction } from "i18next";
import { Translation } from "react-i18next";
import { FaCog } from "react-icons/fa";
import { connect } from "react-redux";

class Navigation extends React.PureComponent<
  { userProfile: UserProfileViewModel; className: string; close: Function },
  { menuItems: MenuItem[] }
> {
  state = {
    menuItems: [] as MenuItem[],
  };

  async componentDidMount() {
    const { Menu } = this.props.userProfile;
    const menu = await NavService.setupMenu(Menu);
    this.setState({ menuItems: menu });
  }

  onMenu(item: MenuItem, t: TFunction) {
    if (item.children && item.children.length > 1) {
      const dropdownOptions: DropdownOption[] | undefined =
        item.children &&
        item.children.map((opt) => {
          return {
            value: opt.Site,
            item: t(opt.Translate),
          };
        });

      return dropdownOptions.length > 0 ? (
        <li
          className="relative ml-2 md:ml-6 pb-1 md:pb-0 w-full md:w-auto text-center"
          key={item.MenuID}
        >
          <Dropdown
            onSelect={this.props.close}
            options={dropdownOptions}
            trigger={
              item.MenuName === "Settings" ? (
                <FaCog className="cursor-pointer mt-1 md:mt-0 inline-block" />
              ) : (
                <span className="text-white px-1 font-bold uppercase cursor-pointer">
                  {t(item.Translate)}
                </span>
              )
            }
          />
        </li>
      ) : null;
    } else {
      return (
        <li className="ml-2 md:ml-6 pb-1 md:pb-0 w-full md:w-auto text-center" key={item.MenuID}>
          <Link
            className="text-white px-1 font-bold uppercase"
            color="neutral-0"
            href="#"
            onClick={() => {
              navigate(item.Site);
              this.props.close();
            }}
          >
            {t(item.Translate)}
          </Link>
        </li>
      );
    }
  }

  render() {
    const { className } = this.props;
    const { menuItems } = this.state;
    return (
      <Translation>
        {(t) => (
          <ul
            className={`flex font-bold uppercase text-white text-base justify-start md:justify-end items-center w-full md:pb-3 md:pt-3 px-1 ${className}`}
          >
            {menuItems[0] && menuItems[0].children?.map((item: MenuItem) => this.onMenu(item, t))}
          </ul>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
  }),
  {}
)(Navigation);
