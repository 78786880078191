import { PackageTypes } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

export const getPackageTypes = createAsyncThunk("registry/packagetypes", async () => {
  return await CreateBookingService.getPackageTypes();
});

export const getPackageTypeByCode = createAsyncThunk(
  "registry/packagetype/code",
  async (code: string) => await CreateBookingService.getPackageTypeByCode(code)
);

export interface PackageTypesState {
  packageTypes: PackageTypes[];
  loadingPackageTypes: boolean;
  packageTypesByCode: PackageTypes | null;
}

export const initialPackageTypesState: PackageTypesState = {
  packageTypes: [],
  loadingPackageTypes: false,
  packageTypesByCode: null,
};

export const packageTypesSlice = createSlice({
  name: "packageTypes",
  initialState: initialPackageTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPackageTypes.pending, (state) => {
        state.loadingPackageTypes = true;
      })
      .addCase(getPackageTypes.rejected, (state) => {
        state.loadingPackageTypes = false;
        state.packageTypes = [];
      })
      .addCase(getPackageTypes.fulfilled, (state, action: PayloadAction<PackageTypes[]>) => {
        state.loadingPackageTypes = false;
        state.packageTypes = action.payload;
      })
      .addCase(getPackageTypeByCode.rejected, (state) => {
        state.packageTypesByCode = null;
      })
      .addCase(getPackageTypeByCode.fulfilled, (state, action: PayloadAction<PackageTypes>) => {
        state.packageTypesByCode = action.payload;
      });
  },
});

export const packageTypesState = (state: RootState) => state.packageTypesSlice;

export default packageTypesSlice.reducer;
