import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M10,17.41l23-11 M25,2.41l-23,11v23l23,11l23-11v-23L25,2.41z"
      />
      <line className={`${styles.st0}`} x1="31" x2="44" y1="38.41" y2="32.41" />
      <line className={`${styles.st0}`} x1="25" x2="25" y1="24.41" y2="47.41" />
      <path
        className={`${styles.st0}`}
        d="M31.51,30.49L34,26.41l2.49,1.69 M34,26.41v7 M39.51,26.49L42,22.41l2.49,1.69 M42,22.41v7"
      />
      <path
        className={`${styles.st0}`}
        d="M2,13.41l8.18,3.91 M16.31,20.26L25,24.41l23-11 M10,17.41v6l6,3v-6l23-11"
      />
    </g>
  </svg>
);

export default SVG;
