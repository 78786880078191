import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    fill="none"
    height="18"
    viewBox="0 0 21 18"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1975_156)">
      <path
        d="M19.597 0h-3.698c-.61 0-1.11.515-1.11 1.147v3.824c0 .632.5 1.147 1.11 1.147h3.698c.61 0 1.109-.515 1.109-1.147V1.147c0-.632-.498-1.147-1.11-1.147zm-.37 4.588h-2.958V1.53h2.958v3.06zM12.202 0H8.504c-.61 0-1.109.515-1.109 1.147v3.824c0 .632.498 1.147 1.11 1.147h3.697c.61 0 1.109-.515 1.109-1.147V1.147C13.31.515 12.813 0 12.2 0zm-.37 4.588H8.874V1.53h2.958v3.06zM4.807 0H1.109C.5 0 0 .515 0 1.147v3.824c0 .632.498 1.147 1.11 1.147h3.697c.61 0 1.109-.515 1.109-1.147V1.147C5.916.515 5.418 0 4.806 0zm-.37 4.588H1.479V1.53h2.958v3.06zm15.16 4.588h-3.698c-.61 0-1.11.515-1.11 1.147v3.824c0 .632.5 1.147 1.11 1.147h3.698c.61 0 1.109-.515 1.109-1.147v-3.824c0-.632-.498-1.147-1.11-1.147zm-.37 4.589h-2.958v-3.06h2.958v3.06zm-7.025-4.589H8.504c-.61 0-1.109.515-1.109 1.147v3.824c0 .632.498 1.147 1.11 1.147h3.697c.61 0 1.109-.515 1.109-1.147v-3.824c0-.632-.498-1.147-1.11-1.147zm-.37 4.589H8.874v-3.06h2.958v3.06zM4.807 9.176H1.109C.5 9.176 0 9.691 0 10.323v3.824c0 .632.498 1.147 1.11 1.147h3.697c.61 0 1.109-.515 1.109-1.147v-3.824c0-.632-.498-1.147-1.11-1.147zm-.37 4.589H1.479v-3.06h2.958v3.06z"
        fill="#696969"
      />
    </g>
    <defs>
      <clipPath className={`${styles.st0}`} id="clip0_1975_156">
        <path d="M0 0H20.706V16H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
