/* eslint-disable react/display-name */
import React, { FocusEventHandler, useEffect, useState } from "react";

import { classNames } from "@assets/helperFunctions";
import LabelEx, { labelCases } from "@components/Label/labelEx";

export interface InputProps {
  /** Whether or not the input field should have autocomplete enabled. */
  autoComplete?: boolean;
  /** Extend the style applied to the outer container. */
  className?: string;
  /** If true the component is disabled.*/
  disabled?: boolean;
  /** The error message to show. !NOTE: use with hasError=true. */
  error?: string;
  /** If true, the component takes uo the full width of it's container. */
  fullWidth?: boolean;
  /** If true, the component is displayed with a error style. */
  hasError?: boolean;
  /** The id of the input element.  */
  id?: string;
  /** A text to be used in the label element. */
  labelValue?: string;
  /** The placement of the label related to the input. */
  labelPosition?: "top" | "bottom" | "left";
  /** The maximum number of characters allowed in the input. */
  maxLength?: number;
  /** The minimum number of characters allowed in the input. */
  minLength?: number;
  /** Name attribute of the input element. */
  name?: string;
  /** Callback fired when focus has left the element */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** Callback fired when the value is changed. */
  onChange?: (time: string) => void;
  /** Callback fired when a key is pressed. */
  onKeyPress?: Function;
  /** If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /**  Short hint that describes the expected value of the input. */
  placeholder?: string;
  /**	If true, the input element shows as required. */
  required?: boolean;
  /** The type of the input element to display. */
  type?: "username" | "password" | "search" | "text" | "number" | "tel" | "email" | "date" | "time";
  /** Controlled value of the input. */
  value?: string;
  /** The style variant of the input. */
  variant?: "outline" | "rounded" | "line";
  tooltipContent?: string;
  unit?: string;
  /** The size of the input. */
  size?: "large" | "medium" | "small";
  /** Transform the label displayed case. */
  labelCase?: labelCases;
}

const fontSizes = {
  large: "text-base",
  medium: "text-sm",
  small: "text-xs",
};

const heights = {
  large: "h-12",
  medium: "h-10",
  small: "h-8",
};

export const TimeInput: React.FC<InputProps> = React.forwardRef(
  (
    {
      autoComplete = false,
      disabled = false,
      hasError = false,
      id,
      labelValue,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onKeyPress,
      placeholder,
      type = "text",
      value,
      optional = false,
      optionalText,
      required = false,
      size = "medium",
      labelCase = "none",
    },
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const [hours, setHours] = useState<string | null>(null);
    const [minutes, setMinutes] = useState<string | null>(null);
    const secondRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (value) {
        const time = value.split(":");
        setHours(time[0]);
        setMinutes(time[1]);
      }
      if (value == "") {
        setHours(null);
        setMinutes(null);
      }
    }, [value]);

    useEffect(() => {
      if (hours && minutes && onChange) onChange(`${hours}:${minutes}`);
    }, [hours, minutes]);

    return (
      <div className="my-3">
        {labelValue && (
          <LabelEx
            className="font-semibold text-3.25"
            forID={id}
            labelCase={labelCase}
            optional={optional}
            optionalText={optionalText}
            required={required}
          >
            {labelValue}
          </LabelEx>
        )}
        <div className="flex">
          <input
            autoComplete={autoComplete ? "on" : "off"}
            className={classNames(
              `bg-neutral-0 appearance-none w-1/2 placeholder:text-neutral-600 text-neutral-900 
              rounded-md border
              hover:border-neutral-400
              focus:outline-none focus:ring-0 focus:ring-primary-500 focus:border-neutral-400`,
              hasError ? "border-error" : "",
              disabled ? "bg-neutral-200 border-neutral-400" : "",
              !hasError && !disabled ? "border-neutral-400" : "",
              heights[size],
              fontSizes[size]
            )}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={onBlur}
            onChange={(e) => {
              setHours(e.target.value);
            }}
            onKeyPress={onKeyPress ? (e) => onKeyPress(e) : undefined}
            onKeyUp={(e) => {
              const target = e.target as HTMLInputElement;
              if (target.value.length == 2) {
                secondRef.current?.focus();
              }
            }}
            placeholder={placeholder}
            ref={ref}
            type={type}
            value={hours ?? ""}
          />
          <span className="m-2">:</span>
          <input
            autoComplete={autoComplete ? "on" : "off"}
            className={classNames(
              `bg-neutral-0 appearance-none w-1/2 placeholder:text-neutral-700 text-neutral-900 
              rounded-md border
              disabled:bg-neutral-200 disabled:border-neutral-400 disabled:placeholder:text-neutral-600 disabled:text-neutral-600
              hover:border-neutral-600
              focus:outline-none focus:ring-0 focus:ring-primary-500 focus:border-primary-500`,
              hasError ? "border-error" : "border-neutral-500",
              heights[size],
              fontSizes[size]
            )}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={onBlur}
            onChange={(e) => {
              setMinutes(e.target.value);
            }}
            onKeyPress={onKeyPress ? (e) => onKeyPress(e) : undefined}
            placeholder={placeholder}
            ref={secondRef}
            type={type}
            value={minutes ?? ""}
          />
        </div>
      </div>
    );
  }
);

export default TimeInput;
