import { useEffect } from "react";

import { CallbackFunction } from "../types";

/**

 * A hook to handle click outside of an element.

 * @param elementRef The reference element's ref

 * @param onOutsideClick The action to call when other element was clicked.

 * @param isActive If false, the event listener won't be applied.

 */

export const useOutsideAlerter = (
  elementRef: React.RefObject<HTMLDivElement>,

  onOutsideClick: CallbackFunction,

  isActive = true
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (elementRef.current && !elementRef.current.contains(event.target as HTMLElement)) {
        onOutsideClick();
      }
    }

    if (isActive) {
      document.addEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [onOutsideClick, elementRef, isActive]);
};

export default useOutsideAlerter;
