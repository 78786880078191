import React, { useState } from "react";

import { FreightMode } from "@assets/constants";
import {
  AddCargoData,
  Incoterms,
  PickPartnerChangeRegistration,
  Port,
  Document,
  HSCodes,
  RequestCollectionRegistration,
  ErrorTypes,
  UserProfileViewModel,
} from "@assets/types";
import Button from "@components/Button/button";
import Checkbox from "@components/Input/checkbox";
import LabelEx from "@components/Label/labelEx";
import LayoutCell from "@components/LayoutCell/layoutCell";
import CargoSection from "@components/Section/cargoSection";
import { format } from "date-fns";
import parse from "html-react-parser";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import DateUtilities from "utilities/DateUtilities";

type ReviewPageProps = {
  userProfile: UserProfileViewModel | undefined | null;
  shipperData: PickPartnerChangeRegistration | null;
  consigneeData: PickPartnerChangeRegistration | null;
  notifierData: PickPartnerChangeRegistration | null;
  portOfLoad: Port | null;
  portOfDischarge: Port | null;
  fromData: RequestCollectionRegistration | null;
  toData: RequestCollectionRegistration | null;
  shippingType: string | null;
  incoterm: Incoterms | null;
  incotermLocation: Port | null | undefined;
  addCargoData: AddCargoData[][] | [];
  documents: Document[];
  hsCodes: HSCodes[];
  handleOnCreateBooking: () => void;
  loadingCreateBooking: boolean;
  setSelectedTab: (selectedTab: number) => void;
  quoteNumber: string | null;
  billOfLading?: string;
  canCollectShipment: boolean;
  canSetDeliverToPort: boolean;
  canSetPickUpPort: boolean;
  canDeliverShipment: boolean;
  freightMode: FreightMode;
};

const ReviewComponent: React.FC<ReviewPageProps> = (props: ReviewPageProps) => {
  const { t } = useTranslation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsHasError, setTermsHasError] = useState(false);

  const getTermsListFromTranslation = (translationString: string): string[] => {
    if (translationString) {
      return translationString.split("\\n").filter((term) => term !== "");
    }
    return [];
  };

  const submit = () => {
    if (termsAccepted || props.userProfile?.Access.UserType === "E") {
      setTermsHasError(false);
      props.handleOnCreateBooking();
    } else {
      setTermsHasError(true);
    }
  };

  return (
    <div className="m-8">
      <div className="my-4 border-b">
        <h2 className="text-jonar-blue text-lg leading-5">{t("LABEL_ROUTE")}</h2>
        <div className="flex flex-col md:flex-row md:justify-between">
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_shipper"
            labelValue={t("LABEL_SHIPPER")}
          >
            <div className="mb-2">
              <ul>
                {props.shipperData?.companyName && <li>{props.shipperData?.companyName}</li>}
                {props.shipperData?.address && <li>{props.shipperData?.address}</li>}
                {props.shipperData?.postalCode && <li>{props.shipperData?.postalCode}</li>}
                {props.shipperData?.placeCity && (
                  <li>
                    {props.shipperData?.placeCity.FullName},{props.shipperData?.placeCity.Country}
                  </li>
                )}
                {props.shipperData?.district && <li>{props.shipperData?.district}</li>}
                {props.shipperData?.contact && <li>{props.shipperData?.contact}</li>}
                {props.shipperData?.phone && <li>{props.shipperData?.phone}</li>}
                {props.shipperData?.email && <li>{props.shipperData?.email}</li>}
              </ul>
            </div>
          </LayoutCell>
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_consignee"
            labelValue={t("LABEL_CONSIGNEE")}
          >
            <div className="mb-2">
              <ul>
                {props.consigneeData?.companyName && <li>{props.consigneeData?.companyName}</li>}
                {props.consigneeData?.address && <li>{props.consigneeData?.address}</li>}
                {props.consigneeData?.postalCode && <li>{props.consigneeData?.postalCode}</li>}
                {props.consigneeData?.placeCity && (
                  <li>
                    {props.consigneeData?.placeCity.FullName},
                    {props.consigneeData?.placeCity.Country}
                  </li>
                )}
                {props.consigneeData?.district && <li>{props.consigneeData?.district}</li>}
                {props.consigneeData?.contact && <li>{props.consigneeData?.contact}</li>}
                {props.consigneeData?.phone && <li>{props.consigneeData?.phone}</li>}
                {props.consigneeData?.email && <li>{props.consigneeData?.email}</li>}
              </ul>
            </div>
          </LayoutCell>
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_notifier"
            labelValue={t("LABEL_NOTIFIER")}
          >
            <div className="mb-2">
              {props.notifierData != undefined && props.notifierData.companyName != "" ? (
                <ul>
                  {props.notifierData?.companyName && <li>{props.notifierData?.companyName}</li>}
                  {props.notifierData?.address && <li>{props.notifierData?.address}</li>}
                  {props.notifierData?.postalCode && <li>{props.notifierData?.postalCode}</li>}
                  {props.notifierData?.placeCity && (
                    <li>
                      {props.notifierData?.placeCity.FullName},
                      {props.notifierData?.placeCity.Country}
                    </li>
                  )}
                  {props.notifierData?.district && <li>{props.notifierData?.district}</li>}
                  {props.notifierData?.contact && <li>{props.notifierData?.contact}</li>}
                  {props.notifierData?.phone && <li>{props.notifierData?.phone}</li>}
                  {props.notifierData?.email && <li>{props.notifierData?.email}</li>}
                </ul>
              ) : (
                <span> {t("TEXT_NOT_PROVIDED")} </span>
              )}
            </div>
          </LayoutCell>
        </div>
        <div className="flex flex-col md:flex-row  justify-between">
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_from"
            labelValue={t("LABEL_FROM")}
          >
            <div className="mb-2">
              {props.canSetDeliverToPort &&
                props.portOfLoad?.FullName +
                  "," +
                  " " +
                  props.portOfLoad?.Country +
                  " " +
                  "(" +
                  props.portOfLoad?.PointCode +
                  ")"}
              {props.canCollectShipment && (
                <ul>
                  {props.fromData?.companyName && <li>{props.fromData?.companyName}</li>}
                  {props.fromData?.address && <li>{props.fromData?.address}</li>}
                  {props.fromData?.postalCode && <li>{props.fromData?.postalCode}</li>}
                  {props.fromData?.placeCity && (
                    <li>
                      {props.fromData?.placeCity.FullName},{props.fromData?.placeCity.Country}
                    </li>
                  )}
                  {props.fromData?.district && <li>{props.fromData?.district}</li>}
                  {props.fromData?.contact && <li>{props.fromData?.contact}</li>}
                  {props.fromData?.phone && <li>{props.fromData?.phone}</li>}
                  {props.fromData?.email && <li>({props.fromData?.email})</li>}

                  {props.fromData?.collectionDate && (
                    <li>
                      {t("Request collection time: ")}
                      {format(
                        DateUtilities.dateToUTC_WithoutTimezoneChange(
                          props.fromData?.collectionDate
                        ),
                        "dd/MM/yyyy"
                      )}
                    </li>
                  )}
                  <li className="flex">
                    {props.fromData?.from && <p>{props.fromData?.from}</p>}
                    {props.fromData?.from && props.fromData?.to && <p>-</p>}
                    {props.fromData?.to && <p>{props.fromData?.to}</p>}
                  </li>
                </ul>
              )}
            </div>
          </LayoutCell>
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_to"
            labelValue={t("LABEL_TO")}
          >
            <div className="mb-2">
              {props.canSetPickUpPort &&
                props.portOfDischarge?.FullName +
                  "," +
                  " " +
                  props.portOfDischarge?.Country +
                  " " +
                  "(" +
                  props.portOfDischarge?.PointCode +
                  ")"}
              {props.canDeliverShipment && (
                <ul>
                  {props.toData?.companyName && <li>{props.toData?.companyName}</li>}
                  {props.toData?.address && <li>{props.toData?.address}</li>}
                  {props.toData?.postalCode && <li>{props.toData?.postalCode}</li>}
                  {props.toData?.placeCity && (
                    <li>
                      {props.toData?.placeCity.FullName},{props.toData?.placeCity.Country}
                    </li>
                  )}
                  {props.toData?.district && <li>{props.toData?.district}</li>}
                  {props.toData?.contact && <li>{props.toData?.contact}</li>}
                  {props.toData?.phone && <li>{props.toData?.phone}</li>}
                  {props.toData?.email && <li>({props.toData?.email})</li>}

                  {props.toData?.collectionDate && (
                    <li>
                      {t("Request collection time: ")}
                      {props.toData?.collectionDate.getDate() +
                        "/" +
                        props.toData?.collectionDate.getMonth() +
                        "/" +
                        props.toData?.collectionDate.getFullYear() +
                        " "}
                    </li>
                  )}
                  <li className="flex">
                    {props.toData?.from && <p>{props.toData?.from}</p>}
                    {props.toData?.from && props.toData?.to && <p>-</p>}
                    {props.toData?.to && <p>{props.toData?.to}</p>}
                  </li>
                </ul>
              )}
            </div>
          </LayoutCell>
          <div className="mx-6 my-6 md:w-1/3 w-12/12">
            <LayoutCell
              canDelete={false}
              canEdit={false}
              className="my-6 min-h-40 md:w-1/3 w-12/12"
              id="review_incoterms"
              labelValue={t("LABEL_INCOTERMS")}
            >
              <div className="mb-2">{props.incoterm?.Description}</div>
            </LayoutCell>
            <LayoutCell
              canDelete={false}
              canEdit={false}
              className="my-6 md:w-1/3 w-12/12"
              id="review_incoterms_location"
              labelValue={t("LABEL_INCOTERMS_LOCATION")}
            >
              <div className="mb-2">{props.incotermLocation?.FullName}</div>
            </LayoutCell>
          </div>
        </div>
        <div className="flex flex-col md:flex-row  justify-between">
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_quote_number"
            labelValue={t("LABEL_QUOTE_NUMBER")}
          >
            <div className="mb-2">
              {props.quoteNumber != null ? props.quoteNumber : t("TEXT_NOT_PROVIDED")}
            </div>
          </LayoutCell>
          {props.freightMode === FreightMode.SH && (
            <LayoutCell
              canDelete={false}
              canEdit={false}
              className="my-6 md:w-1/3 w-12/12"
              id="review_bol"
              labelValue={t("LABEL_BILL_OF_LADING")}
            >
              <div className="mb-2">{t(props.billOfLading ?? "TEXT_NOT_PROVIDED")}</div>
            </LayoutCell>
          )}
          <div className="my-6 md:w-1/3 w-12/12" />
        </div>
      </div>
      <div className="my-4">
        <h2 className="text-jonar-blue text-lg leading-5">{t("LABEL_CARGO")}</h2>
        <div className="flex justify-between">
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 md:w-1/3 w-12/12"
            id="review_shipping_type"
            labelValue={t("LABEL_SHIPPING_TYPE")}
          >
            <div className="mb-2">
              {props.shippingType != undefined ? props.shippingType : t("TEXT_NOT_PROVIDED")}
            </div>
          </LayoutCell>
          <div className="my-6 md:w-1/3 w-12/12" />
          <div className="my-6 md:w-1/3 w-12/12" />
        </div>
        <CargoSection
          data={props.addCargoData}
          freightMode={props.freightMode}
          shippingType={props.shippingType}
        />
        <div className="flex justify-between">
          <LayoutCell
            canDelete={false}
            canEdit={false}
            className="my-6 w-12/12"
            id="review_hscodes"
            labelValue={t("LABEL_HSCODES")}
          >
            <div className="mb-2">
              {props.hsCodes != undefined && props.hsCodes.length > 0
                ? props.hsCodes?.map((x) => x.Description).join("; ")
                : t("TEXT_NOT_PROVIDED")}
            </div>
          </LayoutCell>
        </div>
      </div>
      <div className="my-4">
        <h2 className="text-jonar-blue text-lg leading-5">{t("LABEL_DOCUMENTS")}</h2>
        {props.documents != undefined && props.documents?.length > 0 ? (
          <ul className="bg-white rounded-lg w-full text-gray-900 flex-1">
            <li>
              <div className="grid grid-cols-2">
                <span>{t("LABEL_FILE_NAME")}</span>
                <span>{t("LABEL_DOCUMENT_TYPE")}</span>
              </div>
            </li>
            {props.documents.map((item, index) => (
              <li className="px-6 py-2 border-b border-t border-gray-200 w-full" key={index}>
                <div className="grid grid-cols-2">
                  <span className="mt-2">{item.document.name}</span>
                  <span className="mt-2">{i18n.t(`DOCTYPE_${item.doctype}`)}</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          t("TEXT_NO_DOCUMENTS_ADDED")
        )}
      </div>
      {props.userProfile?.Access.UserType !== "E" && (
        <div className="border-t border-gray-200">
          <LabelEx className="font-semibold text-3.25 m-2">
            {t("LABEL_TERMS_AND_CONDITIONS")}
          </LabelEx>
          <ul className="w-full px-6 mx-text-gray-900 flex-1 list-disc">
            {getTermsListFromTranslation(t("TEXT_BOOKING_TERMS_AND_CONDITIONS_V1")).map(
              (item, index) => (
                <li className="mt-2 list-disc" key={index}>
                  {parse(`${item}`)}
                </li>
              )
            )}
          </ul>
          <Checkbox
            checked={termsAccepted}
            className="flex flex-col pt-3"
            errorMessage={t(ErrorTypes.RequiredField)}
            hasError={termsHasError}
            id="terms"
            label={t("LABEL_USER_AGREEMENT_CHECK")}
            onChange={() => {
              setTermsAccepted(!termsAccepted);
            }}
            type="checkbox"
          />
        </div>
      )}
      <div className="m-6 flex justify-end">
        <Button
          className="mr-2 rounded"
          disabled={props.loadingCreateBooking}
          onClick={() => props.setSelectedTab(2)}
        >
          {t("LABEL_BACK")}
        </Button>
        <Button
          active={props.loadingCreateBooking}
          className="ml-2 rounded"
          disabled={props.loadingCreateBooking}
          onClick={submit}
          primary
        >
          {t("LABEL_SUBMIT_BOOKING")}
        </Button>
      </div>
    </div>
  );
};

export default ReviewComponent;
