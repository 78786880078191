import React, { Component } from "react";

import { FreightMode } from "@assets/constants";
import Button from "@components/Button/button";
import Page from "@components/Page/page";
import { navigate } from "gatsby";
import { Translation } from "react-i18next";

type CreateBookingComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  path?: string;
  shipmentType?: string;
  jobReference?: string;
};

class CreateBookingComponent extends Component<CreateBookingComponentProps, {}> {
  render() {
    const onCreateAnotherBooking = () => {
      this.props.shipmentType === FreightMode.SH
        ? navigate("/shipments/createseafreightbooking")
        : navigate("/shipments/createairfreightbooking");
    };

    return (
      <Translation>
        {(t) => (
          <Page className="md:m-6 w-full md:max-w-1024 self-center justify-center md:p-2">
            {this.props.jobReference != "failed" && this.props.jobReference != "importair" ? (
              <div className="flex flex-col justify-start h-40 my-8">
                <h3 className="text-lg font-medium ml-8 justify-start bold">
                  {`${t("LABEL_BOOKING")} ${this.props.jobReference} ${t(
                    "TEXT_BOOKING_SUBMITTED"
                  )}`}
                </h3>
                <h4 className="justify-start ml-8">{t("TEXT_BOOKING_SUBMITTED_EMAIL")}</h4>
                <div className="flex justify-start mt-6 ml-8">
                  <Button
                    className="my-3 mr-3 h-2/3 text-center"
                    onClick={() => {
                      navigate(
                        `/singleBooking/${this.props.shipmentType}/${this.props.jobReference}`
                      );
                    }}
                    type="button"
                  >
                    {t("LABEL_VIEW_BOOKING")}
                  </Button>
                  <Button
                    className="my-3 mx-3 h-2/3 text-center"
                    onClick={() => {
                      onCreateAnotherBooking();
                    }}
                    primary
                    type="button"
                  >
                    {this.props.shipmentType === FreightMode.SH
                      ? t("LABEL_CREATE_NEW_SEA_FREIGHT_BOOKING")
                      : t("LABEL_CREATE_NEW_AIR_FREIGHT_BOOKING")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-start h-40 my-8">
                <h3 className="text-center text-lg font-medium ml-12">
                  {t(
                    this.props.jobReference == "failed"
                      ? "LABEL_BOOKING_SENT_TO_STAFF_MEMBER"
                      : "LABEL_BOOKING_IMPORT_AIRFREIGHT_MESSAGE"
                  )}
                </h3>
              </div>
            )}
          </Page>
        )}
      </Translation>
    );
  }
}

export default CreateBookingComponent;
