import { HazardCodes } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

class HazardCodeReq {
  jobReference: string;
  sequence: number;
}

export const getHazardCodes = createAsyncThunk("registry/hazardcodes", async () => {
  return await CreateBookingService.getHazardCodes();
});

export const getHazardCodesByJobReference = createAsyncThunk(
  "registry/hazardcodes/jobReference",
  async (req: HazardCodeReq) =>
    await CreateBookingService.getHazardCodesByJobReference(req.jobReference, req.sequence)
);

export const searchHazardCodes = createAsyncThunk(
  "registry/hazardcodes/searchString",
  async (searchString: string) => {
    return await CreateBookingService.searchHazardCodes(searchString);
  }
);

export interface HazardCodesState {
  hazardCodes: HazardCodes[];
  loadingHazardCodes: boolean;
  hazardsByJobReference: HazardCodes[];
  searchedHazardCodes: HazardCodes[];
}

export const initialHazardCodesState: HazardCodesState = {
  hazardCodes: [],
  loadingHazardCodes: false,
  hazardsByJobReference: [],
  searchedHazardCodes: [],
};

export const hazardCodesSlice = createSlice({
  name: "hazardCodes",
  initialState: initialHazardCodesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHazardCodes.pending, (state) => {
        state.loadingHazardCodes = true;
      })
      .addCase(getHazardCodes.rejected, (state) => {
        state.loadingHazardCodes = false;
        state.hazardCodes = [];
      })
      .addCase(getHazardCodes.fulfilled, (state, action: PayloadAction<HazardCodes[]>) => {
        state.loadingHazardCodes = false;
        state.hazardCodes = action.payload;
      })
      .addCase(getHazardCodesByJobReference.rejected, (state) => {
        state.hazardsByJobReference = [];
      })
      .addCase(
        getHazardCodesByJobReference.fulfilled,
        (state, action: PayloadAction<HazardCodes[]>) => {
          state.hazardsByJobReference = action.payload;
        }
      )
      .addCase(searchHazardCodes.rejected, (state) => {
        state.searchedHazardCodes = [];
      })
      .addCase(searchHazardCodes.fulfilled, (state, action: PayloadAction<HazardCodes[]>) => {
        state.searchedHazardCodes = action.payload;
      });
  },
});

export const hazardCodesState = (state: RootState) => state.hazardCodesSlice;

export default hazardCodesSlice.reducer;
