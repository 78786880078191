import React from "react";

import { Control, Controller, FieldValues, Path } from "react-hook-form";

type WithOnChangeProps = { onChange?: (...event: never[]) => void };

interface WithFormControllerProps<T extends FieldValues = never> {
  /** The element that needs to be used with react-hooks-form */

  children: React.ReactElement<WithOnChangeProps>;

  /** The control object from react-hook-form */

  control: Control<T, never>;

  /** The unique name of the input */

  name: Path<T>;
}

/** A wrapper component to use with external components or where the ref can't be passed on. */
const WithFormController: React.FC<WithFormControllerProps> = ({ children, control, name }) => {
  if (React.isValidElement(children)) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const { onChange, ...rest } = field;
          return React.cloneElement(children, {
            onChange: (...event: never[]) => {
              onChange(...event);
              children.props.onChange && children.props.onChange(...event);
            },
            ...rest,
          });
        }}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default WithFormController;
