import React, { useEffect, useState } from "react";

import { UserType } from "@assets/constants";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { UserProfileViewModel, WebNote } from "@assets/types";
import DropdownButton from "@components/DropdownButton/dropdownButton";
import DeleteModal from "@components/Modal/deleteModal";
import LoadingModal from "@components/Modal/loadingModal";
import WebNoteModal from "@components/Modal/webNoteModal";
import Page from "@components/Page/page";
import { ActionTable } from "@samskip/frontend-components/";
import { ActionTableProps } from "@samskip/frontend-components/dist/components/Table/actionTable";
import BookingService from "@services/BookingService";
import { getRemarks, getWebNotes, noteState, resetAddNoteState } from "@state/slices/notesSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface NotesPageProps {
  jobReference: string;
  userProfile: UserProfileViewModel | undefined | null;
}

type AddNotesModal = {
  openAddNotes: boolean;
};

const NotesComponent: React.FC<NotesPageProps> = (props: NotesPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const notes = useAppSelector(noteState).webNotes;
  const addNoteStatus = useAppSelector(noteState).addNoteStatus;
  const loadingNotes = useAppSelector(noteState).loadingNotes;
  const remarks = useAppSelector(noteState).remarks;
  const loadingRemarks = useAppSelector(noteState).loadingRemarks;

  const [noteId, setNoteId] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  const openDeleteModal = (id: string) => {
    setNoteId(id);

    const openDeleteModal = {
      open: true,
    };
    setDeleteModal(openDeleteModal);
  };

  const closeDeleteModal = () => {
    const closeDeleteModal = {
      ...deleteModal,
      open: false,
    };
    setDeleteModal(closeDeleteModal);
  };

  const isEmployee = props.userProfile?.Access.UserType === UserType.EMPLOYEE;

  const values = isEmployee ? notes.concat(remarks) : remarks;

  const [addNotesModal, setAddNotesModal] = useState<AddNotesModal>({
    openAddNotes: false,
  });
  const [noteType, setNoteType] = useState<number>(1);

  useEffect(() => {
    dispatch(getWebNotes(props.jobReference));
    dispatch(getRemarks(props.jobReference));
  }, [window.location.href.split("#")[1] === "Notes"]);

  useEffect(() => {
    if (addNoteStatus === "OK") {
      toast.success(t("TEXT_NOTE_ADDED"));
      dispatch(getWebNotes(props.jobReference));
      dispatch(getRemarks(props.jobReference));
    } else if (addNoteStatus === "failed") {
      toast.error(t("ERROR_NOTE_NOT_ADDED"));
    }
    dispatch(resetAddNoteState());
  }, [addNoteStatus]);

  const handleDelete = (noteId: string) => {
    BookingService.deleteNote(props.jobReference, noteId).then(() => {
      toast.success(t("TEXT_NOTE_DELETED"));
      dispatch(getWebNotes(props.jobReference));
      dispatch(getRemarks(props.jobReference));
    });
  };

  const getNotesColumns = () => {
    const notesColumns: ActionTableProps<WebNote>["columns"] = [
      {
        dataIndex: "Note",
        id: "note",
        title: t("LABEL_NOTE"),
      },
      {
        dataIndex: "IsInternal",
        id: "isInternal",
        title: t("LABEL_TYPE"),
        render: (note: WebNote) => (note.IsInternal ? "Internal" : "Public"),
      },
      {
        dataIndex: "CreateDate",
        id: "createDate",
        title: t("LABEL_CREATED_ON"),
        render: (note) => moment(note.CreateDate).format("DD MMM YYYY").toString(),
        sortable: true,
      },
      {
        dataIndex: "UserName",
        id: "userName",
        title: t("LABEL_CREATED_BY"),
        render: (note) => (note.UserName ? note.UserName : "-"),
        sortable: true,
      },
    ];
    return notesColumns;
  };

  const addNotesDropdownButtonOptions = [
    {
      id: 1,
      title: t("INTERNAL_NOTE"),
      type: "INTERNAL",
    },
    {
      id: 2,
      title: t("PUBLIC_NOTE"),
      type: "PUBLIC",
    },
  ];

  const openAddNotesModal = () => {
    const addNoteModal = { ...addNotesModal };
    addNoteModal.openAddNotes = true;
    setAddNotesModal(addNoteModal);
  };

  const closeAddNotesModal = () => {
    const addNoteModal = { ...addNotesModal };
    addNoteModal.openAddNotes = false;
    setAddNotesModal(addNoteModal);
  };

  const onChangeNoteType = (id: number) => {
    setNoteType(id);
    openAddNotesModal();
  };

  return (
    <>
      <WebNoteModal
        closeIcon
        closeModal={() => closeAddNotesModal()}
        closeOnOutsideClick={false}
        isNote
        jobReference={props.jobReference}
        open={addNotesModal.openAddNotes}
        type={noteType}
      />
      {isEmployee && (
        <div className="md:my-6 md:ml-6 flex md:justify-end rounded my-6 ml-40">
          <DropdownButton
            label={t("LABEL_ADD_NOTE")}
            onClick={onChangeNoteType}
            options={addNotesDropdownButtonOptions}
            type="secundary"
          />
        </div>
      )}
      {deleteModal.open && (
        <DeleteModal
          closeModal={closeDeleteModal}
          content={t("TEXT_DELETE_NOTE")}
          header={t("LABEL_DELETE_NOTE")}
          onDelete={() => handleDelete(noteId)}
          open={deleteModal.open}
        />
      )}
      <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
        <div className="w-full">
          <LoadingModal isOpen={loadingNotes && loadingRemarks}>
            {t("TEXT_LOADING_NOTES")}
          </LoadingModal>
          {values && values.length == 0 ? (
            <div className="text-neutral-700 px-8 py-6 text-sm">{t("TEXT_NO_NOTES_AVAILABLE")}</div>
          ) : (
            <div className="mx-8 my-6">
              <ActionTable
                canRemove={isEmployee}
                columns={getNotesColumns()}
                getRowId={(note: WebNote) => note.RecId}
                headerTextCase="none"
                id="note"
                isBordered={false}
                onRemove={(RecId: string) => openDeleteModal(RecId)}
                rows={notes}
                scrollable={false}
              />
            </div>
          )}
        </div>
      </Page>
    </>
  );
};

export default NotesComponent;
