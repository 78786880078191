import React, { useState } from "react";

import { Booking, BookingAddress, Company, ErrorTypes, ModalProps } from "@assets/types";
import Button from "@components/Button/button";
import Checkbox from "@components/Input/checkbox";
import ValidationError from "@components/ValidationError/validationError";
import { Modal } from "@samskip/frontend-components";
import { Trans, useTranslation } from "react-i18next";

interface SwitchCompanyModalProps extends ModalProps {
  userSelectedCompany: Company | null;
  booking: Booking | null;
  shipper: BookingAddress | null | undefined;
  consignee: BookingAddress | null | undefined;
  open: boolean;
  onSwitchCompany: (booking: Booking | null, partnerCode: string) => void;
  loadingSwitchCompany: boolean;
}

const SwitchCompanyModal: React.FC<SwitchCompanyModalProps> = ({
  userSelectedCompany,
  open,
  closeModal,
  booking,
  onSwitchCompany,
  shipper,
  consignee,
  loadingSwitchCompany,
}: SwitchCompanyModalProps) => {
  const { t } = useTranslation();

  const [selectedCompany, setSelectedCompany] = useState<string | null | undefined>(null);

  const [selectedCompanyError, setSelectedCompanyError] = useState<string | null>(null);

  const onClose = () => {
    closeModal();
  };

  const onHandleProceed = () => {
    if (!selectedCompany) {
      setSelectedCompanyError(ErrorTypes.RequiredField);
    } else {
      setSelectedCompanyError(null);
      onSwitchCompany(booking, selectedCompany ? selectedCompany : "");
    }
  };

  return (
    <Modal closeIcon onClose={onClose} open={open}>
      <Modal.Header divider>{t("LABEL_SWITCH_COMPANY")}</Modal.Header>
      <Modal.Content className="text-neutral-900">
        <p>
          <Trans
            components={{ bold: <strong /> }}
            i18nKey="TEXT_SWITCH_COMPANY_HEADER_N"
            values={{ n: userSelectedCompany?.FullName }}
          />
        </p>
        <p>{t("TEXT_SELECT_COMPANY")}</p>
        <div className="flex">
          <Checkbox
            checked={selectedCompany === shipper?.PartnerCode}
            className="pb-1 mr-2"
            label={shipper?.Name}
            onChange={() => setSelectedCompany(shipper?.PartnerCode)}
            type="radio"
          />
          <Checkbox
            checked={selectedCompany === consignee?.PartnerCode}
            className="pb-1 mr-2 ml-2"
            label={consignee?.Name}
            onChange={() => setSelectedCompany(consignee?.PartnerCode)}
            type="radio"
          />
        </div>
        {selectedCompanyError && <ValidationError errorText={selectedCompanyError} />}
        <p>{t("TEXT_SWITCH_COMPANY_FOOTER")}</p>
      </Modal.Content>
      <Modal.Footer divider>
        <Button onClick={onClose}>{t("LABEL_CANCEL")}</Button>
        <Button
          active={loadingSwitchCompany}
          onClick={() => {
            onHandleProceed();
          }}
          primary
        >
          {t("LABEL_PROCEED")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwitchCompanyModal;
