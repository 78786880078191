import React from "react";

import { Toast as ToastItem } from "@assets/types";
import Toast from "@components/Toast/toast";
import { removeToast } from "@state/actions";
import { AppState } from "@state/reducers/types";
import { connect } from "react-redux";

type ToastsProps = {
  removeToast: Function;
  toasts: ToastItem[];
};

const Toasts: React.FC<ToastsProps> = ({ removeToast, toasts }) => {
  return (
    <ul className="absolute top-100px right-0 z-50 mr-10 mt-4">
      {toasts.map((toast) => {
        const { id } = toast;
        return <Toast key={id} {...toast} removeToast={() => removeToast(id)} />;
      })}
    </ul>
  );
};

const mapStateToProps = (state: AppState) => ({
  toasts: state.ToastReducer.toasts,
});

export default connect(mapStateToProps, { removeToast })(Toasts);
