import React from "react";

import IconButton from "@components/Button/iconButton";
import LabelEx, { labelCases } from "@components/Label/labelEx";
import { Translation } from "react-i18next";
import { FaPen, FaTrash } from "react-icons/fa";

type LayoutCellProps = {
  children: React.ReactNode;
  canDelete?: boolean;
  canEdit?: boolean;
  tooltipContent?: string;
  labelValue?: string;
  className?: string;
  onClickDeleteIcon?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickEditIcon?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  error?: string | null;
  /** If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /**	If true, the input element shows as required. */
  required?: boolean;
  /** The id of the layout cell element.  */
  id: string;
  /** Transform the label displayed case. */
  labelCase?: labelCases;
};

export const LayoutCell = React.forwardRef(
  (
    {
      children,
      canDelete = false,
      canEdit = false,
      tooltipContent,
      labelValue,
      className,
      onClickDeleteIcon,
      onClickEditIcon,
      error,
      optional = false,
      optionalText,
      required = false,
      id,
      labelCase = "none",
    }: LayoutCellProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Translation>
        {(t) => (
          <div className={`${className}`}>
            <div className="flex justify-between items-start">
              {labelValue && (
                <LabelEx
                  className="font-semibold text-3.25"
                  forID={id}
                  labelCase={labelCase}
                  optional={optional}
                  optionalText={optionalText}
                  required={required}
                  tooltipContent={tooltipContent}
                >
                  {labelValue}
                </LabelEx>
              )}
              {(canDelete || canEdit) && (
                <div className="grid grid-cols-2 self-end place-self-end mr-2 mb-2 gap-4">
                  {canEdit && (
                    <IconButton
                      active={false}
                      className={`rounded-full ${canDelete === false ? `col-end-13` : ""}`}
                      disabled={false}
                      onClick={onClickEditIcon}
                    >
                      <FaPen className="text-base" />
                    </IconButton>
                  )}
                  {canDelete && (
                    <IconButton
                      active={false}
                      className="rounded-full"
                      disabled={false}
                      onClick={onClickDeleteIcon}
                    >
                      <FaTrash className="text-base" />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
            {children}
            {error != null && error != "" && (
              <div className="text-red-600 my-2" ref={ref}>
                <span> {t(error)}</span>
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
);

LayoutCell.displayName = "LayoutCell";

export default LayoutCell;
