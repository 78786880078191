import { MenuItem } from "@assets/types";

class NavService {
  setupMenu = async (menuProp: MenuItem[]) => {
    const menu: MenuItem[] = JSON.parse(JSON.stringify(menuProp));
    const menuTree: MenuItem[] = [];

    // create a name: node map
    const dataMap = menu.reduce((map, node) => {
      map[node.MenuID] = node;
      return map;
    }, {});

    menu.forEach((node: MenuItem) => {
      // add to parent
      const parent = node.MenuParent ? dataMap[node.MenuParent] : null;
      if (parent) {
        // create child array if it doesn't exist
        const nodes = parent.children || (parent.children = []);
        // add node to child array
        node.OrderID ? nodes.splice(node.OrderID, 0, node) : nodes.push(node);
      } else {
        // parent is null or missing
        menuTree.push(node);
      }
    });
    return menuTree;
  };
}

export default new NavService();
