import React, { Component } from "react";

import { titlePrefix } from "@assets/constants";
import Spinner from "@components/Spinner/spinner";
import { AppState } from "@state/reducers/types";
import i18n from "i18next";
import Iframe from "react-iframe";
import { connect } from "react-redux";

const OldServiceWeb = process.env.OldServiceWeb;

class DocumentUpload extends Component<
  { path?: string; iframeUpdater: number },
  { loading: boolean }
> {
  state = { loading: true };

  componentDidMount() {
    document.title = titlePrefix + i18n.t("MENU_DOCUMENT_UPLOAD");
  }

  componentDidUpdate(prevProps: { path?: string; iframeUpdater: number }) {
    if (this.props.iframeUpdater !== prevProps.iframeUpdater) {
      this.setState({ loading: true });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Spinner isHidden={!loading} />
        <Iframe
          className="overflow-hidden iframe-main-container shadow-lg flex-grow"
          height={loading ? "0%" : "100%"}
          key={`${this.props.iframeUpdater}`}
          onLoad={() => this.setState({ loading: false })}
          url={`${OldServiceWeb}/DocumentUpload.aspx?Iframe=1`}
          width={loading ? "0%" : "100%"}
        />
      </>
    );
  }
}

export default connect((state: AppState) => {
  return { iframeUpdater: state.UserReducer.iframeUpdater };
}, {})(DocumentUpload);
