import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler } from "react";

import { classNames } from "@assets/helperFunctions";
import IconButtonUpdated from "@components/Button/iconButtonUpdated";
import LabelEx, { labelCases } from "@components/Label/labelEx";
import ValidationError from "@components/ValidationError/validationError";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface InputExProps {
  /** Whether or not the input field should have autocomplete enabled. */
  autoComplete?: boolean;
  /** Extend the style applied to the outer container. */
  className?: string;
  /** If true the component is disabled.*/
  disabled?: boolean;
  /** The error message to show. !NOTE: use with hasError=true. */
  errorMessage?: string;
  /** If true, the component takes up the full width of it's container. */
  fullWidth?: boolean;
  /** If true, the component is displayed with a error style. */
  hasError?: boolean;
  /** Description text for the input. */
  helperDescription?: string;
  /** Adds the icon to the left of the input. */
  iconLeft?: IconDefinition;
  /** Adds the icon to the right of the input. */
  iconRight?: IconDefinition;
  /** Removes outlines and background. */
  isOnlyText?: boolean;
  /** The id of the input element.  */
  id?: string;
  /** The ref to pass to the input in case forwardRef doesn't work. */
  innerRef?: React.ForwardedRef<HTMLInputElement>;
  /** To help the browser which keyboard to display. */
  inputmode?: "search" | "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal";
  /** A text to be used in the label element. */
  label?: string;
  /** Transform the label displayed case. */
  labelCase?: labelCases;
  /** The maximum number of characters allowed in the input. */
  maxLength?: number;
  /** The minimum number of characters allowed in the input. */
  minLength?: number;
  /** Name attribute of the input element. */
  name?: string;
  /** Callback fired when focus has left the element */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** Callback fired when the value is changed. */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** Callback fired when the element is clicked. */
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  /** Callback fired when a key is being pressed. */
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  /** Callback fired when a key is pressed. */
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  /** Callback fired when the left icon is clicked. */
  onLeftIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** Callback fired when the right icon is clicked. */
  onRightIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** If true, the input element shows an optional text. */
  optional?: boolean;
  /** Set the optional text to show. !NOTE: use with optional=true. */
  optionalText?: string;
  /**  Short hint that describes the expected value of the input. */
  placeholder?: string;
  /**	If true, the input element shows as required. */
  required?: boolean;
  /** The size of the input. */
  size?: "large" | "medium" | "small";
  /** The type of the input element to display. */
  type?: "username" | "password" | "search" | "text" | "number" | "tel";
  /** Controlled value of the input. */
  value?: string;
  unitLeft?: string;
  unitRight?: string;
  min?: string | number;
  tooltipContent?: string;
}

const heights = {
  large: "h-12",
  medium: "h-10",
  small: "h-8",
};

const leftPaddingsWithLeftIcon = {
  large: "pl-12",
  medium: "pl-12",
  small: "pl-7.5",
};

const leftPaddingsWithoutLeftIcon = {
  large: "pl-4",
  medium: "pl-4",
  small: "pl-2",
};

const rightPaddingsWithRightIcon = {
  large: "pr-12",
  medium: "pr-12",
  small: "pr-7.5",
};

const rightPaddingsWithoutRightIcon = {
  large: "pr-4",
  medium: "pr-4",
  small: "pr-2",
};

const topBottomPaddings = {
  large: "py-3",
  medium: "py-2.5",
  small: "py-2",
};

// Paddings for icons
const leftIconPaddings = {
  large: "pl-5",
  medium: "pl-5",
  small: "pl-3",
};

const rightIconPaddings = {
  large: "pr-5",
  medium: "pr-5",
  small: "pr-3",
};

const fontSizes = {
  large: "text-base",
  medium: "text-sm",
  small: "text-xs",
};

const getInputIcon = (
  size: "small" | "medium" | "large",
  icon: IconDefinition,
  iconPosition: "left" | "right",
  onIconClick?: MouseEventHandler<HTMLButtonElement>
) => {
  if (onIconClick) {
    return (
      <IconButtonUpdated
        className={classNames(
          "flex absolute inset-y-0 items-center",
          iconPosition === "left" ? "left-0" : "right-0",
          iconPosition === "left" ? leftIconPaddings[size] : rightIconPaddings[size]
        )}
        color="neutral-600"
        icon={icon}
        onClick={onIconClick}
        size={size === "small" ? "xs" : undefined}
      />
    );
  }
  return (
    <div
      className={classNames(
        "flex absolute inset-y-0 items-center pointer-events-none",
        iconPosition === "left" ? "left-0" : "right-0",
        iconPosition === "left" ? leftIconPaddings[size] : rightIconPaddings[size]
      )}
    >
      <FontAwesomeIcon
        className="text-neutral-600  text-base"
        icon={icon}
        size={size === "small" ? "xs" : undefined}
      />
    </div>
  );
};

const getInputUnit = (
  size: "small" | "medium" | "large",
  unit: string,
  unitPosition: "left" | "right"
) => (
  <div
    className={classNames(
      "flex absolute inset-y-0 items-center pointer-events-none",
      unitPosition === "left" ? "left-0" : "right-0",
      unitPosition === "left" ? leftIconPaddings[size] : rightIconPaddings[size]
    )}
  >
    {unit}
  </div>
);

export const InputEx = React.forwardRef<HTMLInputElement, InputExProps>(
  (
    {
      autoComplete = false,
      className,
      disabled = false,
      errorMessage,
      fullWidth = false,
      hasError = false,
      helperDescription,
      iconLeft,
      iconRight,
      isOnlyText,
      unitLeft,
      unitRight,
      id,
      innerRef,
      inputmode,
      label,
      labelCase = "none",
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onKeyDown,
      onKeyPress,
      onLeftIconClick,
      onRightIconClick,
      optional,
      optionalText,
      placeholder,
      required,
      size = "medium",
      type = "text",
      value,
      min,
      tooltipContent,
    },
    ref
  ) => {
    return (
      <div
        className={classNames(
          `flex flex-col gap-2 items-baseline`,
          fullWidth ? "w-full" : "",
          className
        )}
      >
        {label && (
          <LabelEx
            className="font-semibold text-3.25"
            forID={id}
            labelCase={labelCase}
            optional={optional}
            optionalText={optionalText}
            required={required}
            tooltipContent={tooltipContent}
          >
            {label}
          </LabelEx>
        )}
        <div className="w-full relative">
          {iconLeft && getInputIcon(size, iconLeft, "left", onLeftIconClick)}
          {unitLeft && getInputUnit(size, unitLeft, "left")}
          <input
            autoComplete={autoComplete ? "on" : "off"}
            className={classNames(
              `bg-neutral-0 appearance-none w-full text-neutral-900 placeholder:text-neutral-600
              rounded border
              hover:border-neutral-400
              focus:outline-none focus:ring-0 focus:border-neutral-400`,
              hasError ? "border-error" : "",
              disabled && !isOnlyText ? "bg-neutral-200 border-neutral-400" : "",
              isOnlyText ? "bg-transparent outline-none border-none" : "",
              !hasError && !disabled ? "border-neutral-400" : "",
              heights[size],
              iconLeft || unitLeft
                ? leftPaddingsWithLeftIcon[size]
                : leftPaddingsWithoutLeftIcon[size],
              iconRight || unitRight
                ? rightPaddingsWithRightIcon[size]
                : rightPaddingsWithoutRightIcon[size],
              topBottomPaddings[size],
              fontSizes[size]
            )}
            disabled={disabled}
            inputMode={inputmode}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            ref={ref || innerRef}
            type={type}
            value={value}
          />
          {iconRight && getInputIcon(size, iconRight, "right", onRightIconClick)}
          {unitRight && getInputUnit(size, unitRight, "right")}
        </div>
        {hasError && errorMessage && <ValidationError errorText={errorMessage} />}
        {helperDescription && <p className="text-neutral-800 text-body-xs">{helperDescription}</p>}
      </div>
    );
  }
);

export default InputEx;

InputEx.displayName = "InputEx";
