import React from "react";

import InputEx, { InputExProps } from "@components/Input/inputEx";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";

interface SearchExProps
  extends Omit<InputExProps, "iconRight" | "iconLeft" | "onRightIconClick" | "onLeftIconClick"> {
  isClearable?: boolean;
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SearchEx = ({ placeholder, isClearable, onClear, ...rest }: SearchExProps) => {
  const customPlaceholder = placeholder ?? `Search...`;
  return (
    <InputEx
      {...rest}
      iconLeft={faMagnifyingGlass}
      iconRight={isClearable ? faTimes : undefined}
      onRightIconClick={onClear}
      placeholder={customPlaceholder}
    />
  );
};

export default SearchEx;
