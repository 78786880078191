import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M21.05,31.11c2.45,0,4.44,1.98,4.44,4.42c0,2.44-1.99,4.42-4.44,4.42s-4.44-1.98-4.44-4.42
                C16.61,33.09,18.6,31.11,21.05,31.11z M40.08,31.11c2.45,0,4.44,1.98,4.44,4.42c0,2.44-1.99,4.42-4.44,4.42
                c-2.45,0-4.44-1.98-4.44-4.42C35.65,33.09,37.64,31.11,40.08,31.11z"
      />
      <path
        className={`${styles.st0}`}
        d="M23.18,27.35l8.06-16.59h7.85c1.62,0,2.45,0.97,2.45,2.21v12.06"
      />
      <path
        className={`${styles.st0}`}
        d="M18.76,31.74h-3.74V8.93h-3.67v19.9v9.72H1.58 M16.61,35.53h-5.26 M44.52,37.54h3.89V27.25
                c0-1.25-0.82-2.21-2.44-2.21H35.14c-1.22,0-2.22,0.99-2.22,2.21v3.42H27.1v-1.11c0-1.25-0.82-2.21-2.44-2.21h-7.42
                c-1.22,0-2.22,0.99-2.22,2.21 M25.08,37.54h10.83"
      />
    </g>
  </svg>
);

export default SVG;
