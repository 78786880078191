import React from "react";

import styles from "./spinner.module.less";

const Spinner: React.FC<SpinnerProps> = ({ isHidden, className = "bg-light-gray" }) => {
  return isHidden ? null : (
    <div
      className={`z-0 flex flex-col justify-center items-center flex-grow h-full w-full ${className}`}
    >
      <div className={`${styles.skfadingcircle}`}>
        <div className={`${styles.skcircle1} ${styles.skcircle}`} />
        <div className={`${styles.skcircle2} ${styles.skcircle}`} />
        <div className={`${styles.skcircle3} ${styles.skcircle}`} />
        <div className={`${styles.skcircle4} ${styles.skcircle}`} />
        <div className={`${styles.skcircle5} ${styles.skcircle}`} />
        <div className={`${styles.skcircle6} ${styles.skcircle}`} />
        <div className={`${styles.skcircle7} ${styles.skcircle}`} />
        <div className={`${styles.skcircle8} ${styles.skcircle}`} />
        <div className={`${styles.skcircle9} ${styles.skcircle}`} />
        <div className={`${styles.skcircle10} ${styles.skcircle}`} />
        <div className={`${styles.skcircle11} ${styles.skcircle}`} />
        <div className={`${styles.skcircle12} ${styles.skcircle}`} />
      </div>
    </div>
  );
};

type SpinnerProps = {
  isHidden?: boolean;
  className?: string;
};

export default Spinner;
