import { DocumentReferenceType } from "@assets/constants";
import { BookingDocumentResponse, UploadDocumentsResponse } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

class UploadDoc {
  jobReference: string;
  docTypes: string[];
  referenceType: DocumentReferenceType;
  files: File[];
  userReference: DocumentReferenceType;
  userName: string;
}

export const getBookingDocuments = createAsyncThunk(
  "SingleBooking/jobReference/documents",
  async (jobReference: string) => {
    return await SingleBookingService.getBookingDocuments(jobReference);
  }
);

export const uploadDocuments = createAsyncThunk(
  "SingleBooking/uploadDocuments",
  async (uploadDoc: UploadDoc) => {
    return await SingleBookingService.uploadDocuments(
      uploadDoc.jobReference,
      uploadDoc.docTypes,
      uploadDoc.referenceType,
      uploadDoc.userReference,
      uploadDoc.userName,
      uploadDoc.files
    );
  }
);

export interface DocumentsState {
  bookingDocuments: BookingDocumentResponse[];
  loadingDocumentByReference: boolean;
  uploadDocuments: UploadDocumentsResponse[];
  document: string;
  uploadStatus: null | string;
  loadingUploadDocuments: boolean;
}

export const initialDocumentsState: DocumentsState = {
  bookingDocuments: [],
  loadingDocumentByReference: false,
  uploadDocuments: [],
  document: "",
  uploadStatus: null,
  loadingUploadDocuments: false,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState: initialDocumentsState,
  reducers: {
    resetUploadDocumentsState: (state) => {
      state.uploadStatus = null;
      state.loadingUploadDocuments = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingDocuments.rejected, (state) => {
        state.bookingDocuments = [];
      })
      .addCase(
        getBookingDocuments.fulfilled,
        (state, action: PayloadAction<BookingDocumentResponse[]>) => {
          state.bookingDocuments = action.payload;
        }
      )
      .addCase(uploadDocuments.pending, (state) => {
        state.loadingUploadDocuments = true;
      })
      .addCase(uploadDocuments.rejected, (state) => {
        state.uploadStatus = "failed";
        state.loadingUploadDocuments = false;
        state.uploadDocuments = [];
      })
      .addCase(
        uploadDocuments.fulfilled,
        (state, action: PayloadAction<UploadDocumentsResponse[]>) => {
          state.uploadStatus = "OK";
          state.loadingUploadDocuments = false;
          state.uploadDocuments = action.payload;
        }
      );
  },
});

export const documentState = (state: RootState) => state.documentsSlice;

export const { resetUploadDocumentsState } = documentsSlice.actions;

export default documentsSlice.reducer;
