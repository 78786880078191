import { CargoBookingResponse } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SingleBookingService from "@services/SingleBookingService";
import { RootState } from "@state/store";

export const getBookingCargo = createAsyncThunk(
  "SingleBooking/jobReference/cargo",
  async (jobReference: string) => {
    return await SingleBookingService.getBookingCargo(jobReference);
  }
);

export interface CargoBookingState {
  cargoBooking: CargoBookingResponse | null;
  loadingCargoBooking: boolean;
}

export const initialCargoBookingState: CargoBookingState = {
  cargoBooking: null,
  loadingCargoBooking: false,
};

export const cargoBookingSlice = createSlice({
  name: "cargoBooking",
  initialState: initialCargoBookingState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBookingCargo.pending, (state) => {
        state.loadingCargoBooking = true;
      })
      .addCase(getBookingCargo.rejected, (state) => {
        state.loadingCargoBooking = false;
        state.cargoBooking = null;
      })
      .addCase(getBookingCargo.fulfilled, (state, action: PayloadAction<CargoBookingResponse>) => {
        state.loadingCargoBooking = false;
        state.cargoBooking = action.payload;
      });
  },
});

export const cargoBookingState = (state: RootState) => state.cargoBookingSlice;

export default cargoBookingSlice.reducer;
