import React, { PureComponent } from "react";

import { ModalProps } from "@assets/types";
import Modal from "@components/Modal/modal";
import Spinner from "@components/Spinner/spinner";
import { closeModal } from "@state/actions/index";
import Iframe from "react-iframe";
import { connect } from "react-redux";

const OldServiceWeb = process.env.OldServiceWeb;

class CompanyModal extends PureComponent<ModalProps, { loading: boolean }> {
  state = { loading: true };

  onClose = () => {
    this.setState({ loading: true });
    this.props.closeModal();
  };

  render() {
    const { open, header } = this.props;
    const { loading } = this.state;
    return (
      <Modal onClose={this.onClose} open={open} size="xlarge">
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content className="h-748">
          <Spinner className="relative bg-light-gray" isHidden={!loading} />
          <Iframe
            className="overflow-hidden iframe-main-container shadow-lg"
            height={loading ? "0%" : "100%"}
            onLoad={() => this.setState({ loading: false })}
            url={`${OldServiceWeb}/CompanyProfile.aspx?Iframe=1`}
            width={loading ? "0%" : "100%"}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  () => {
    return {};
  },
  { closeModal }
)(CompanyModal);
