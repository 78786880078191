/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

import { FreightMode } from "@assets/constants";
import { RouteBookingResponse } from "@assets/types";
import Divider from "@components/Divider/divider";
import LayoutCell from "@components/LayoutCell/layoutCell";
import Spinner from "@components/Spinner/spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface RoutePageProps {
  routeBooking: RouteBookingResponse | undefined | null;
  freightMode: string | undefined | null;
  isLoadingRoute: boolean;
}

const RouteComponent: React.FC<RoutePageProps> = (props: RoutePageProps) => {
  const { t } = useTranslation();

  const getWarehouseText = (): string | undefined => {
    if (props.routeBooking?.Warehouse) {
      return props.routeBooking.Warehouse.map((w) => t(`LABEL_${w}`)).join(", ");
    }
    return t("TEXT_NOT_PROVIDED");
  };

  return (
    <>
      {props.isLoadingRoute ? (
        <Spinner className="w-full flex align-center justify-center" />
      ) : (
        <div className="w-full">
          <div className="flex flex-col md:flex-row md:justify-between mx-8">
            <LayoutCell
              className="md:w-1/3 w-12/12 my-6"
              id="singlebooking_shipment_number"
              labelValue={t("LABEL_SHIPPMENT_NUMBER")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.ShipmentNumber != null
                  ? props.routeBooking?.ShipmentNumber
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 my-6"
              id="singlebooking_input_ref"
              labelValue={t("LABEL_INPUT_REFERENCE")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.InputReference != null
                  ? t(props.routeBooking?.InputReference)
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 my-6"
              id="singlebooking_voyage_ref"
              labelValue={t("LABEL_VOYAGE_REFERENCE")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.VoyageReference?.VoyageReference != null
                  ? props.routeBooking?.VoyageReference?.VoyageReference
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between mx-8">
            <LayoutCell
              className="md:w-1/3 w-12/12"
              id="singlebooking_warehouse"
              labelValue={t("LABEL_WAREHOUSE")}
            >
              <div className="text-neutral-9">{getWarehouseText()}</div>
            </LayoutCell>
          </div>
          <Divider className="my-8" />
          <div className="flex flex-col md:flex-row md:justify-between mx-8">
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_shipper"
              labelValue={t("LABEL_SHIPPER")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.Shipper != null ? (
                  <ul>
                    {props.routeBooking?.Shipper?.FullName && (
                      <li>{props.routeBooking?.Shipper?.FullName}</li>
                    )}
                    {props.routeBooking?.Shipper?.Address2 ? (
                      <li>{props.routeBooking?.Shipper?.Address2}</li>
                    ) : (
                      <li>{props.routeBooking?.Shipper?.Address3}</li>
                    )}
                    {props.routeBooking?.Consignee?.SSN && (
                      <li>{props.routeBooking?.Consignee?.SSN}</li>
                    )}
                    {props.routeBooking?.Shipper?.PointCode && (
                      <li>{props.routeBooking?.Shipper?.PointCode}</li>
                    )}
                    {props.routeBooking?.Shipper?.PartnerCode && (
                      <li>{props.routeBooking?.Shipper?.PartnerCode}</li>
                    )}
                    {props.routeBooking?.Shipper?.ContactName && (
                      <li>{props.routeBooking?.Shipper?.ContactName}</li>
                    )}
                    {props.routeBooking?.Shipper?.ContactNumber && (
                      <li>{props.routeBooking?.Shipper?.ContactNumber}</li>
                    )}
                    {props.routeBooking?.Shipper?.ContactEmail && (
                      <li>{props.routeBooking?.Shipper?.ContactEmail}</li>
                    )}
                    {props.routeBooking?.Shipper?.SSN && (
                      <li>{props.routeBooking?.Shipper?.SSN}</li>
                    )}
                  </ul>
                ) : (
                  <span> {t("TEXT_NOT_PROVIDED")} </span>
                )}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_consignee"
              labelValue={t("LABEL_CONSIGNEE")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.Consignee != null ? (
                  <ul>
                    {props.routeBooking?.Consignee?.FullName && (
                      <li>{props.routeBooking?.Consignee?.FullName}</li>
                    )}
                    {props.routeBooking?.Consignee?.Address2 ? (
                      <li>{props.routeBooking?.Consignee?.Address2}</li>
                    ) : (
                      <li>{props.routeBooking?.Consignee?.Address3}</li>
                    )}
                    {props.routeBooking?.Consignee?.SSN && (
                      <li>{props.routeBooking?.Consignee?.SSN}</li>
                    )}
                    {props.routeBooking?.Consignee?.PointCode && (
                      <li>{props.routeBooking?.Consignee?.PointCode}</li>
                    )}
                    {props.routeBooking?.Consignee?.PartnerCode && (
                      <li>{props.routeBooking?.Consignee?.PartnerCode}</li>
                    )}
                    {props.routeBooking?.Consignee?.ContactName && (
                      <li>{props.routeBooking?.Consignee?.ContactName}</li>
                    )}
                    {props.routeBooking?.Consignee?.ContactNumber && (
                      <li>{props.routeBooking?.Consignee?.ContactNumber}</li>
                    )}
                    {props.routeBooking?.Consignee?.ContactEmail && (
                      <li>{props.routeBooking?.Consignee?.ContactEmail}</li>
                    )}
                  </ul>
                ) : (
                  <span> {t("TEXT_NOT_PROVIDED")} </span>
                )}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_notifier"
              labelValue={t("LABEL_NOTIFIER")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.Notifier != null ? (
                  <ul>
                    {props.routeBooking?.Notifier?.FullName && (
                      <li>{props.routeBooking?.Notifier?.FullName}</li>
                    )}
                    {props.routeBooking?.Notifier?.Address2 ? (
                      <li>{props.routeBooking?.Notifier?.Address2}</li>
                    ) : (
                      <li>{props.routeBooking?.Notifier?.Address3}</li>
                    )}
                    {props.routeBooking?.Notifier?.PointCode && (
                      <li>{props.routeBooking?.Notifier?.PointCode}</li>
                    )}
                    {props.routeBooking?.Notifier?.PartnerCode && (
                      <li>{props.routeBooking?.Notifier?.PartnerCode}</li>
                    )}
                    {props.routeBooking?.Notifier?.ContactName && (
                      <li>{props.routeBooking?.Notifier?.ContactName}</li>
                    )}
                    {props.routeBooking?.Notifier?.ContactNumber && (
                      <li>{props.routeBooking?.Notifier?.ContactNumber}</li>
                    )}
                    {props.routeBooking?.Notifier?.ContactEmail && (
                      <li>{props.routeBooking?.Notifier?.ContactEmail}</li>
                    )}
                  </ul>
                ) : (
                  <span> {t("TEXT_NOT_PROVIDED")} </span>
                )}
              </div>
            </LayoutCell>
          </div>
          <div className="flex flex-col md:flex-row justify-between mx-8">
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_from"
              labelValue={t("LABEL_FROM")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.POL || props.routeBooking?.PLR ? (
                  <>
                    {props.routeBooking?.POL ? (
                      <ul>
                        {props.routeBooking?.POL?.FullName && (
                          <li>{props.routeBooking?.POL?.FullName}</li>
                        )}
                        {props.routeBooking?.POL?.Country && (
                          <li>{props.routeBooking?.POL?.Country}</li>
                        )}
                        {props.routeBooking?.POL?.PointCode && (
                          <li>{props.routeBooking?.POL?.PointCode}</li>
                        )}
                        {props.routeBooking?.POL?.PortType && (
                          <li>{props.routeBooking?.POL?.PortType}</li>
                        )}
                      </ul>
                    ) : (
                      <ul>
                        {props.routeBooking?.PLR?.FullName && (
                          <li>{props.routeBooking?.PLR?.FullName}</li>
                        )}
                        {props.routeBooking?.PLR?.Country && (
                          <li>{props.routeBooking?.PLR?.Country}</li>
                        )}
                        {props.routeBooking?.PLR?.PointCode && (
                          <li>{props.routeBooking?.PLR?.PointCode}</li>
                        )}
                        {props.routeBooking?.PLR?.PortType && (
                          <li>{props.routeBooking?.PLR?.PortType}</li>
                        )}
                      </ul>
                    )}
                    {props.routeBooking?.Shipper?.RequestDate &&
                    props.routeBooking?.Shipper?.RequestDate ? (
                      <li className="list-none">
                        {t("Request collection time: ")}
                        {props.routeBooking?.Shipper.RequestDate +
                          ", " +
                          props.routeBooking?.Shipper.RequestTime}
                      </li>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <span>
                    {`${props.routeBooking?.VoyageReference?.POL ?? t("TEXT_NOT_PROVIDED")}`}
                  </span>
                )}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_to"
              labelValue={t("LABEL_TO")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.POD || props.routeBooking?.PFD ? (
                  <>
                    {props.routeBooking?.POD ? (
                      <ul>
                        {props.routeBooking?.POD?.FullName && (
                          <li>{props.routeBooking?.POD?.FullName}</li>
                        )}
                        {props.routeBooking?.POD?.Country && (
                          <li>{props.routeBooking?.POD?.Country}</li>
                        )}
                        {props.routeBooking?.POD?.PointCode && (
                          <li>{props.routeBooking?.POD?.PointCode}</li>
                        )}
                        {props.routeBooking?.POD?.PortType && (
                          <li>{props.routeBooking?.POD?.PortType}</li>
                        )}
                        {props.routeBooking?.VoyageReference?.ETA && (
                          <li>
                            {`ETA: ${moment(props.routeBooking?.VoyageReference?.ETA)
                              .format("DD MMM YYYY")
                              .toString()}`}
                          </li>
                        )}
                      </ul>
                    ) : (
                      <ul>
                        {props.routeBooking?.PFD?.FullName && (
                          <li>{props.routeBooking?.PFD?.FullName}</li>
                        )}
                        {props.routeBooking?.PFD?.Country && (
                          <li>{props.routeBooking?.PFD?.Country}</li>
                        )}
                        {props.routeBooking?.PFD?.PointCode && (
                          <li>{props.routeBooking?.PFD?.PointCode}</li>
                        )}
                        {props.routeBooking?.PFD?.PortType && (
                          <li>{props.routeBooking?.PFD?.PortType}</li>
                        )}
                        {props.routeBooking?.VoyageReference?.ETA && (
                          <li>
                            {`ETA: ${moment(props.routeBooking?.VoyageReference?.ETA)
                              .format("DD MMM YYYY")
                              .toString()}`}
                          </li>
                        )}
                      </ul>
                    )}
                    {props.routeBooking?.Consignee?.RequestDate != null &&
                    props.routeBooking?.Consignee.RequestTime ? (
                      <li className="list-none">
                        {t("Request collection time: ")}
                        {props.routeBooking?.Consignee.RequestDate +
                          ", " +
                          props.routeBooking?.Consignee.RequestTime}
                      </li>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <span>
                    {`${props.routeBooking?.VoyageReference?.POD ?? t("TEXT_NOT_PROVIDED")}`}
                  </span>
                )}
              </div>
            </LayoutCell>
            <div className="md:w-1/3 w-12/12">
              <LayoutCell
                className="mb-6"
                id="singlebooking_incoterms"
                labelValue={t("LABEL_INCOTERMS")}
              >
                <div className="text-neutral-9">
                  {props.routeBooking?.IncoTerms != null
                    ? props.routeBooking?.IncoTerms
                    : t("TEXT_NOT_PROVIDED")}
                </div>
              </LayoutCell>
              <LayoutCell
                className="mb-6"
                id="singlebooking_incoterms_location"
                labelValue={t("LABEL_INCOTERMS_LOCATION")}
              >
                <div className="text-neutral-9">
                  {props.routeBooking?.IncoTermsPoint != null
                    ? props.routeBooking?.IncoTermsPoint
                    : t("TEXT_NOT_PROVIDED")}
                </div>
              </LayoutCell>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between mx-8">
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_quote_number"
              labelValue={t("LABEL_QUOTE_NUMBER")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.QuoteNumber != null
                  ? props.routeBooking?.QuoteNumber
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_bol_type"
              labelValue={t("LABEL_BILL_OF_LADING_TYPE")}
            >
              <div className="text-neutral-9">
                {props.routeBooking?.BOLType != null
                  ? props.routeBooking?.BOLType
                  : t("TEXT_NOT_PROVIDED")}
              </div>
            </LayoutCell>
            <LayoutCell
              className="md:w-1/3 w-12/12 mb-6"
              id="singlebooking_bol_number"
              labelValue={
                props.freightMode === FreightMode.AI
                  ? t("LABEL_HAWB_NUMBER")
                  : t("LABEL_BILL_OF_LADING_NUMBER")
              }
            >
              {props.freightMode === FreightMode.AI ? (
                <div className="text-neutral-9">
                  {props.routeBooking?.BookingPartyReference != null
                    ? props.routeBooking?.BookingPartyReference
                    : t("TEXT_NOT_PROVIDED")}
                </div>
              ) : (
                <div className="text-neutral-9">
                  {props.routeBooking?.BOLNumber != null
                    ? props.routeBooking?.BOLNumber
                    : t("TEXT_NOT_PROVIDED")}
                </div>
              )}
            </LayoutCell>
          </div>
        </div>
      )}
    </>
  );
};

export default RouteComponent;
