import React, { ReactNode } from "react";

import TabTitle from "./tabTitle";

type Props = {
  children: ReactNode[];
  selectedTab: number;
  setSelectedTab: (selectedTab: number) => void;
  className?: string;
};

const Tabs: React.FC<Props> = ({ children, selectedTab, setSelectedTab, className }) => {
  return (
    <div className={className}>
      <ul
        className="nav nav-tabs flex flex-row flex-wrap list-none border-b-2 border-neutral-500 pl-0 mb-8" //overflow-y-auto
        id="tabs-tab"
        role="tablist"
      >
        {React.Children.map(children, (item, index) => {
          if (item)
            return (
              <TabTitle
                href={item.props.href}
                index={index}
                isActive={selectedTab === index}
                key={`tab${-index}`}
                setSelectedTab={setSelectedTab}
                title={item.props.title}
              />
            );
          // eslint-disable-next-line react/jsx-no-useless-fragment
          else return <></>;
        })}
      </ul>
      {children ? children[selectedTab] : null}
    </div>
  );
};

export default Tabs;
