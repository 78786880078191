import React from "react";

import styles from "./card.module.less";

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={`transition duration-300 ease-in-out overflow-visible ${className} ${styles.cardWrapper}`}
    >
      {children}
    </div>
  );
};

export default Card;
