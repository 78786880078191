import React, { Component } from "react";

import { FreightMode, PageType, UserRole } from "@assets/constants";
import { UserProfileViewModel } from "@assets/types";
import Administration from "@pages/Administration/administration";
import Finance from "@pages/Finance/finance";
import StorageFees from "@pages/Finance/storageFees";
import Login from "@pages/Login/login";
import ForgotPassword from "@pages/Login/PasswordReset/forgotPassword";
import ResetPassword from "@pages/Login/PasswordReset/resetPassword";
import Master from "@pages/Master/master";
import NotFound from "@pages/NotFound/notFound";
import Requests from "@pages/Requests/requests";
import CreateBookingComponent from "@pages/Shipments/CreateTrace/createBookingComponent";
import CreateTrace from "@pages/Shipments/CreateTrace/createTrace";
import CustomsRequests from "@pages/Shipments/customsRequests";
import DocumentUpload from "@pages/Shipments/DocumentUpload/documentUpload";
import Shipments from "@pages/Shipments/shipments";
import SingleBooking from "@pages/Shipments/SingleBooking/singleBooking";
import UserProfile from "@pages/Users/userProfile";
import WarehouseReceptions from "@pages/Warehouse/receptions";
import { Router, globalHistory } from "@reach/router";
import PrivateRoute from "@routes/privateRoute";
import AuthService from "@services/AuthService";
import { logout, getProfile, checkAlerts } from "@state/actions/index";
import { AppState } from "@state/reducers/types";
import { connect } from "react-redux";

interface RoutesProps {
  checkAlerts: Function;
  getProfile: Function;
  loggedInOld: boolean;
  profileLoaded: boolean;
  userProfile: UserProfileViewModel;
}
class Routes extends Component<RoutesProps, {}> {
  constructor(props: RoutesProps) {
    super(props);
  }

  async componentDidMount() {
    await this.props.getProfile();
    // globalHistory covers this in development mode
    if (process.env.GATSBY_ACTIVE_ENV) this.props.checkAlerts(globalHistory.location.pathname);

    globalHistory.listen((hist) => {
      if (hist.action === "PUSH" && hist.location.pathname) {
        this.props.checkAlerts(hist.location.pathname);
        if (
          hist.location.pathname !== "/login" &&
          hist.location.pathname !== "/password/reset" &&
          hist.location.pathname !== "/forgotPassword"
        ) {
          this.props.getProfile();
        }
      }
    });
  }

  render() {
    const { userProfile, profileLoaded, loggedInOld } = this.props;
    return (
      <Router className="flex-grow flex-col flex">
        <Login path="/login" />
        <PrivateRoute
          component={SingleBooking}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/singleBooking/:freightMode/:jobReference"
        />
        <CreateBookingComponent path="/createBooking/:shipmentType/:jobReference" />
        <ForgotPassword path="/forgotPassword" />
        <ResetPassword path="/password/reset/:hash" />
        <PrivateRoute
          component={Shipments}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.SHIP, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/shipments"
        />
        <PrivateRoute
          component={DocumentUpload}
          hasAccess={AuthService.hasUserAccessToRoles([
            UserRole.BUISN,
            UserRole.SYSADMIN,
            UserRole.DOCUPLOAD,
          ])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="shipments/upload"
        />
        <PrivateRoute
          component={CreateTrace}
          freightMode={FreightMode.AI}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.CREATE])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          pageType={PageType.CREATE}
          path="shipments/createairfreightbooking"
        />
        <PrivateRoute
          component={CreateTrace}
          freightMode={FreightMode.SH}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.CREATE])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          pageType={PageType.CREATE}
          path="shipments/createseafreightbooking"
        />
        <PrivateRoute
          component={CreateTrace}
          freightMode={FreightMode.AI}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.CREATE])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          pageType={PageType.COPY}
          path="shipments/copycreateairfreightbooking/:jobReference"
        />
        <PrivateRoute
          component={CreateTrace}
          freightMode={FreightMode.SH}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.CREATE])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          pageType={PageType.COPY}
          path="shipments/copycreateseafreightbooking/:jobReference"
        />
        <PrivateRoute
          component={Requests}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.SHIP, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/requests"
        />
        <PrivateRoute
          component={Finance}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.FINANCE, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/finance"
        />
        <PrivateRoute
          component={Finance}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.FINANCE, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/finance"
        />
        <PrivateRoute
          component={StorageFees}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.FINANCE, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/finance/storage"
        />
        <PrivateRoute
          component={UserProfile}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.SHIP, UserRole.FINANCE])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/userprofile"
        />
        <PrivateRoute
          component={Administration}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/administration"
        />
        <PrivateRoute
          component={CustomsRequests}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.BUISN, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="/customs"
        />
        <PrivateRoute
          component={WarehouseReceptions}
          hasAccess={AuthService.hasUserAccessToRoles([UserRole.BUISN, UserRole.SYSADMIN])}
          isLoaded={profileLoaded && loggedInOld}
          loggedIn={userProfile != undefined}
          path="warehouse/receptions"
        />
        <PrivateRoute
          component={Master}
          hasAccess
          isLoaded={profileLoaded}
          loggedIn={userProfile != undefined}
          path="/"
        />
        <PrivateRoute
          component={NotFound}
          hasAccess
          isLoaded={profileLoaded}
          loggedIn={userProfile != undefined}
          path="*"
        />
        {/* 
         <PrivateRoute
          path="/maintenance"
          component={Maintenance}
          loggedIn={userProfile != undefined}
          isLoaded={profileLoaded}
          hasAccess={true}
        />
        */}
      </Router>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userProfile: state.UserReducer.userProfile,
    profileLoaded: state.UserReducer.profileLoaded,
    loggedInOld: state.UserReducer.loggedInOld,
  }),
  { logout, getProfile, checkAlerts }
)(Routes);
