import React, { useEffect, useState } from "react";

import { ModalProps, TableInfo, WebNote } from "@assets/types";
import Table from "@components/Table/table";
import moment from "moment";
import { Translation, useTranslation } from "react-i18next";

import Modal from "./modal";

interface MemoModalProps extends ModalProps {
  open: boolean;
  bookingReference: string;
  webObject: WebNote[];
}

const MemoModal: React.FC<MemoModalProps> = ({
  open,
  closeModal,
  bookingReference,
  webObject,
}: MemoModalProps) => {
  const { t } = useTranslation();

  const [infoContent, setInfoContent] = useState<WebNote[]>([]);

  useEffect(() => {
    const fetchWebNotes = () => {
      try {
        setInfoContent(webObject);
      } catch (error) {
        console.log(error);
      }
    };

    fetchWebNotes();
  }, [bookingReference]);

  const onClose = () => {
    closeModal();
  };

  const renderInfoContent = (webNotes: WebNote[]) => {
    const tableData: TableInfo = {
      tableHeader: ["LABEL_NOTE", "LABEL_TYPE", "LABEL_CREATE_DATE", "LABEL_USER"],
      tableData: webNotes.map((webNote) => [
        <span className="text-black whitespace-normal" key="note">
          {webNote.Note}
        </span>,
        <span className="text-black whitespace-normal" key="internal-memo">
          {webNote.IsInternal ? t("LABEL_INTERNAL_MEMO") : t("LABEL_MEMO")}
        </span>,
        <span className="text-black whitespace-normal" key="create-date">
          {moment(webNote.CreateDate).format("DD.MM.yyyy HH:mm")}
        </span>,
        <span className="text-black whitespace-normal" key="username">
          {webNote.UserName}
        </span>,
      ]),
      classes: ["text-black", "text-black", "text-black", "text-black"],
    };
    return <Table isLight tableInfo={tableData} />;
  };

  return (
    <Translation>
      {(t) => (
        <Modal
          className="left-2 right-2 mb-auto mt-[32px]"
          onClose={onClose}
          open={open}
          size="xlarge"
        >
          <Modal.Header>
            <span>{t("LABEL_NOTES_ON") + `${bookingReference}`}</span>
          </Modal.Header>
          <Modal.Content className="bg-white grid overflow-x-auto text-black">
            {renderInfoContent(infoContent)}
          </Modal.Content>
          <Modal.Footer />
        </Modal>
      )}
    </Translation>
  );
};

export default MemoModal;
