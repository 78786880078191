import React from "react";

type PageProps = {
  children: React.ReactNode;
  className?: string;
};

export const Page: React.FC<PageProps> = ({ children, className }) => {
  return <div className={`flex flex-col static bg-white shadow ${className} `}>{children}</div>;
};

export default Page;
