import React, { PureComponent } from "react";

import IconButton from "@components/Button/iconButton";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

import styles from "./listFilterLayout.module.less";

type LayoutProps = {
  children: React.ReactNode;
  showFilterSearchButtons?: boolean;
};

class ListFilterLayout extends PureComponent<LayoutProps> {
  state = {};
  render() {
    const { children } = this.props;

    return (
      <div
        className={`listFilterLayout w-full mx-auto my-8 px-2 lg:px-8 transition duration-300 ease-in-out overflow-visible ${styles.listFilterLayout}`}
      >
        <div
          className={`listFilterLayoutInner flex flex-col relative md:flex-row justify-items-stretch ${styles.innerList} bg-white shadow-lg`}
        >
          {this.props.showFilterSearchButtons ? (
            <>
              <span className="arrow-filter-container" />
              <IconButton
                active={false}
                className="arrow-filter-scroll-up lg:px-2"
                disabled={false}
                onClick={() => {
                  document.getElementById("dpFilterPanel").scrollTop -= 50;
                }}
              >
                <FiArrowUp className="text-base" />
              </IconButton>
              <IconButton
                active={false}
                className="arrow-filter-scroll-down lg:px-2"
                disabled={false}
                onClick={() => {
                  document.getElementById("dpFilterPanel").scrollTop += 50;
                }}
              >
                <FiArrowDown className="text-base" />
              </IconButton>
            </>
          ) : null}
          {children}
        </div>
      </div>
    );
  }
}

export default ListFilterLayout;
