import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        className={`${styles.st0}`}
        d="M30.07,35.02l14.68-14.68l-3.35-3.35L26.71,31.67l-1.38,4.78L30.07,35.02z M35.04,18.57h-9.01V9.55
                M35.04,23.34v-4.78l-9.01-9.01H10.34v34.34h24.7V30.32"
      />
      <polyline className={`${styles.st1}`} points="9.34,8.55 9.34,44.89 45.75,44.89 	" />
      <path
        className={`${styles.st0}`}
        d="M14.27,33.67h11.87 M14.27,28.43h15.69 M14.27,23.19h16.85"
      />
    </g>
  </svg>
);

export default SVG;
