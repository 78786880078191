import React, { ChangeEventHandler, FocusEventHandler } from "react";

import i18n from "i18next";

type TextareaProps = {
  className?: string;
  value: string;
  name?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  onKeyPress?: Function;
  error?: string;
  hasError?: boolean;
};

const Textarea: React.FC<TextareaProps> = ({
  name,
  value,
  onChange,
  onBlur,
  className,
  placeholder,
  disabled,
  minLength,
  maxLength,
  onKeyPress,
  error,
  hasError,
}) => (
  <>
    <textarea
      className={`appearance-none w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none ${className}`}
      disabled={disabled}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onKeyPress={onKeyPress ? (e) => onKeyPress(e) : undefined}
      placeholder={placeholder}
      value={value}
    />
    {hasError && <p className="text-red-500 my-2">{i18n.t(error ?? "")}</p>}
  </>
);

export default Textarea;
