import React from "react";

import { classNames } from "@assets/utilities/classNameUtilities";

interface ValidationErrorProps {
  /** Override or extend the style applied to the component. */
  className?: string;
  /** The error text to show. */
  errorText: string;
}

const ValidationError = ({ errorText, className }: ValidationErrorProps) => {
  return <p className={classNames("text-error leading-4.5 text-xs", className)}>{errorText}</p>;
};

export default ValidationError;
