import { GroupBase, StylesConfig, ThemeConfig } from "react-select";

export const styles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  hasError: boolean,
  disabled: boolean,
  menuFitLongestOption: boolean,
  variant: "normal" | "compact"
): StylesConfig<Option, IsMulti, Group> => {
  const isCompact = variant === "compact";
  const noBorder = isCompact; // Variable to delimit what styles are related to border and other compact styles
  const fitLongestOption = isCompact || menuFitLongestOption; // In compact mode always fit longest option
  return {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderColor: "rgb(var(--color-neutral-400))",
      ...(noBorder && { border: 0 }),
      ...(noBorder ? { minHeight: "1rem" } : { minHeight: "2.5rem" }),
      ...(hasError &&
        !isFocused && {
          borderColor: "rgb(var(--color-error))",
        }),
      ...(disabled && {
        backgroundColor: "rgb(var(--color-neutral-200))",
        borderColor: "rgb(var(--color-neutral-400))",
        color: "rgb(var(--color-neutral-600))",
      }),
      ...(isFocused && {
        borderColor: "rgb(var(--color-neutral-400))",
      }),
      borderRadius: "4px",
      width: "100%",
      boxShadow: "none",
      ":hover": {
        borderColor: "rgb(var(--color-neutral-400))",
      },
      fontFamily: "var(--font)",
    }),
    input: (provided) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      margin: 0,
      ...(isCompact && { padding: 0 }),
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgb(var(--color-neutral-600))",
      margin: 0,
      fontSize: "0.875rem",
    }),
    // Don't show separator.
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...(isCompact && { padding: "0 0 1px 0" }),
    }),
    // without the border there is too much padding, so remove it
    valueContainer: (provided) => ({
      ...provided,
      ...(isCompact ? { padding: 0 } : { paddingLeft: "16px" }),
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "256px",
    }),
    // To fix the problem when some options are longer than the others
    menu: (provided) => ({
      ...provided,
      ...(fitLongestOption && { width: "max-content", minWidth: "100%" }),
    }),
    option: (provided, { isFocused, isSelected, isDisabled }) => ({
      ...provided,
      color: "rgb(var(--color-neutral-900))",
      // The background color when selected is 300, when hovered 200
      backgroundColor: isSelected
        ? "rgb(var(--color-neutral-300))"
        : isFocused
        ? "rgb(var(--color-neutral-200))"
        : undefined,
      // The background when selecting the selected would fall back to react-select primary
      ":active": {
        ...provided[":active"],
        backgroundColor: !isDisabled && isSelected ? "rgb(var(--color-neutral-300))" : undefined,
      },
    }),
  };
};

export const theme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    danger: "rgb(var(--color-error))", // x color at multiple select
    primary25: "rgb(var(--color-neutral-200))", // hover option background
    primary50: "rgb(var(--color-neutral-300))", // click on option background
    primary75: "rgb(var(--color-neutral-400))",
    primary: "rgb(var(--color-primary-500))", // selected option background, border on hover
    neutral5: "rgb(var(--color-neutral-200))", // disabled background
    neutral10: "rgb(var(--color-neutral-500))", //multiselect background, border
    neutral20: "rgb(var(--color-neutral-500))", // border
  },
});
