import React from "react";

import styles from "./icons.module.less";

const SVG = ({ className = "", ...props }) => (
  <svg
    className={`${styles.customSvgIcon} ${className}`}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path className={`${styles.st0}`} d="M12.5,31l10-10c0.81-0.81,1.94-1.03,3-1 M32.5,26l5,5" />
      <path
        className={`${styles.st0}`}
        d="M22.5,11v2c0,1.79-4,2.44-4,7c0,1.38,0.5,2.63,1.37,3.63 M22.27,25.36C23.23,25.77,24.32,26,25.5,26
                c3.56,0,5.44-3.11,6-5c0.29-1-1.71-1.89-2-1c-0.33,1-1.57,2-2.5,2c-1.38,0-2.5-1.12-2.5-2.5c0-0.82,0.39-1.54,1-2
                c1.78-1.29,3-2.11,3-4.26V11 M28.5,35h-7v-4h7V35z M39.5,48h-29V31h29V48z M17.5,2v6l3,3h10l3-3V2H17.5z"
      />
    </g>
  </svg>
);

export default SVG;
