import React from "react";

import { FiMail } from "react-icons/fi";

type ContactButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ContactButton = ({ onClick }: ContactButtonProps) => {
  return (
    <button
      className="fixed right-0 right-8 bottom-8 z-30 shadow-lg transition duration-500 ease-in-out bg-jonar-orange hover:bg-blue-800 focus:bg-blue-800 transform hover:-translate-y-1 mt-2 focus:outline-none px-3 py-2 text-white rounded"
      onClick={onClick}
      type="button"
    >
      <FiMail className="text-2xl" />
    </button>
  );
};

export default ContactButton;
