import { WebNote } from "@assets/types";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BookingService from "@services/BookingService";
import { RootState } from "@state/store";

class WebNoteReq {
  jobReference: string;
  isInternalNote: boolean;
}

class CreateWebNoteReq {
  jobReference: string;
  note: string;
  isInternalNote: boolean;
}

export const getWebNote = createAsyncThunk("bookings/note", async (req: WebNoteReq) => {
  return await BookingService.getWebNote(req.jobReference, req.isInternalNote);
});

export const getWebNotes = createAsyncThunk("bookings/notes", async (jobReference: string) => {
  return await BookingService.getWebNotes(jobReference);
});

export const createWebNote = createAsyncThunk(
  "bookings/note/create",
  async (req: CreateWebNoteReq) => {
    return await BookingService.createWebNote(req.jobReference, req.note, req.isInternalNote);
  }
);

export const getRemarks = createAsyncThunk("bookings/remarks", async (jobReference: string) => {
  return await BookingService.getRemarks(jobReference);
});

export interface NotesState {
  webNote: WebNote | null;
  webNotes: WebNote[];
  loadingNotes: boolean;
  createNote: string | null;
  loadingCreateNotes: boolean;
  addNoteStatus: null | string;
  remarks: WebNote[];
  loadingRemarks: boolean;
}

export const initialNotesState: NotesState = {
  webNote: null,
  webNotes: [],
  loadingNotes: false,
  createNote: null,
  loadingCreateNotes: false,
  addNoteStatus: null,
  remarks: [],
  loadingRemarks: false,
};

export const notesSlice = createSlice({
  name: "notes",
  initialState: initialNotesState,
  reducers: {
    resetAddNoteState: (state) => {
      state.addNoteStatus = null;
      state.loadingCreateNotes = false;
      state.loadingRemarks = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebNote.rejected, (state) => {
        state.webNote = null;
      })
      .addCase(getWebNote.fulfilled, (state, action: PayloadAction<WebNote>) => {
        state.webNote = action.payload;
      })
      .addCase(getWebNotes.pending, (state) => {
        state.loadingNotes = true;
      })
      .addCase(getWebNotes.rejected, (state) => {
        state.loadingNotes = false;
        state.webNotes = [];
      })
      .addCase(getWebNotes.fulfilled, (state, action: PayloadAction<WebNote[]>) => {
        state.loadingNotes = false;
        state.webNotes = action.payload;
      })
      .addCase(createWebNote.rejected, (state) => {
        state.addNoteStatus = "failed";
        state.createNote = null;
      })
      .addCase(createWebNote.fulfilled, (state, action: PayloadAction<string>) => {
        state.addNoteStatus = "OK";
        state.createNote = action.payload;
      })
      .addCase(getRemarks.pending, (state) => {
        state.loadingRemarks = true;
      })
      .addCase(getRemarks.rejected, (state) => {
        state.loadingRemarks = false;
        state.remarks = [];
      })
      .addCase(getRemarks.fulfilled, (state, action: PayloadAction<WebNote[]>) => {
        state.loadingRemarks = false;
        state.remarks = action.payload;
      });
  },
});

export const noteState = (state: RootState) => state.notesSlice;

export const { resetAddNoteState } = notesSlice.actions;

export default notesSlice.reducer;
