import React from "react";

import { FreightMode, ShippingTypeValue } from "@assets/constants";
import { ExposedBookingCargoDetailsRes, ExposedContainerDataRes } from "@assets/types";
import Link from "@components/Link/link";
import Tooltip from "@components/Tooltip/tooltip";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ActionTableJonar } from "@samskip/frontend-components";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { FaBuffer, FaExclamationTriangle, FaTemperatureHigh } from "react-icons/fa";
import StringUtilities from "utilities/StringUtilities";

type ContainerDetailsCellProps = {
  containerNumber: string;
  containerData: ExposedContainerDataRes;
  shippingTypeValue?: string | null;
  freightMode?: string;
};

export const ContainerDetailsCell: React.FC<ContainerDetailsCellProps> = ({
  containerNumber,
  containerData,
  shippingTypeValue,
  freightMode,
}) => {
  const { t } = useTranslation();

  const handleTemperature = (
    isRefrigerated: boolean | null,
    minTemp: number | null,
    maxTemp: number | null
  ): string => {
    let temperature = "";
    if (isRefrigerated) {
      minTemp && minTemp < 0 ? (temperature = `${minTemp}°C`) : (temperature = `+${minTemp}°C`);
      temperature = temperature.concat("/");
      maxTemp && maxTemp < 0
        ? (temperature = temperature.concat(`${maxTemp}°C`))
        : (temperature = temperature.concat(`+${maxTemp}°C`));
    } else temperature = i18n.t("TEXT_NO");
    return temperature;
  };

  const createCargoDataTableHeaders = () => {
    return [
      {
        id: "Description",
        name: t("LABEL_CARGO_DESCRIPTION"),
        className: "w-96",
      },
      {
        id: "QTY",
        name: t("LABEL_QUANTITY_ABBREV"),
      },
      {
        id: "PackageType",
        name: t("LABEL_PACKAGE"),
      },
      {
        id: "Volume",
        name: t("LABEL_VOLUME"),
      },
      {
        id: "Weight",
        name: t("LABEL_WEIGHT"),
      },
      {
        id: "Stackable",
        name: (
          <Tooltip childClassName="inline-flex" content={t("INFO_STACKABLE")} key="Stackable">
            <FaBuffer className="text-base" key="Stackable" />
          </Tooltip>
        ),
      },
      {
        id: "Hazardous",
        name: (
          <Tooltip childClassName="inline-flex" content={t("INFO_HAZARDOUS")} key="Hazardous">
            <FaExclamationTriangle className="text-base" key="Hazardous" />
          </Tooltip>
        ),
      },
      {
        id: "TemperatureControlled",
        name: (
          <Tooltip
            childClassName="inline-flex"
            content={t("INFO_TEMPERATURE_VALUES")}
            key="Temperature"
          >
            <FaTemperatureHigh className="text-base" key="Temperature" />
          </Tooltip>
        ),
      },
    ];
  };

  const createCargoDataTable = (details: ExposedBookingCargoDetailsRes[] | null) => {
    return details
      ? details.map((detail, index) => {
          return {
            id: `cargo-${index}`,
            cells: [
              detail.Description ?? "N/A",
              detail.Quantity ?? 0,
              detail.PackageCode ?? "N/A",
              detail?.Volume
                ? `${StringUtilities.numberCommaDecimal(detail?.Volume)}
                              ${detail.VolumeUOM}`
                : 0,
              detail?.Weight
                ? `${StringUtilities.numberCommaDecimal(detail?.Weight)}
                              ${detail.WeightUOM}`
                : 0,
              detail.IsStackable ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
              detail.IsHazardous ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
              handleTemperature(
                detail.IsRefrigerated,
                detail.MinTemperature,
                detail.MaxTemperature
              ),
            ],
          };
        })
      : [];
  };

  const createDataTable = (
    details: ExposedBookingCargoDetailsRes[] | null,
    detailIndex: number
  ) => {
    if (!details) return [];
    const detail = details[detailIndex];
    return [
      {
        id: `cargo-${detailIndex}`,
        cells: [
          detail.Description ?? "N/A",
          detail.Quantity ?? 0,
          detail.PackageCode ?? "N/A",
          detail?.Volume
            ? `${StringUtilities.numberCommaDecimal(detail?.Volume)}
                              ${detail.VolumeUOM}`
            : 0,
          detail?.Weight
            ? `${StringUtilities.numberCommaDecimal(detail?.Weight)}
                              ${detail.WeightUOM}`
            : 0,
          detail.IsStackable ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          detail.IsHazardous ? i18n.t("TEXT_YES") : i18n.t("TEXT_NO"),
          detail.IsRefrigerated
            ? `${detail.MinTemperature}°C/${detail.MaxTemperature}°C`
            : i18n.t("TEXT_NO"),
        ],
      },
    ];
  };

  const renderDetailTableByIndex = (detailIndex: number) => {
    return (
      <div className="my-6">
        <ActionTableJonar
          className="divide-y divide-neutral-500"
          headers={createCargoDataTableHeaders()}
          id="CargoTable"
          shouldFade={false}
          tableData={createDataTable(containerData.Details, detailIndex)}
        />
      </div>
    );
  };

  const renderDetailTable = () => {
    return (
      <div className="my-6">
        <ActionTableJonar
          className="divide-y divide-neutral-500"
          headers={createCargoDataTableHeaders()}
          id="CargoTable"
          shouldFade={false}
          tableData={createCargoDataTable(containerData.Details)}
        />
      </div>
    );
  };

  const bookingReferenceSection = containerData.Details?.map((detail, index) => (
    <div className="mt-6" key={`detail-${index}`}>
      <div className="grid grid-cols-3 mb-4">
        <div className="flex items-center">
          <div className="rounded-full bg-jonar-blue h-6 w-6 text-white m-2 flex justify-center items-center">
            {index + 1}
          </div>
          <div className="flex flex-col">
            <label className="text-neutral-7">{t("LABEL_BOOKING_REFERENCE")}</label>
            {detail.BcBookingReference ? (
              <div>
                <Link
                  href={`/singleBooking/${freightMode}/${detail.BcBookingReference}`}
                  icon={faArrowUpRightFromSquare}
                  target="_blank"
                >
                  {detail.BcBookingReference}
                </Link>
              </div>
            ) : (
              <div>N/A</div>
            )}
          </div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_SHIPPER")}</label>
          <div className="text-neutral-9">{detail.BcBookingShipper ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_CONSIGNEE")}</label>
          <div className="text-neutral-9">{detail.BcBookingConsignee ?? "N/A"}</div>
        </div>
      </div>
      {renderDetailTableByIndex(index)}
    </div>
  ));

  const containerDetails = () => {
    return (
      <div className="border-2 p-4 grid grid-cols-5">
        <div>
          <label className="text-neutral-7">{t("LABEL_CONTAINER_NUMBER")}</label>
          <div className="text-neutral-9">{containerNumber ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_CONTAINER_TYPE")}</label>
          <div className="text-neutral-9">{containerData.ContainerType ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_SEAL_NUMBER")}</label>
          <div className="text-neutral-9">{containerData.SealNumber ?? "N/A"}</div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_VOLUME")}</label>
          <div className="text-neutral-9">
            {containerData.Details?.reduce((x, y) => {
              return x + (y.Volume ?? 0);
            }, 0)}
          </div>
        </div>
        <div>
          <label className="text-neutral-7">{t("LABEL_WEIGHT")}</label>
          <div className="text-neutral-9">
            {containerData.Details?.reduce((x, y) => {
              return x + (y.Weight ?? 0);
            }, 0)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {freightMode == FreightMode.SH && shippingTypeValue === ShippingTypeValue.LL && (
        <>{renderDetailTable()}</>
      )}
      {freightMode == FreightMode.SH &&
        (shippingTypeValue === ShippingTypeValue.LF ||
          shippingTypeValue === ShippingTypeValue.FL) && (
          <>
            {containerDetails()}
            {bookingReferenceSection}
          </>
        )}
      {freightMode == FreightMode.SH && shippingTypeValue === ShippingTypeValue.FF && (
        <>
          {containerDetails()}
          {renderDetailTable()}
        </>
      )}
      {freightMode == FreightMode.AI && shippingTypeValue === ShippingTypeValue.LL && (
        <>{renderDetailTable()}</>
      )}
    </>
  );
};

ContainerDetailsCell.displayName = "ContainerDetailsCell";

export default ContainerDetailsCell;
