import { CompanyAddress } from "@assets/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateBookingService from "@services/CreateBookingService";
import { RootState } from "@state/store";

class PartnerFilter {
  partnerCode: string;
  addressType: string;
}

export const getPartnerAddress = createAsyncThunk(
  "partner/partneraddress",
  async (partnerFilter: PartnerFilter) => {
    return await CreateBookingService.getPartnerAddress(
      partnerFilter.partnerCode,
      partnerFilter.addressType
    );
  }
);

export const getCompanyCustomerAddresses = createAsyncThunk(
  "partner/customers",
  async (partnerFilter: PartnerFilter) => {
    return await CreateBookingService.getCompanyCustomerAddresses(
      partnerFilter.partnerCode,
      partnerFilter.addressType
    );
  }
);

export const getCompanyAddresses = createAsyncThunk(
  "partner/addresses",
  async (partnerFilter: PartnerFilter) => {
    return await CreateBookingService.getCompanyAddresses(
      partnerFilter.partnerCode,
      partnerFilter.addressType
    );
  }
);

export const searchPartners = createAsyncThunk(
  "partner/search/customers",
  async (searchString: string) => {
    return await CreateBookingService.searchPartners(searchString);
  }
);

export interface PartnerState {
  partnerAddress: CompanyAddress | null;
  loadingPartnerAddresses: boolean;
  companyCustomerAddresses: CompanyAddress[];
  loadingCompanyAddresses: boolean;
  companyAddresses: CompanyAddress[];
  loadingCompanyCustomerAddresses: boolean;
  searchPartners: CompanyAddress[];
}

export const initialPartnerState: PartnerState = {
  partnerAddress: null,
  loadingPartnerAddresses: false,
  companyCustomerAddresses: [],
  loadingCompanyAddresses: false,
  companyAddresses: [],
  loadingCompanyCustomerAddresses: false,
  searchPartners: [],
};

export const partnerSlice = createSlice({
  name: "partnerAddress",
  initialState: initialPartnerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerAddress.pending, (state) => {
        state.loadingPartnerAddresses = true;
      })
      .addCase(getPartnerAddress.rejected, (state) => {
        state.loadingPartnerAddresses = false;
        state.partnerAddress = null;
      })
      .addCase(getPartnerAddress.fulfilled, (state, action: PayloadAction<CompanyAddress>) => {
        state.loadingPartnerAddresses = false;
        state.partnerAddress = action.payload;
      })
      .addCase(getCompanyCustomerAddresses.pending, (state) => {
        state.loadingCompanyAddresses = true;
      })
      .addCase(getCompanyCustomerAddresses.rejected, (state) => {
        state.loadingCompanyAddresses = false;
        state.companyCustomerAddresses = [];
      })
      .addCase(
        getCompanyCustomerAddresses.fulfilled,
        (state, action: PayloadAction<CompanyAddress[]>) => {
          state.loadingCompanyAddresses = false;
          state.companyCustomerAddresses = action.payload;
        }
      )
      .addCase(getCompanyAddresses.pending, (state) => {
        state.loadingCompanyCustomerAddresses = true;
      })
      .addCase(getCompanyAddresses.rejected, (state) => {
        state.loadingCompanyCustomerAddresses = false;
        state.companyAddresses = [];
      })
      .addCase(getCompanyAddresses.fulfilled, (state, action: PayloadAction<CompanyAddress[]>) => {
        state.loadingCompanyCustomerAddresses = false;
        state.companyAddresses = action.payload;
      })
      .addCase(searchPartners.rejected, (state) => {
        state.searchPartners = [];
      })
      .addCase(searchPartners.fulfilled, (state, action: PayloadAction<CompanyAddress[]>) => {
        state.searchPartners = action.payload;
      });
  },
});

export const partnerState = (state: RootState) => state.partnerSlice;

export default partnerSlice.reducer;
