import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { Request } from "@assets/types";
import Link from "@components/Link/link";
import Page from "@components/Page/page";
import { ActionTable } from "@samskip/frontend-components/";
import { ActionTableProps } from "@samskip/frontend-components/dist/components/Table/actionTable";
import { requestState, getRequests } from "@state/slices/requestsSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface RequestsPageProps {
  jobReference: string;
}

const RequestsComponent: React.FC<RequestsPageProps> = (props: RequestsPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const requests = useAppSelector(requestState).requests;

  const OldServiceWeb = process.env.OldServiceWeb;

  useEffect(() => {
    dispatch(getRequests({ jobReference: props.jobReference, requestType: null }));
  }, [window.location.href.split("#")[1] === "Requests"]);

  const getRequestColumns = () => {
    const requestColumns: ActionTableProps<Request>["columns"] = [
      {
        dataIndex: "Status",
        id: "status",
        title: t("LABEL_STATUS"),
        sortable: true,
      },
      {
        dataIndex: "TypeDescription",
        id: "typeDescription",
        title: t("LABEL_REQUEST_TYPE"),
        sortable: true,
      },
      {
        dataIndex: "CreateTime",
        id: "createTime",
        title: t("LABEL_CREATED_ON"),
        render: (request) =>
          request.CreateTime != null
            ? moment(request.CreateTime).format("DD MMM YYYY").toString()
            : "N/A",
        sortable: true,
      },
      {
        dataIndex: "CloseTime",
        id: "closeTime",
        title: t("LABEL_CLOSED_ON"),
        render: (request) =>
          request.CloseTime != null
            ? moment(request.CloseTime).format("DD MMM YYYY").toString()
            : "N/A",
        sortable: true,
      },
      {
        dataIndex: "UserName",
        id: "userName",
        title: t("LABEL_REQUEST_BY"),
        sortable: true,
      },
      {
        dataIndex: "",
        id: "link",
        title: "",
        render: (request) => (
          <Link
            className="text-jonar-blue"
            key="request-link"
            onClick={() =>
              window.open(
                `${OldServiceWeb}/DisplayBookingInquiry.aspx?ReqNo=${request.ID}`,
                "_blank",
                "location=yes,height=780,width=800,scrollbars=yes,status=yes"
              )
            }
          >
            {t("LABEL_VIEW_REQUEST")}
          </Link>
        ),
      },
    ];
    return requestColumns;
  };

  return (
    <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
      <div className="w-full">
        {requests && requests.length == 0 ? (
          <div className="text-neutral-700 px-8 py-6 text-sm">
            {t("TEXT_NO_REQUESTS_AVAILABLE")}
          </div>
        ) : (
          <div className="mx-8 my-6">
            <ActionTable
              columns={getRequestColumns()}
              getRowId={(request: Request) => request.ID.toString()}
              headerTextCase="none"
              id="request"
              isBordered={false}
              rows={requests}
              scrollable={false}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default RequestsComponent;
