import i18next from "i18next";
import Backend from "i18next-http-backend";
import Cache from "i18next-localstorage-cache";
import { initReactI18next } from "react-i18next";

const AuthAPI = process.env.AuthAPI;
import UserService from "./services/UserService";

const supportedLanguages = ["is", "en"];

i18next
  .use(Backend)
  .use(Cache)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    fallbackLng: supportedLanguages,
    supportedLngs: supportedLanguages,
    cleanCode: true,
    debug: process.env.NODE_ENV === "production" ? false : true,
    cache: { enabled: true, expirationTime: 24 * 60 * 60 * 1000 },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${AuthAPI}/translations/languages/{{lng}}`,
      parse: function (data) {
        data = JSON.parse(data);
        const obj = {};
        data.map((item) => {
          if (item.Singular) obj[item.Key] = item.Singular;
          if (item.Plural) obj[item.Key + "_plural"] = item.Plural;
        });
        return obj;
      },
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
  });

const setUserSelectedLanguage = async () => {
  await UserService.getUserSelectedLanguage()
    .then((res) => {
      if (res) i18next.changeLanguage(res);
    })
    .catch(() => null);
};

i18next.on("loaded", function () {
  setUserSelectedLanguage();
});

export default i18next;
