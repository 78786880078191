import React, { useEffect, useState } from "react";

import { AcceptedFileType, FreightMode } from "@assets/constants";
import { Document, DocumentError, SelectOption } from "@assets/types";
import Button from "@components/Button/button";
import FileUploaderComponent from "@components/DropFileInput/fileUploaderComponent";
import { Translation } from "react-i18next";

type AddDocumentsPageProps = {
  freightMode: FreightMode;
  documents: Document[];
  onDocumentAdded: (document: Document) => void;
  onDocumentDeleted: (index: number) => void;
  onChangeSelectedDocType: (selectedDocType: SelectOption<string>, index: number) => void;
  setSelectedTab: (selectedTab: number) => void;
  onHandleContinue: () => void;
  getDocumentsErrors: () => DocumentError[];
  invalidDocument: boolean;
};

const AddDocumentsComponent: React.FC<AddDocumentsPageProps> = (props: AddDocumentsPageProps) => {
  const [dragging, setDragging] = useState<boolean>(false);

  let fileUploaderInput: HTMLElement | null = null;

  const overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("drop", (event: Event) => {
      overrideEventDefaults(event);
    });
  }, []);

  useEffect(() => {
    window.removeEventListener("drop", overrideEventDefaults);
  }, []);

  const fileChecker = (fileType: string) => {
    return (
      fileType === AcceptedFileType.PDF ||
      fileType === AcceptedFileType.JPEG ||
      fileType === AcceptedFileType.XLSX ||
      fileType === AcceptedFileType.DOC ||
      fileType === AcceptedFileType.DOCX ||
      fileType === AcceptedFileType.XLS
    );
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    let files: File[] = [];
    if (event.dataTransfer.files) {
      files = Array.from(event.dataTransfer.files);
    }

    files.forEach((file) => {
      if (fileChecker(file.type))
        props.onDocumentAdded({
          document: file,
          doctype: "",
        });
    });
  };

  const onSelectFileClick = () => {
    fileUploaderInput && fileUploaderInput.click();
  };

  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let files: File[] = [];
      if (event.target.files) {
        files = Array.from(event.target.files);
      }

      files.forEach((file) => {
        if (fileChecker(file.type))
          props.onDocumentAdded({
            document: file,
            doctype: "",
          });
      });
    }
  };

  return (
    <Translation>
      {(t) => (
        <div className="py-10 mx-8">
          <div className="text-neutral-700">{t("TEXT_DOCUMENTS_INFO")}</div>
          <FileUploaderComponent
            className="mt-8"
            documents={props.documents}
            dragging={dragging}
            errors={props.getDocumentsErrors()}
            fileRemove={props.onDocumentDeleted}
            freightMode={props.freightMode}
            invalidDocument={props.invalidDocument}
            onChangeSelectedDocType={props.onChangeSelectedDocType}
            onDrag={overrideEventDefaults}
            onDragEnd={overrideEventDefaults}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDragStart={overrideEventDefaults}
            onDrop={dropListener}
            onSelectFileClick={onSelectFileClick}
          >
            <input
              accept={`${AcceptedFileType.PDF}, ${AcceptedFileType.JPEG}, 
                ${AcceptedFileType.XLSX}, ${AcceptedFileType.DOC}, 
                ${AcceptedFileType.DOCX}, ${AcceptedFileType.XLS}`}
              className="hidden"
              multiple
              onChange={onFileChanged}
              ref={(el) => (fileUploaderInput = el)}
              type="file"
            />
          </FileUploaderComponent>
          <div className="m-6 flex justify-end">
            <Button className="mr-2 rounded" onClick={() => props.setSelectedTab(1)}>
              {t("LABEL_BACK")}
            </Button>
            <Button className="ml-2 rounded" onClick={() => props.onHandleContinue()} primary>
              {t("LABEL_CONTINUE")}
            </Button>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default AddDocumentsComponent;
