import React, { useEffect, useState } from "react";

import {
  AcceptedFileType,
  DocumentReferenceType,
  fileMaxSize,
  fileNameSize,
  FreightMode,
} from "@assets/constants";
import { useAppDispatch } from "@assets/hooks";
import { DocumentError, ModalProps, SelectOption, Document, ErrorTypes } from "@assets/types";
import Button from "@components/Button/button";
import FileUploaderComponent from "@components/DropFileInput/fileUploaderComponent";
import { uploadDocuments } from "@state/slices/documentsSlice";
import { useTranslation } from "react-i18next";

import Modal from "./modal";

interface UploadDocumentsProps extends ModalProps {
  jobReference: string;
  freightMode: FreightMode;
  userName: string;
}

const UploadDocumentsModal: React.FC<UploadDocumentsProps> = (props: UploadDocumentsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { open, closeModal, jobReference, userName } = props;

  const [dragging, setDragging] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [invalidDocuments, setInvalidDocuments] = useState<boolean>(false);

  let fileUploaderInput: HTMLElement | null = null;
  let dragEventCounter = 0;

  const resetStates = () => {
    setDocuments([]);
  };

  const onClose = () => {
    resetStates();
    closeModal();
  };

  const onDocumentAdded = (document: Document) => {
    setDocuments((oldDocs) => [...oldDocs, document]);
  };

  const onDocumentDeleted = (index: number) => {
    setDocuments([...documents.slice(0, index), ...documents.slice(index + 1, documents.length)]);
  };

  const fileChecker = (fileType: string) => {
    return (
      fileType === AcceptedFileType.PDF ||
      fileType === AcceptedFileType.JPEG ||
      fileType === AcceptedFileType.XLSX ||
      fileType === AcceptedFileType.DOC ||
      fileType === AcceptedFileType.DOCX ||
      fileType === AcceptedFileType.XLS
    );
  };

  const overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragenterListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      setDragging(true);
    } else if (event.dataTransfer.types && event.dataTransfer.types[0] === "Files") {
      setDragging(true);
    }
  };

  const dragleaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter--;

    if (dragEventCounter === 0) {
      setDragging(false);
    }
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter = 0;
    setDragging(false);

    let files: File[] = [];
    if (event.dataTransfer.files) {
      files = Array.from(event.dataTransfer.files);
    }

    files.forEach((file) => {
      if (fileChecker(file.type))
        onDocumentAdded({
          document: file,
          doctype: "",
        });
    });
  };

  const onSelectFileClick = () => {
    fileUploaderInput && fileUploaderInput.click();
  };

  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let files: File[] = [];
      if (event.target.files) {
        files = Array.from(event.target.files);
      }

      files.forEach((file) => {
        if (fileChecker(file.type))
          onDocumentAdded({
            document: file,
            doctype: "",
          });
      });
    }
  };

  const onChangeSelectedDocType = (selectedDocType: SelectOption<string>, index: number) => {
    const updatedList: Document[] = [...documents];
    const updatedDoc = {
      document: updatedList[index].document,
      doctype: selectedDocType.value,
    };
    updatedList[index] = updatedDoc;
    setDocuments(updatedList);
  };

  const getDocumentsErrors = (): DocumentError[] => {
    const errors: DocumentError[] = [];
    const doc = documents as Document[];
    for (let i = 0; i < documents.length; i++) {
      if (doc[i].document.name.length >= fileNameSize) {
        errors.push({
          errorType: ErrorTypes.FileNameSizeExceded,
          index: i,
        });
      }
      if (Math.round(doc[i].document.size / 1048576) >= fileMaxSize) {
        errors.push({
          errorType: ErrorTypes.FileSizeExceded,
          index: i,
        });
      }
      if (doc.filter((x) => x.document.name === doc[i].document.name).length >= 2) {
        errors.push({ errorType: ErrorTypes.AlreadyExists, index: i });
      }
      if (doc[i].doctype === undefined || doc[i].doctype === null || doc[i].doctype === "") {
        errors.push({
          errorType: ErrorTypes.MandatoryField,
          index: i,
        });
      }
    }
    return errors;
  };

  const onSubmit = async () => {
    if (getDocumentsErrors().length > 0) {
      setInvalidDocuments(true);
      return;
    }

    dispatch(
      uploadDocuments({
        jobReference: jobReference,
        docTypes: documents.map((d) => d.doctype),
        referenceType: DocumentReferenceType.JOBREFERENCE,
        userReference: DocumentReferenceType.USERNAMEREFERENCE,
        userName: userName,
        files: documents.map((d) => d.document),
      })
    );

    resetStates();
    closeModal();
    return;
  };

  useEffect(() => {
    window.addEventListener("dragover", (event: Event) => {
      overrideEventDefaults(event);
    });
    window.addEventListener("drop", (event: Event) => {
      overrideEventDefaults(event);
    });
  }, []);

  useEffect(() => {
    window.removeEventListener("dragover", overrideEventDefaults);
    window.removeEventListener("drop", overrideEventDefaults);
  }, []);

  return (
    <Modal className="left-2 right-2" onClose={() => onClose()} open={open} size="xlarge">
      <Modal.Header>{t("LABEL_UPLOAD_DOCUMENTS")}</Modal.Header>
      <Modal.Content className="bg-white grid">
        <FileUploaderComponent
          className="mx-2 mt-4"
          documents={documents}
          dragging={dragging}
          errors={getDocumentsErrors()}
          fileRemove={onDocumentDeleted}
          freightMode={props.freightMode}
          invalidDocument={invalidDocuments}
          onChangeSelectedDocType={onChangeSelectedDocType}
          onDrag={overrideEventDefaults}
          onDragEnd={overrideEventDefaults}
          onDragEnter={dragenterListener}
          onDragLeave={dragleaveListener}
          onDragOver={overrideEventDefaults}
          onDragStart={overrideEventDefaults}
          onDrop={dropListener}
          onSelectFileClick={onSelectFileClick}
        >
          <input
            accept={`${AcceptedFileType.PDF}, ${AcceptedFileType.JPEG}, 
                ${AcceptedFileType.XLSX}, ${AcceptedFileType.DOC}, 
                ${AcceptedFileType.DOCX}, ${AcceptedFileType.XLS}`}
            className="hidden"
            multiple
            onChange={onFileChanged}
            ref={(el) => (fileUploaderInput = el)}
            type="file"
          />
        </FileUploaderComponent>
      </Modal.Content>
      <Modal.Footer divider>
        <Button
          className="ml-2"
          onClick={() => {
            onClose();
          }}
          type="button"
        >
          {t("LABEL_CANCEL")}
        </Button>
        <Button className="mr-6 md:mr-2" onClick={() => onSubmit()} primary type="submit">
          {t("LABEL_UPLOAD")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocumentsModal;
