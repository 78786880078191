import { ShippingTypeOptions } from "@assets/constants";
import { UserProfileViewModel, Company, UserWebSettings } from "@assets/types";
import {
  UserState,
  ToastState,
  ViewState,
  HomeState,
  BookingState,
  LoaderState,
} from "@state/reducers/types";
import _ from "lodash";

import {
  loginConstants,
  LoginType,
  userConstants,
  UserType,
  toastConstants,
  ToastType,
  viewConstants,
  ViewType,
  bookingConstants,
  requestConstants,
  HomeType,
  currencyConstants,
  voyageConstants,
  BookingType,
  LoaderType,
  loaderConstants,
} from "../actions/types";

const initialUserState: UserState = {
  profileLoaded: false,
  changingCompany: false,
  iframeUpdater: 0,
  loggedInOld: true,
  loginInProgress: false,
  logoutInProgress: false,
};

export const UserReducer = (state = initialUserState, action: LoginType | UserType) => {
  let profile = Object.assign({}, state.userProfile);
  let userWebSettingsPayload: UserWebSettings;
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return { ...state, profileLoaded: false, loginInProgress: true };
    case loginConstants.TOKEN_REFRESH:
      return { ...state, loginInProgress: true };
    case loginConstants.LOGIN_SUCCESS:
      // temporary shit mix while we still have old web menu items
      profile = action.payload as UserProfileViewModel;
      profile.Menu = profile.Menu.filter((item) => item.MenuID >= 248);
      return {
        ...state,
        userProfile: profile,
        profileLoaded: true,
        loginInProgress: false,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        profileLoaded: false,
        loginInProgress: false,
      };
    case loginConstants.OLD_LOGIN_REQUEST:
      return {
        ...state,
        loggedInOld: false,
      };
    case loginConstants.OLD_LOGIN_SUCCESS:
      return {
        ...state,
        loggedInOld: true,
      };
    case loginConstants.LOGOUT_REQUEST:
      return {
        ...state,
        logoutInProgress: true,
      };
    case loginConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        userProfile: undefined,
        profileLoaded: true,
        logoutInProgress: false,
      };
    case userConstants.USER_SELECT_COMPANY_REQUEST:
      return { ...state, changingCompany: true };
    case userConstants.USER_SELECT_COMPANY_SUCCESS:
      profile.SelectedCompany = action.payload as Company;
      return {
        ...state,
        userProfile: profile,
        changingCompany: false,
        iframeUpdater: ++state.iframeUpdater,
      };
    case userConstants.USER_SELECT_COMPANY_FAILED:
      return { ...state, changingCompany: false };
    case userConstants.USER_ME:
      // temporary shit mix while we still have old web menu items
      profile = action.payload as UserProfileViewModel;
      profile.Menu =
        profile && profile.Menu ? profile.Menu.filter((item) => item.MenuID >= 248) : [];
      return {
        ...state,
        userProfile: profile,
        profileLoaded: true,
      };
    case userConstants.USER_SELECT_LANGUAGE:
      profile.WebSettings = profile.WebSettings.filter(
        (webSetting) => webSetting.Field !== "SelectedLanguage"
      );
      profile.WebSettings.push(action.payload as UserWebSettings);
      return {
        ...state,
        iframeUpdater: ++state.iframeUpdater,
        userProfile: profile,
      };
    case userConstants.USER_UPDATE_WEBSETTINGS:
      userWebSettingsPayload = action.payload as UserWebSettings;
      profile.WebSettings = profile.WebSettings.filter(
        (webSetting) => webSetting.Field !== userWebSettingsPayload.Field
      );
      profile.WebSettings.push(userWebSettingsPayload as UserWebSettings);
      return {
        ...state,
        userProfile: profile,
      };
    default:
      return state;
  }
};

const inititialLoaderState: LoaderState = {
  favoriteBookingIsLoading: 0,
};

export function LoaderReducer(state = inititialLoaderState, action: LoaderType) {
  const { type } = action;

  switch (type) {
    case loaderConstants.FAVORITE_BOOKING_LOADER_ADD:
      return {
        ...state,
        favoriteBookingIsLoading: state.favoriteBookingIsLoading + 1,
      };

    case loaderConstants.FAVORITE_BOOKING_LOADER_REMOVE:
      return {
        ...state,
        favoriteBookingIsLoading: state.favoriteBookingIsLoading - 1,
      };

    default:
      return state;
  }
}

const inititialToastState: ToastState = {
  toasts: [],
};

export function ToastReducer(state = inititialToastState, action: ToastType) {
  const { payload, type } = action;

  switch (type) {
    case toastConstants.ADD_TOAST:
      return { ...state, toasts: [payload, ...state.toasts] };

    case toastConstants.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== payload),
      };

    default:
      return state;
  }
}

const initialViewState: ViewState = {
  modalOpen: false,
  modalType: undefined,
};

export function ViewReducer(state = initialViewState, action: ViewType) {
  const { type, payload } = action;

  switch (type) {
    case viewConstants.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
        modalType: payload ? payload : undefined,
      };

    case viewConstants.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
        modalType: undefined,
      };

    default:
      return state;
  }
}

const initialHomeState: HomeState = {
  activeRequests: [],
  loadingRequests: false,
  activeAirFreightBookings: [],
  loadingAirFreightBookings: false,
  activeSeaFreightBookings: [],
  loadingSeaFreightBookings: false,
  latestCurrencies: [],
  loadingCurrencies: false,
  activeVoyages: [],
  loadingVoyages: false,
};

export function HomeReducer(state = initialHomeState, action: HomeType) {
  const { type, payload } = action;

  switch (type) {
    case bookingConstants.BOOKING_SEA_FETCH_ACTIVE_REQUEST:
      return {
        ...state,
        loadingSeaFreightBookings: true,
      };
    case bookingConstants.BOOKING_SEA_FETCH_ACTIVE_SUCCESS:
      return {
        ...state,
        activeSeaFreightBookings: payload,
        loadingSeaFreightBookings: false,
      };
    case bookingConstants.BOOKING_SEA_FETCH_ACTIVE_FAILED:
      return {
        ...state,
        activeSeaFreightBookings: [],
        loadingSeaFreightBookings: false,
      };
    case bookingConstants.BOOKING_AIR_FETCH_ACTIVE_REQUEST:
      return {
        ...state,
        loadingAirFreightBookings: true,
      };
    case bookingConstants.BOOKING_AIR_FETCH_ACTIVE_SUCCESS:
      return {
        ...state,
        activeAirFreightBookings: payload,
        loadingAirFreightBookings: false,
      };
    case bookingConstants.BOOKING_AIR_FETCH_ACTIVE_FAILED:
      return {
        ...state,
        activeAirFreightBookings: [],
        loadingAirFreightBookings: false,
      };
    case requestConstants.REQUEST_FETCH_ACTIVE_REQUEST:
      return {
        ...state,
        loadingRequests: true,
      };
    case requestConstants.REQUEST_FETCH_ACTIVE_SUCCESS:
      return {
        ...state,
        activeRequests: payload,
        loadingRequests: false,
      };
    case requestConstants.REQUEST_FETCH_ACTIVE_FAILED:
      return {
        ...state,
        activeRequests: [],
        loadingRequests: false,
      };
    case currencyConstants.CURRENCY_FETCH_LATEST_REQUEST:
      return {
        ...state,
        loadingCurrencies: true,
      };
    case currencyConstants.CURRENCY_FETCH_LATEST_SUCCESS:
      return {
        ...state,
        latestCurrencies: payload,
        loadingCurrencies: false,
      };
    case currencyConstants.CURRENCY_FETCH_LATEST_FAILED:
      return {
        ...state,
        latestCurrencies: [],
        loadingCurrencies: false,
      };
    case voyageConstants.VOYAGE_FETCH_ACTIVE_REQUEST:
      return {
        ...state,
        loadingVoyages: true,
      };
    case voyageConstants.VOYAGE_FETCH_ACTIVE_SUCCESS:
      return {
        ...state,
        activeVoyages: payload,
        loadingVoyages: false,
      };
    case voyageConstants.VOYAGE_FETCH_ACTIVE_FAILED:
      return {
        ...state,
        activeVoyages: [],
        loadingVoyages: false,
      };
    default:
      return state;
  }
}

const initialBookingState: BookingState = {
  // All bookings
  bookingsAll: [],
  // Filtered
  bookings: [],
  favoriteBookings: [],
  shippingTypeOptions: ShippingTypeOptions,
  displayedShippingTypeOptions: ShippingTypeOptions,
  polOptions: [],
  displayedPolOptions: [],
  podOptions: [],
  displayedPodOptions: [],
  shipperOptions: [],
  displayedShipperOptions: [],
  consigneeOptions: [],
  displayedConsigneeOptions: [],
  loadingBookings: false,
  togglingFavoriteBooking: false,
  isFetchingExcelBookings: false,
  loadingBookingsInit: false,
};

export function BookingReducer(state = initialBookingState, action: BookingType) {
  const { type, payload } = action;
  switch (type) {
    case bookingConstants.BOOKING_LIST_FETCH_REQUEST:
      return {
        ...state,
        loadingBookings: true,
      };
    case bookingConstants.BOOKING_LIST_FETCH_SUCCESS:
      return {
        ...state,
        bookingsAll: payload,
        loadingBookings: false,
        loadingBookingsInit: true,
      };
    case bookingConstants.BOOKING_LIST_FRONTEND_FILTERING:
      return {
        ...state,
        bookings: payload,
      };
    case bookingConstants.BOOKING_LIST_UNIQUE_SHIPPING_TYPE:
      return {
        ...state,
        shippingTypeOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_DISPLAYED_SHIPPING_TYPE:
      return {
        ...state,
        displayedShippingTypeOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_UNIQUE_POL:
      return {
        ...state,
        polOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_DISPLAYED_POLS:
      return {
        ...state,
        displayedPolOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_DISPLAYED_PODS:
      return {
        ...state,
        displayedPodOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_DISPLAYED_SHIPPERS:
      return {
        ...state,
        displayedShipperOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_DISPLAYED_CONSIGNEES:
      return {
        ...state,
        displayedConsigneeOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_UNIQUE_POD:
      return {
        ...state,
        podOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_UNIQUE_SHIPPERS:
      return {
        ...state,
        shipperOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_UNIQUE_CONSIGNEES:
      return {
        ...state,
        consigneeOptions: payload,
      };
    case bookingConstants.BOOKING_LIST_FETCH_FAILED:
      return {
        ...state,
        bookingsAll: [],
        bookings: [],
        loadingBookings: false,
        loadingBookingsInit: true,
      };
    case bookingConstants.BOOKING_GET_FAVORITES_SUCCESS:
      return {
        ...state,
        favoriteBookings: payload,
      };
    case bookingConstants.BOOKING_TOGGLE_FAVORITE_REQUEST:
      return {
        ...state,
        favoriteBookings: _.xor(state.favoriteBookings, [payload as string]),
      };
    case bookingConstants.BOOKING_TOGGLE_FAVORITE_SUCCESS:
      return {
        ...state,
      };
    case bookingConstants.BOOKING_TOGGLE_FAVORITE_FAILED:
      return {
        ...state,
      };
    case bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_REQUEST:
      return {
        ...state,
        isFetchingExcelBookings: true,
      };
    case bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_SUCCESS:
      return {
        ...state,
        isFetchingExcelBookings: false,
      };
    case bookingConstants.BOOKING_GET_BOOKING_IN_EXCEL_FAILED:
      return {
        ...state,
        isFetchingExcelBookings: false,
      };
    default:
      return state;
  }
}
