import React, { Component } from "react";

import { titlePrefix } from "@assets/constants";
import { LoginState, UserProfileViewModel } from "@assets/types";
import Button from "@components/Button/button";
import Card from "@components/Card/card";
import InputEx from "@components/Input/inputEx";
import Link from "@components/Link/link";
import { login } from "@state/actions/index";
import { AppState } from "@state/reducers/types";
import { navigate } from "gatsby";
import i18n from "i18next";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import styles from "./login.module.less";

type LoginPageProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  path?: string;
  login: Function;
  userProfile: UserProfileViewModel;
  loginInProgress: boolean;
};

class Login extends Component<LoginPageProps, LoginState> {
  state = { username: "", password: "", loginInProgress: false };

  handleLogin = async (loginValues: LoginState) => {
    if (!this.state.loginInProgress) {
      try {
        await this.props.login(loginValues, { route: "/" });
      } catch (error) {
        console.log(error);
      }
    }
  };

  async componentDidMount() {
    document.title = titlePrefix + i18n.t("MENU_SERVICE_WEB");
    if (this.props.userProfile && this.props.userProfile.User?.ID) {
      await navigate("/", { replace: true });
      return;
    }
    return;
  }

  render() {
    const { loginInProgress } = this.props;
    const { username, password } = this.state;
    return (
      <Translation>
        {(t) => (
          <main
            className={`${styles.login} flex bg-light-gray flex-col flex-grow justify-center items-center w-full`}
          >
            <Card className="w-3/4 lg:w-3/4 max-w-600 bg-white ease-in-out bg-opacity-85 hover:bg-opacity-100 h-auto flip-in-hor-top m-4">
              <h2 className="uppercase pt-2 pl-3 font-bold text-jonar-blue text-xl">
                {t("LABEL_LOGIN")}
              </h2>
              <form
                autoComplete="on"
                className="container p-5"
                name="loginForm"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="border-b border-gray-300 p-2">
                  <InputEx
                    className="bg-transparent leading-tight  text-gray-700 mr-3"
                    id="login-username"
                    name="username"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ username: event.target.value })
                    }
                    placeholder={t("LABEL_USER_NAME")}
                    type="username"
                    value={username}
                  />
                </div>
                <div className="border-b border-gray-300 p-2">
                  <InputEx
                    className="bg-transparent leading-tight  text-gray-700 mr-3"
                    id="login-password"
                    name="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ password: event.target.value })
                    }
                    placeholder={t("LABEL_PASSWORD")}
                    type="password"
                    value={password}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <Link className="text-blue-500" onClick={() => navigate("/forgotPassword")}>
                    {t("LABEL_FORGOT_PWD")}
                  </Link>
                  <Button
                    active={loginInProgress}
                    className="mt-2"
                    onClick={async () => {
                      await this.handleLogin({ username, password });
                    }}
                    primary
                    type="submit"
                  >
                    {t("LABEL_BUTTON_LOGIN")}
                  </Button>
                </div>
              </form>
            </Card>
          </main>
        )}
      </Translation>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      userProfile: state.UserReducer.userProfile,
      loginInProgress: state.UserReducer.loginInProgress,
    };
  },
  { login }
)(Login);
