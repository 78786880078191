import {
  Commodities,
  CompanyAddress,
  ContainerTypes,
  HazardCodes,
  HSCodes,
  Incoterms,
  PackageTypes,
  Port,
  SelectOption,
} from "@assets/types";

export const portLabelMapper = (port: Port) => `${port.FullName}, ${port.Country}`;

export const portValueMapper = (port: Port) => port.PointCode;

export const commodityLabelMapper = (commodity: Commodities) =>
  `(${commodity.CommodityCode}) ${commodity.Description}`;

export const commodityValueMapper = (commodity: Commodities) => commodity.CommodityCode;

export const packageTypeLabelMapper = (packageType: PackageTypes) => packageType.Description;

export const packageTypeValueMapper = (packageType: PackageTypes) => packageType.PackageCode;

export const containerTypeLabelMapper = (containertype: ContainerTypes) =>
  containertype.Description;

export const containerTypeValueMapper = (containertype: ContainerTypes) =>
  containertype.PackageCode;

export const incotermValueMapper = (incoterm: Incoterms) => incoterm.TermsCode;

export const incotermLabelMapper = (incoterm: Incoterms) =>
  `${incoterm.TermsCode} - ${incoterm.Description}`;

export const partnerLabelMapper = (partner: CompanyAddress) =>
  `[${partner.PartnerCode}] ${partner.FullName}, ${
    partner.Address2 !== null ? partner.Address2 : partner.Address3
  }, ${partner.City}, ${partner.CountryName}`;

export const partnerValueMapper = (partner: CompanyAddress) => partner.PointCode;

export const hazardCodesLabelMapper = (hazardCodes: HazardCodes) =>
  `(${hazardCodes.UnNumber}) ${hazardCodes.Description ?? ""}`;

export const hazardCodesValueMapper = (hazardCodes: HazardCodes) =>
  `${hazardCodes.UnNumber}-${hazardCodes.UnVariant}-${hazardCodes.Description}`;

export const hsCodesLabelMapper = (hsCodes: HSCodes) =>
  `(${hsCodes.HSCode}) ${hsCodes.Description}`;

export const hsCodesValueMapper = (hsCodes: HSCodes) => hsCodes.HSCode;

const getValueLabelObject = <T extends string | number>(value: T): SelectOption<T> => {
  return {
    value: value,
    label: value.toString(),
  };
};

/**
 * Maps a list of values into a SelectOption list.
 * Mostly used to set the options of a select.
 * @param values The array of values that need to be transformed
 * @returns The array of SelectOptions
 */
export const listToSelectOptionMapper = <T extends string | number>(
  values: T[]
): SelectOption<T>[] => {
  return values.map((val) => {
    return getValueLabelObject(val);
  });
};

/**
 * A mapper to map a string or a number into a SelectOption.
 * Mostly used when setting the value of the select.
 * @param value The value to be mapped into a select option
 * @returns A select option object.
 */
export const singleValueToSelectOptionMapper = <T extends string | number>(value: T | null) => {
  if (!value) {
    return null;
  }
  return getValueLabelObject(value);
};
