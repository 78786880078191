import React, { ReactNode, useState } from "react";

import { classNames } from "@assets/utilities/classNameUtilities";

import ToggleLink from "../Link/toggleLink";

interface ExpandableComponentProps {
  children: React.ReactNode;
  className?: string;
  /** The element on the link to display when all items are shown, indicating that the list can be shortened. */
  expandedTitle?: ReactNode;
  /** The element on the link to display when max(less than the list length) items are shown, indicating that there are more items to be shown. */
  closedTitle?: ReactNode;
  fixedComponent?: ReactNode;
  position?: "right" | "default";
  isExpanded?: boolean;
}

const ExpandableComponent = ({
  children,
  className,
  expandedTitle,
  closedTitle,
  fixedComponent,
  position = "default",
  isExpanded = false,
}: ExpandableComponentProps) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const toggle = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };
  return (
    <>
      {position == "right" ? (
        <div className="flex justify-between">
          <div className="text-neutral-800 text-3.25 font-semibold">{fixedComponent}</div>
          <ToggleLink
            className={className}
            expandedText={expandedTitle}
            onClick={toggle}
            open={expanded}
          >
            {closedTitle}
          </ToggleLink>
        </div>
      ) : (
        <>
          <ToggleLink
            className={className}
            expandedText={expandedTitle}
            onClick={toggle}
            open={expanded}
          >
            {closedTitle}
          </ToggleLink>
          {fixedComponent}
        </>
      )}

      <div className={classNames(!expanded && "hidden")}>{children}</div>
    </>
  );
};

export default ExpandableComponent;
